import React, { forwardRef } from "react";
import { FormControlLabel, RadioProps, styled, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TEMPLATE } from "../../../interface/invoiceBuilder.interface";
import { ChooseTemplateWrapper, TemplateBox, TemplateBoxWrp } from "./invoiceSettingStyles";
import { CHOOSE_TEMPLATE_LIST } from "../../../helpers/constant/invoiceConstant";
import CustomRadioGroup from "../../../common/Radio/CustomRadioGroup";
import CustomRadio from "../../../common/Radio/CustomRadio";

const StyledRadioGroup = styled(CustomRadioGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "nowrap",
}));

interface Props extends RadioProps {
  selectedTemplate: TEMPLATE;
}

const ChooseTemplate = forwardRef<null, Props>((allProps, ref) => {
  const { selectedTemplate, ...props } = allProps;

  const templateList = Object.values(CHOOSE_TEMPLATE_LIST);
  return (
    <ChooseTemplateWrapper>
      <StyledRadioGroup>
        {templateList.map((templateInfo, index) => {
          return (
            <FormControlLabel
              sx={{ m: 0, width: "100%", "& .MuiFormControlLabel-label": { width: "100%" } }}
              key={templateInfo.value}
              control={<CustomRadio {...props} value={templateInfo.value} ref={ref} sx={{ display: "none" }} />}
              label={
                <TemplateBoxWrp>
                  <TemplateBox className={selectedTemplate == templateInfo.value ? "inv_selected" : ""}>
                    <img src={templateInfo.image} alt='default invoice' />
                    <CheckCircleIcon color='secondary' className='ib_checkedIcon' />
                  </TemplateBox>
                  <Typography variant='body2'>{templateInfo.label}</Typography>
                </TemplateBoxWrp>
              }
            />
          );
        })}
      </StyledRadioGroup>
    </ChooseTemplateWrapper>
  );
});

ChooseTemplate.displayName = "ChooseTemplate";
export default ChooseTemplate;
