import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import {
  addPaymentTermFailed,
  addPaymentTermSuccess,
  deletePaymentTermFailed,
  deletePaymentTermSuccess,
  getPaymentTermsFailed,
  getPaymentTermsSuccess,
  updatePaymentTermFailed,
  updatePaymentTermSuccess,
} from "./paymentTermSlice";
import PaymentTermService from "../../../services/paymentTermService";
import { IGetPaymentTermsPayload, PaymentTermInterface } from "./paymentTerm.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* paymentTermWatcher() {
  yield takeEvery("paymentTerm/getPaymentTerms", getPaymentTermsSaga);
  yield takeEvery("paymentTerm/addPaymentTerm", addPaymentTermSaga);
  yield takeEvery("paymentTerm/updatePaymentTerm", updatePaymentTermSaga);
  yield takeEvery("paymentTerm/deletePaymentTerm", deletePaymentTermSaga);
}

function* getPaymentTermsSaga(action: PayloadAction<IGetPaymentTermsPayload>) {
  try {
    const response = yield* call(PaymentTermService.getPaymentTerms, action.payload);

    if (response.success) {
      yield put(getPaymentTermsSuccess(response.data));
    } else {
      yield put(getPaymentTermsFailed(response.message));
    }
  } catch (err) {
    yield put(getPaymentTermsFailed("Network or server error"));
  }
}

function* addPaymentTermSaga(action: PayloadAction<PaymentTermInterface>) {
  try {
    const response = yield* call(PaymentTermService.addPaymentTerm, action.payload);

    if (response.success) {
      yield put(addPaymentTermSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(addPaymentTermFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(addPaymentTermFailed());
    showErrorToaster("Network or server error");
  }
}

function* updatePaymentTermSaga(action: PayloadAction<PaymentTermInterface>) {
  try {
    const response = yield* call(PaymentTermService.updatePaymentTerm, action.payload);

    if (response.success) {
      yield put(updatePaymentTermSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(updatePaymentTermFailed());
      showSuccessToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(updatePaymentTermFailed());
  }
}

function* deletePaymentTermSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(PaymentTermService.deletePaymentTerm, action.payload);

    if (response.success) {
      yield put(deletePaymentTermSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(deletePaymentTermFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
  }
}

export default function* paymentTermSaga() {
  yield all([paymentTermWatcher()]);
}
