import React, { forwardRef } from "react";
import { Editor, IAllProps } from "@tinymce/tinymce-react";
import { Box, styled } from "@mui/material";

const StyledEditorWrapper = styled(Box)(({ theme }) => ({
  "& .tox-tinymce": {
    borderColor: theme.palette.other.outlinedBorder,
    borderRadius: "8px",
  },
  "& .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)": {
    borderRightColor: theme.palette.other.outlinedBorder,
  },
  "& .tox-statusbar": {
    borderTopColor: `${theme.palette.other.outlinedBorder} !important`,
  },
  "& .tox-toolbar__primary": {
    background: "none !important",
    borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
  },
}));

interface Props extends IAllProps {
  value?: string;
  onChangeBody?: (value: string) => void;
  autoFocus?: true;
  height?: number | string;
}

const BasicEditor = forwardRef<Editor, Props>(
  ({ value, onChangeBody, onBlur, autoFocus = true, height = 300, ...rest }, ref) => {
    return (
      <StyledEditorWrapper>
        <Editor
          ref={ref}
          apiKey='f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x'
          value={value}
          cloudChannel='5-dev'
          init={{
            height: height,
            auto_focus: autoFocus,
            menubar: false,
            menu: {
              favs: {
                title: "My Favorites",
                items: "code visualaid | searchreplace | emoticons",
              },
            },
            plugins: [
              "advlist autolink lists link hr charmap print preview anchor pagebreak",
              "searchreplace visualblocks visualchars code fullscreen nonbreaking",
              "insertdatetime table contextmenu code help wordcount template",
            ],
            convert_urls: false,
            toolbar:
              "insertfile undo redo | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | link image | bullist numlist outdent indent | removeformat | preview media fullpage",
            automatic_uploads: true,
            target_list: [
              { title: "Same page", value: "_self" },
              { title: "New page", value: "_blank" },
            ],
            content_style: "p { margin: 0px; }",
          }}
          onEditorChange={onChangeBody}
          onBlur={onBlur}
          {...rest}
        />
      </StyledEditorWrapper>
    );
  }
);

BasicEditor.displayName = "BasicEditor";

export default BasicEditor;
