import httpRequest from "./api/httpRequest";
import { IPaymentListItem } from "../state/features/paymentList/paymentList.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class PaymentListService {
  getPaymentList() {
    return httpRequest.get<IPaymentListItem[]>(invoiceAPI + "invoice-builder/payment/list");
  }
}

export default new PaymentListService();
