import React, { cloneElement, JSXElementConstructor, ReactElement, useRef } from "react";
import { showErrorToaster } from "../../helpers/utils/toaster";
import FileUploadService from "../../services/FileUploadService";

interface IProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  onImageUpload?: (imgUrl: string) => void;
  onUploading: (loadingState: boolean) => void;
  maxFileSizeMB?: number;
}

const CustomImageUploadWrapper: React.FC<IProps> = ({ children, onImageUpload, onUploading, maxFileSizeMB = 2 }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = e.target.files;
      if (!files) return;

      const file = files[0];
      if (!file.type.match(/image/g)) {
        showErrorToaster("Please select a valid image.");
        return;
      }

      if (file.size > 1024 * 1024 * maxFileSizeMB) {
        showErrorToaster("Maximum upload size : 2MB");
        return;
      }

      if (onUploading) onUploading(true);

      const dt: FormData = new FormData();
      dt.append("files", file);

      FileUploadService.uploadFile(dt)
        .then((res) => {
          if (res.success && onImageUpload) {
            onImageUpload(res.data.urls);
          } else {
            showErrorToaster("Image upload failed, please try again later");
          }
        })
        .finally(() => onUploading && onUploading(false));
    } catch (e) {
      showErrorToaster("Something went wrong, try upload file from local storage");
    }
  };

  const onClickChildItem = () => {
    if (inputRef.current !== null) inputRef.current.click();
  };

  return (
    <>
      <label htmlFor='custom-file-upload-btn'>
        {cloneElement(children, {
          onClick: onClickChildItem,
        })}
      </label>
      <input
        id={"custom-file-upload-btn"}
        ref={inputRef}
        type='file'
        accept='image/*'
        onChange={handleFileUpload}
        hidden
      />
    </>
  );
};

export default CustomImageUploadWrapper;
