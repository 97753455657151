import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./state/store";
import * as serviceWorker from "./serviceWorker";
import "./assets/scss/main.scss";
import { RouteComponentProps } from "react-router-dom";

const rootElement = document.getElementById("InvoiceBuilder-root");

if (rootElement) {
  const isProductionMode = process.env.REACT_APP_PRODUCTION_MODE;

  if (isProductionMode && isProductionMode !== "true") {
    const rootLoadingElement = document.getElementById("root_loading");
    if (rootLoadingElement) rootLoadingElement.remove();

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
    serviceWorker.unregister();
  }
}

declare global {
  interface Window {
    renderInvoiceBuilderModule: (containerId: string, history: RouteComponentProps["history"]) => void;
    unmountInvoiceBuilderModule: (containerId: string) => void;
    setActiveSidebar: (value: string) => void;
  }
}

window.renderInvoiceBuilderModule = (containerId: string, history: RouteComponentProps["history"]) => {
  const containerElement = document.getElementById(containerId);
  if (!containerElement) throw Error("container element not found");

  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    containerElement
  );
  serviceWorker.unregister();
};

window.unmountInvoiceBuilderModule = (containerId: string) => {
  const containerElement = document.getElementById(containerId);
  if (containerElement) {
    ReactDOM.unmountComponentAtNode(containerElement);
  }
};
