import { Stack, styled, tableCellClasses, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";

//inv header for basic
export const LeftSide = styled(Stack)(() => ({
  width: "60%",
  justifyContent: "space-between",
}));
export const RightSide = styled(Stack)(() => ({
  width: "40%",
  alignItems: "flex-end",
  justifyContent: "space-between",
}));

//inv table for basic
export const TotalTableRow = styled(TableRow)(({ theme }) => ({
  border: "none",
  "& td": {
    border: "none",
    padding: theme.spacing(1),
    color: theme.palette.other.grayDark,
    "&:last-of-type": { paddingRight: theme.spacing(10) },
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.other.dividerFillColor,
  "& td": {
    fontWeight: 600,
    color: theme.palette.other.grayDark,
    "&:first-of-type": { paddingLeft: theme.spacing(10) },
    "&:last-of-type": { paddingRight: theme.spacing(10) },
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.other.grayDark,
    color: theme.palette.common.white,
    fontWeight: 600,
    minWidth: "100px",
  },
}));

//inv footer
