import React from "react";
import { useDispatch } from "react-redux";
import { Box, Tab } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { FolderChipListTabs, StyledChip } from "./InvoiceHomePageStyles";
import { AppDispatch } from "../../state/store";
import { FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderConstant";
import { removeFolderFromInvoice } from "../../state/features/invoice/invoiceSlice";

interface Props {
  invoiceId: number;
  invoiceFolderList: {
    name: string;
    folderId: number;
    color: string;
  }[];
}

const FolderNameChipList: React.FC<Props> = ({ invoiceId, invoiceFolderList = [] }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  const handleDelete = (folderId: number) => {
    dispatch(
      removeFolderFromInvoice({
        folderId,
        invoiceId,
      })
    );
  };
  return (
    <Box
      sx={{
        flex: 1,
        width: 100,
        position: "relative",
      }}
    >
      <FolderChipListTabs
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        aria-label='scrollable force tabs example'
        value={value}
        onChange={handleChange}
      >
        {invoiceFolderList.map((singleFolder, index) => (
          <Tab
            key={singleFolder.folderId}
            component={"div"}
            sx={{ padding: 0, borderRadius: "16px", minWidth: "max-content" }}
            label={
              <StyledChip
                size={"xSmall"}
                icon={<FolderIcon />}
                label={singleFolder.name}
                onDelete={() => handleDelete(singleFolder.folderId as number)}
                chipColor={singleFolder.color || FOLDER_DEFAULT_COLOR}
              />
            }
          />
        ))}
        <Tab
          disabled={true}
          component={"div"}
          label={""}
          sx={{
            minWidth: "unset !important",
            px: 1.5,
          }}
        />
      </FolderChipListTabs>
    </Box>
  );
};

export default FolderNameChipList;
