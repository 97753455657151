import React from "react";
import { useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import InvoiceListFilterSearch from "./invoiceListfilterHeader/InvoiceListFilterSearch";
import InvoiceListFilterStatus from "./invoiceListfilterHeader/InvoiceListFilterStatus";
import InvoiceResetFilter from "./invoiceListfilterHeader/InvoiceResetFilter";
import InvoiceListBulkActions from "./invoiceListfilterHeader/InvoiceListBulkActions";
import { selectInvoiceSelectedIds } from "../../state/features/invoice/invoiceSelector";
import InvoiceListPaidStatusFilter from "./invoiceListfilterHeader/InvoiceListPaidStatusFilter";

const HeaderBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px",
  marginBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.action.hover}`,
  paddingBottom: theme.spacing(1),
}));

const FormListFilterHeader: React.FC = () => {
  const selectedInvoiceIds = useSelector(selectInvoiceSelectedIds);
  return (
    <HeaderBoxWrapper>
      <>
        <Box sx={{ flex: 1 }}>
          {selectedInvoiceIds?.length === 0 ? <InvoiceListFilterSearch /> : <InvoiceListBulkActions />}
        </Box>

        <InvoiceListPaidStatusFilter />
        <InvoiceListFilterStatus />
        <InvoiceResetFilter />
      </>
    </HeaderBoxWrapper>
  );
};

export default FormListFilterHeader;
