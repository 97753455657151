import { lastDayOfMonth } from "date-fns";
import { addDays, addMonths } from "./dateUtils";
import previewDateFormat from "./previewDateFormat";
import { INVOICE_TYPE } from "../constant/invoiceConstant";
import { PAYMENT_TERM_STATUS } from "../constant/paymentTermConstant";
import { InvoiceTypeEnum } from "../../state/features/invoice/invoice.interface";
import { PaymentTermInterface } from "../../state/features/paymentTerm/paymentTerm.interface";

const getInvoiceDateAndDueDate = (
  invoiceType: InvoiceTypeEnum,
  invoiceDate: Date | string,
  dueDate: Date | string,
  startDate?: Date | string,
  paymentTerms?: PaymentTermInterface
) => {
  let formattedInvoiceDate = "";
  let formattedDueDate = "";

  if (invoiceType === INVOICE_TYPE.RECURRING) {
    formattedInvoiceDate = startDate ? previewDateFormat(startDate) : "";
    if (startDate) {
      let nextDue = startDate;
      if (paymentTerms?.status === PAYMENT_TERM_STATUS.DUE_END_OF_THE_MONTH) {
        nextDue = lastDayOfMonth(new Date(startDate));
      } else if (paymentTerms?.status === PAYMENT_TERM_STATUS.DUE_END_OF_THE_NEXT_MONTH) {
        nextDue = addMonths(startDate, 1);
        nextDue = lastDayOfMonth(new Date(nextDue));
      } else {
        nextDue = addDays(startDate, paymentTerms?.value ?? 0);
      }

      formattedDueDate = previewDateFormat(nextDue);
    }
  } else {
    formattedInvoiceDate = invoiceDate ? previewDateFormat(invoiceDate) : "";
    formattedDueDate = dueDate ? previewDateFormat(dueDate) : "";
  }

  return { formattedInvoiceDate, formattedDueDate };
};

export default getInvoiceDateAndDueDate;
