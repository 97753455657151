import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MailTemplateInterface, SendMailPayload } from "./mail.interface";

const initialState = {
  data: {} as MailTemplateInterface,
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: "",
  isMailSent: false,
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    getMailTemplateData: (state) => {},
    getMailTemplateDataSuccess: (state, action: PayloadAction<MailTemplateInterface>) => {
      state.data = action.payload;
      state.isSuccess = true;
    },

    sendMail: (state, action: PayloadAction<SendMailPayload>) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    },

    sendMailSuccess: (state, action: PayloadAction<SendMailPayload>) => {
      state.isLoading = false;
      state.isMailSent = true;
    },

    sendMailFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    resetMailState: (state) => {
      state.isMailSent = false;
      state.isError = true;
      state.error = "";
    },
  },
});

export const {
  getMailTemplateData,
  getMailTemplateDataSuccess,
  sendMail,
  sendMailSuccess,
  sendMailFailed,
  resetMailState,
} = mailSlice.actions;
export default mailSlice.reducer;
