import httpRequest from "./api/httpRequest";
import {
  getContactInterface,
  IContactResponse,
  IGetStageContact,
  IStageContactResponse,
} from "../state/features/contact/contactInterfaces";

const contactBackendUrl = process.env.REACT_APP_BACKEND_URL_CONTACT_API;
const contactBackendUrlStage = process.env.REACT_APP_BACKEND_URL_CONTACT_API_STAGE;
if (!contactBackendUrl) throw Error("Invoice Contact API URL not found in env!");

class ContactService {
  getContactList(body: getContactInterface) {
    return httpRequest.get<IContactResponse>(contactBackendUrl, body);
  }
  getStageContactList(body: IGetStageContact) {
    return httpRequest.post<IStageContactResponse>(contactBackendUrlStage, body);
  }
}

export default new ContactService();
