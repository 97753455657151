import React from "react";
import { Divider, Stack, Typography } from "@mui/material";

interface Props {
  heading: string;
  icon?: React.ReactNode;
}

const SendInvoiceHeading: React.FC<Props> = ({ heading, icon }) => {
  return (
    <>
      <Stack direction={"row"} alignItems={"center"} spacing={1} p={2.5} sx={{ bgcolor: "other.secondaryHover" }}>
        {icon}
        <Typography variant='h6' color='text.primary'>
          {heading}
        </Typography>
      </Stack>
      <Divider light />
    </>
  );
};

export default SendInvoiceHeading;
