import { RootState } from "../../store";

export const selectInvoiceState = (state: RootState) => state.invoice;
export const selectInvoiceSelectedIds = (state: RootState) => state.invoice.selectedInvoiceIds;
export const selectInvoiceFilters = (state: RootState) => state.invoice.filters;
export const selectShowReportAlert = (state: RootState) => state.invoice.showReportAlert;

export const selectedInvoicesFolderIds = (state: RootState) => {
  const invoiceIds = state.invoice.selectedInvoiceIds;
  const invoiceList = state.invoice.data.filter((invoiceData) => invoiceIds.indexOf(invoiceData.id as number) !== -1);

  const invoiceFolderIds = invoiceList
    .filter((invoiceData) => invoiceData?.folder?.length)
    .reduce((folders: { name: string; color: string; folderId: number }[], invoiceData) => {
      if (invoiceData.folder) {
        return [...folders, ...invoiceData.folder];
      } else {
        return folders;
      }
    }, [])
    .map((folder) => folder.folderId);

  return [...new Set(invoiceFolderIds)];
};
