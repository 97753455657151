import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import { IFolder, IGetFoldersPayload } from "../state/features/folder/folder.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class FolderService {
  getFolders(params: IGetFoldersPayload) {
    return httpRequest.get<WithPagination<IFolder[]>>(invoiceAPI + "invoice-builder/folder/list", params);
  }

  addFolder(folder: IFolder) {
    return httpRequest.post<IFolder>(invoiceAPI + "invoice-builder/folder/create", folder);
  }

  updateFolder(folder: IFolder) {
    return httpRequest.put<IFolder>(invoiceAPI + "invoice-builder/folder/update", folder);
  }

  deleteFolder(id: number) {
    return httpRequest.delete<IFolder>(invoiceAPI + "invoice-builder/folder/delete", { id: id });
  }
}

export default new FolderService();
