import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SystemDefaultInfoInterface } from "./defaultInfoInterfaces";

const initialState = {
  data: {} as SystemDefaultInfoInterface,
  isLoading: true,
  isSuccess: false,
  isError: false,
  error: "",
};

const defaultInfoSlice = createSlice({
  name: "defaultInfo",
  initialState,
  reducers: {
    getSystemDefaultInfo(state) {
      state.isLoading = true;
    },
    getSystemDefaultInfoSuccess(state, action: PayloadAction<SystemDefaultInfoInterface>) {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = "";
    },
    getSystemDefaultInfoFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { getSystemDefaultInfo, getSystemDefaultInfoSuccess, getSystemDefaultInfoFailed } =
  defaultInfoSlice.actions;
export default defaultInfoSlice.reducer;
