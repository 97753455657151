import React from "react";
import InvoiceHeaderBasic from "./BasicInvoiceComponents/InvoiceHeaderBasic";
import { Box } from "@mui/material";
import InvoiceTableBasic from "./BasicInvoiceComponents/InvoiceTableBasic";
import InvoiceFooterBasic from "./BasicInvoiceComponents/InvoiceFooterBasic";

const BasicInvoiceBody: React.FC = () => {
  return (
    <Box py={3}>
      <InvoiceHeaderBasic />
      <InvoiceTableBasic />
      <InvoiceFooterBasic />
    </Box>
  );
};

export default BasicInvoiceBody;
