import React, { useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { DescriptionOutlined, RepeatOutlined, RestoreFromTrash } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderNameChipList from "./FolderNameChipList";
import RouteLink from "../../common/Link/RouteLink";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import useBoolean from "../../hooks/useBoolean";
import { AppDispatch } from "../../state/store";
import { selectInvoiceState } from "../../state/features/invoice/invoiceSelector";
import { InvoiceListItemInterface } from "../../state/features/invoice/invoice.interface";
import {
  deleteInvoices,
  deleteInvoicesForever,
  resetDeleteInvoiceForeverSuccess,
  toggleSelectedSingleInvoice,
} from "../../state/features/invoice/invoiceSlice";
import getFullName from "../../helpers/utils/getFullName";
import formatCurrency from "../../helpers/utils/formatCurrency";
import { INVOICE_TYPE, InvoiceEndDateStatus } from "../../helpers/constant/invoiceConstant";

interface Props {
  invoice: InvoiceListItemInterface;
}

const getFormattedDate = (dueDate: string | Date) => {
  return format(new Date(dueDate), "MMM dd, yyyy");
};

const SingleItemInTrash: React.FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const {
    id,
    name,
    invoiceNumber,
    client,
    totalAmount,
    createdAt,
    dueDate,
    status,
    invoiceType,
    invoiceDate,
    totalRecurringInvoice,
    startDate,
    endDateStatus,
    endDate,
    formattedCreatedAt,
  } = invoice;
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  const { selectedInvoiceIds, isDeleting, isDeleteSuccess } = useSelector(selectInvoiceState);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseModal();
      dispatch(resetDeleteInvoiceForeverSuccess());
    }
  }, [isDeleteSuccess]);

  const onConfirmDeleteForever = () => {
    dispatch(deleteInvoicesForever({ ids: [id as number] }));
  };

  // handle restore from in trash
  const handleRestoreInvoice = () => {
    dispatch(deleteInvoices({ ids: [id as number], isTrashed: false }));
  };

  const formattedDueDate = dueDate ? getFormattedDate(dueDate) : "-";
  // const formattedCreatedAt = createdAt ? getFormattedDate(createdAt) : "-";
  const formattedInvoiceDate = invoiceDate ? getFormattedDate(invoiceDate) : "-";
  const formattedStartDate = startDate ? getFormattedDate(startDate) : "-";
  const formattedEndDate = endDate ? getFormattedDate(endDate) : "-";

  let customer: string | undefined;
  if (client?.id) {
    customer = getFullName(client.firstName, client.lastName) || client.email || client.number;
  }

  let paymentStatus;
  if (status === "COMPLETED") {
    paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"success"} label={"Paid"} />;
  } else {
    if (new Date().getTime() < new Date(dueDate).getTime()) {
      paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"secondary"} label={"Outstanding"} />;
    } else {
      paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"error"} label={" Past Due"} />;
    }
  }

  return (
    <>
      <TableBody sx={{ "& td": { fontWeight: 300 } }}>
        <TableRow selected={selectedInvoiceIds.includes(id as number)}>
          <TableCell>
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Checkbox
                edge='start'
                color='secondary'
                size={"small"}
                tabIndex={-1}
                checked={selectedInvoiceIds.includes(id as number)}
                onChange={() => dispatch(toggleSelectedSingleInvoice(id as number))}
                sx={{
                  padding: "4px",
                  marginLeft: 0,
                  "& .PrivateSwitchBase-input": {
                    pointerEvents: "auto !important",
                  },
                }}
              />
              <CustomTooltip
                arrow
                placement={"top"}
                title={invoiceType === INVOICE_TYPE.ONE_TIME ? "OneTime" : "Recurring"}
              >
                <IconButton size={"small"} sx={invoiceType === INVOICE_TYPE.ONE_TIME ? { cursor: "default" } : {}}>
                  {invoiceType === INVOICE_TYPE.ONE_TIME ? (
                    <DescriptionOutlined fontSize={"small"} />
                  ) : (
                    <Badge color='secondary' badgeContent={totalRecurringInvoice}>
                      <RepeatOutlined fontSize={"small"} />
                    </Badge>
                  )}
                </IconButton>
              </CustomTooltip>
            </Stack>
          </TableCell>

          <TableCell sx={{ lineHeight: "15px" }}>
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              <RouteLink to={`/ibuilder/invoice/${invoice.id}`}>
                <Typography variant='body2' color='text.primary' sx={{ minWidth: "max-content" }}>
                  {name}
                </Typography>
              </RouteLink>
              <FolderNameChipList invoiceId={invoice.id as number} invoiceFolderList={invoice.folder || []} />
            </Stack>
            <Typography variant='caption' color='text.secondary' fontWeight={300}>
              Created on: {formattedCreatedAt}
            </Typography>
          </TableCell>

          <TableCell>{invoiceNumber}</TableCell>

          <TableCell>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Avatar sx={{ width: 25, height: 25 }}>{customer?.charAt(0).toUpperCase()}</Avatar>
              <Typography variant='body2' color='text.primary' fontWeight={300}>
                {customer || "-"}
              </Typography>
            </Stack>
          </TableCell>

          <TableCell>{invoiceType === INVOICE_TYPE.RECURRING ? formattedStartDate : formattedInvoiceDate}</TableCell>
          <TableCell>
            {invoiceType === INVOICE_TYPE.RECURRING
              ? endDateStatus === InvoiceEndDateStatus.NEVER
                ? "Never End"
                : formattedEndDate
              : formattedDueDate}
          </TableCell>

          <TableCell>{formatCurrency(totalAmount, "$")}</TableCell>

          <TableCell>{invoiceType === INVOICE_TYPE.RECURRING ? "-" : paymentStatus}</TableCell>

          <TableCell>
            <Stack direction={"row"} alignItems={"center"}>
              <CustomTooltip title='Delete Forever' arrow placement='top' backgroundColor={theme.palette.error.main}>
                <IconButton size='small' onClick={onOpenModal}>
                  <DeleteForeverIcon color='error' />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title='Restore' arrow placement='top'>
                <IconButton size='small' onClick={handleRestoreInvoice}>
                  <RestoreFromTrash sx={{ color: "#15008A" }} />
                </IconButton>
              </CustomTooltip>
            </Stack>
          </TableCell>
        </TableRow>
      </TableBody>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        warningSubtitle={"Are you sure, want to delete this invoice?"}
        warningContent={"After delete, this operation cannot be undone."}
        buttonText={"Yes, Delete Forever"}
        cancelButtonText={"Cancel"}
        open={openModal}
        onClose={onCloseModal}
        onConfirm={onConfirmDeleteForever}
        isLoading={isDeleting}
      />
    </>
  );
};

export default SingleItemInTrash;
