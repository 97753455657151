import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import BasicInvoiceBody from "./BasicTemplatePreview/BasicInvoiceBody";
import DefaultInvoiceBody from "./DefaultTemplatePreview/DefaultInvoiceBody";
import StandardInvoiceBody from "./StandardTemplatePreview/StandardInvoiceBody";
import SendWithMail from "../Settings/SentInvoice/SendWithMail";
import SentWithSms from "../Settings/SentInvoice/SentWithSms";
import PreviewSkeleton from "./PreviewSkeleton";
import CoreButton from "../../common/Button/CoreButton";
import CommonTitleBar from "../../common/CommonTitleBar";
import LoadingButton from "../../common/Button/LoadingButton";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import { AppDispatch } from "../../state/store";
import { BUILDER_OPTIONS, TEMPLATE_TYPE } from "../../helpers/constant/invoiceConstant";
import { changeCurrentBuilderTab, generatePDF } from "../../state/features/invoiceBuilder/invoiceBuilderSlice";
import { selectInvoiceBuilderState } from "../../state/features/invoiceBuilder/invoiceBuilderSelector";
import GoToSendInvoiceButton from "./GoToSendInvoiceButton";
import { InvoiceBodyPreview, InvoiceBodyPreviewWrp } from "./PreviewStyles";
import { getPersonalizeTags } from "../../state/features/personalizeTag/personalizeTagSlice";
import { selectPersonalizeTagIsSuccess } from "../../state/features/personalizeTag/personalizeTagSelector";

interface IParams {
  invoiceId: string;
}

const PreviewAndSend: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { invoiceId } = useParams<IParams>();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollPreviewDivRef = useRef<HTMLDivElement>(null);
  const [isScrollToSend, setIsScrollToSend] = useState(false);
  const hasPersonalizeTags = useSelector(selectPersonalizeTagIsSuccess);

  const handleClickButton = () => {
    isScrollToSend
      ? scrollPreviewDivRef.current?.scrollIntoView({ behavior: "smooth" })
      : scrollDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const targetDiv = scrollDivRef.current;

    if (targetDiv) {
      const sendDiv = targetDiv.getBoundingClientRect();
      setIsScrollToSend(sendDiv.top <= window.innerHeight && sendDiv.bottom >= 0);
    }
  };

  const {
    data: { client, items = [], settings },
    isLoading,
    pdfGenerating,
  } = useSelector(selectInvoiceBuilderState);
  const { template = 1 } = settings || {};

  useEffect(() => {
    if (!hasPersonalizeTags) {
      dispatch(getPersonalizeTags());
    }
  }, []);

  const onDownload = () => {
    dispatch(generatePDF(invoiceId));
  };

  // ## decide what to render
  let content = <></>;
  if (isLoading) {
    content = (
      <InvoiceBodyPreviewWrp className='ib_scroll_bar'>
        <InvoiceBodyPreview>
          <PreviewSkeleton />
        </InvoiceBodyPreview>
      </InvoiceBodyPreviewWrp>
    );
  } else if (!client?.id || items?.length === 0) {
    content = (
      <GlobalEmptyPage
        title={"Unable to generate Invoice preview"}
        description={
          "Necessary invoice data not found, Please make sure to provide your invoice information before show preview"
        }
      />
    );
  } else {
    content = (
      <>
        <InvoiceBodyPreviewWrp className='ib_scroll_bar' onScroll={handleScroll}>
          <GoToSendInvoiceButton isScrollToSend={isScrollToSend} onClick={handleClickButton} />

          {/* ## Invoice Preview Portion ## */}
          <InvoiceBodyPreview ref={scrollPreviewDivRef}>
            <CommonTitleBar name={"Preview"} icon={<FileOpenOutlinedIcon />}>
              <CoreButton
                size={"small"}
                variant='outlined'
                startIcon={<ArrowBackIcon />}
                onClick={() => dispatch(changeCurrentBuilderTab(BUILDER_OPTIONS.BUILDER))}
                sx={{ color: "common.white", borderColor: "common.white", "&:hover": { borderColor: "common.white" } }}
              >
                Back
              </CoreButton>
            </CommonTitleBar>
            <Paper variant={"outlined"} sx={{ m: 3, mb: 2 }}>
              {template == TEMPLATE_TYPE.BASIC && <BasicInvoiceBody />}
              {template == TEMPLATE_TYPE.DEFAULT && <DefaultInvoiceBody />}
              {template == TEMPLATE_TYPE.STANDARD && <StandardInvoiceBody />}
            </Paper>
            <Stack alignItems='center' justifyContent='center' p={2} pt={0}>
              <LoadingButton
                isLoading={pdfGenerating}
                disabled={pdfGenerating}
                variant='contained'
                color='secondary'
                size={"small"}
                startIcon={<DownloadForOfflineIcon />}
                onClick={onDownload}
              >
                {pdfGenerating ? "Generating" : "Download PDF"}
              </LoadingButton>
            </Stack>
          </InvoiceBodyPreview>

          {/* ## Send Invoice Portion ## */}
          <InvoiceBodyPreview sx={{ my: 2 }} ref={scrollDivRef}>
            <CommonTitleBar
              name={"Send your Invoice"}
              subtitle={
                "Its time to send your invoice. You can send it via email. Craft your message below and click send."
              }
              icon={<SendOutlinedIcon />}
            />
            <SendWithMail />
          </InvoiceBodyPreview>

          <InvoiceBodyPreview>
            <SentWithSms />
          </InvoiceBodyPreview>
        </InvoiceBodyPreviewWrp>
      </>
    );
  }

  return (
    <Stack direction='column' justifyContent='space-between' sx={{ height: "calc(100vh - 52px)" }}>
      {content}
    </Stack>
  );
};

export default PreviewAndSend;
