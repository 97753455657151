import { combineReducers } from "@reduxjs/toolkit";
import itemSlice from "./features/item/itemSlice";
import mailSlice from "./features/mail/mailSlice";
import folderSlice from "./features/folder/folderSlice";
import invoiceSlice from "./features/invoice/invoiceSlice";
import childInvoiceSlice from "./features/childInvoice/childInvoiceSlice";
import invoiceBuilderSlice from "./features/invoiceBuilder/invoiceBuilderSlice";
import smsSlice from "./features/sms/smsSlice";
import contactSlice from "./features/contact/contactSlice";
import invoiceStateSlice from "./features/invoiceState/invoiceStateSlice";
import recurringSettingSlice from "./features/recurringSettings/recurringSettingSlice";
import followupSettingSlice from "./features/followupSetting/followupSettingSlice";
import paymentListSlice from "./features/paymentList/paymentListSlice";
import defaultInfoSlice from "./features/defaultInfo/defaultInfoSlice";
import paymentTermSlice from "./features/paymentTerm/paymentTermSlice";
import taxSlice from "./features/tax/taxSlice";
import personalizeTagSlice from "./features/personalizeTag/personalizeTagSlice";

const rootReducer = combineReducers({
  items: itemSlice,
  mail: mailSlice,
  folder: folderSlice,
  invoice: invoiceSlice,
  childInvoice: childInvoiceSlice,
  invoiceBuilder: invoiceBuilderSlice,
  sms: smsSlice,
  contact: contactSlice,
  invoiceState: invoiceStateSlice,
  recurringSetting: recurringSettingSlice,
  followupSetting: followupSettingSlice,
  paymentList: paymentListSlice,
  defaultInfo: defaultInfoSlice,
  paymentTerm: paymentTermSlice,
  tax: taxSlice,
  personalizeTag: personalizeTagSlice,
});

export default rootReducer;
