import Popover from "@mui/material/Popover";
import { Search } from "@mui/icons-material";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import formatCurrency from "../../../helpers/utils/formatCurrency";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { getItems } from "../../../state/features/item/itemSlice";
import { ItemInterface } from "../../../state/features/item/item.interface";
import { selectItemState } from "../../../state/features/item/itemSelector";

const StyledButton = styled(CoreButton)(({ theme }) => ({
  justifyContent: "flex-start",
  color: theme.palette.text.secondary,
  maxWidth: "334px",
  "& .MuiButton-endIcon": {
    marginLeft: "auto",
  },
  "&.MuiButton-outlinedInherit": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
  "&.MuiButton-sizeMedium": {
    padding: "9px 10px",
  },
}));

interface IProps {
  callback: (itemInfo: ItemInterface, index: number) => void;
  index: number;
  onOpenDrawer: () => void;
  name?: string;
}

const SelectItem: React.FC<IProps> = ({ callback, index, onOpenDrawer, name }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const onOpenPopup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const onClosePopup = () => setAnchorEl(null);

  const { data: itemList = [], isLoading } = useSelector(selectItemState);

  const handleSelectItem = (item: ItemInterface) => {
    callback(item, index);
    onClosePopup();
  };

  const handleChangeNewItem = () => {
    onOpenDrawer();
    onClosePopup();
  };

  const handleClickItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOpenPopup(event);
    dispatch(getItems({ searchText: searchText, currentPage: 1, limit: 10 }));
  };

  const handleSearch = debounceHandler((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    dispatch(getItems({ searchText: event.target.value, currentPage: 1, limit: 10 }));
  }, 500);

  let content = null;
  if (isLoading) {
    content = Array.from({ length: 5 })
      .fill("")
      .map((_, i) => <Skeleton key={i} variant={"rectangular"} height={60} sx={{ mb: 1 }} />);
  } else if (itemList.length) {
    content = itemList.map((item) => (
      <ListItem key={item.id} disablePadding onClick={() => handleSelectItem(item)}>
        <ListItemButton>
          <ListItemText primary={item.name} secondary={`Rate: ${formatCurrency(item.price, "$")}`} />
        </ListItemButton>
      </ListItem>
    ));
  }

  const open = Boolean(anchorEl);
  const id = open ? "item-select-popover" : undefined;

  return (
    <>
      <StyledButton
        fullWidth
        variant={"outlined"}
        color={"inherit"}
        aria-describedby={id}
        onClick={handleClickItem}
        endIcon={<ArrowDropDownIcon />}
      >
        <Typography variant={"body2"} fontWeight={300} noWrap>
          {name ? <span title={name}>{name}</span> : "Choose Item"}
        </Typography>
      </StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopup}
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          '& input[type="search"]::-webkit-search-decoration, & input[type="search"]::-webkit-search-cancel-button, & input[type="search"]::-webkit-search-results-button, & input[type="search"]::-webkit-search-results-decoration':
            {
              WebkitAppearance: "button",
            },
        }}
      >
        <Stack spacing={1} sx={{ width: "280px", p: 1 }}>
          <CoreTextField
            fullWidth
            size={"small"}
            type={"search"}
            color={"secondary"}
            placeholder={"Search Items"}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            defaultValue={searchText}
            onChange={handleSearch}
          />

          <List sx={{ mt: 0, maxHeight: "350px", overflowY: "auto" }} className={"ib_scroll_bar"}>
            {content}
          </List>

          <CoreButton
            fullWidth
            size={"small"}
            variant={"outlined"}
            color={"secondary"}
            startIcon={<AddCircleIcon />}
            onClick={handleChangeNewItem}
          >
            Add New Item
          </CoreButton>
        </Stack>
      </Popover>
    </>
  );
};

export default SelectItem;
