export const ITEM_UNIT = {
  BOX: "BOX",
  PIECE: "PIECE",
  DOZEN: "DOZEN",
  INCH: "INCHI",
  FOOT: "FEET",
  CM: "CM",
  METER: "METER",
  KM: "KM",
  MG: "MG",
  GRAM: "GRAM",
  KG: "KG",
  LB: "LB",
  HOUR: "HOUR",
};

export const ITEM_UNIT_OPTIONS = {
  [ITEM_UNIT.BOX]: { label: "Box (box)", unit: "box", value: ITEM_UNIT.BOX },
  [ITEM_UNIT.PIECE]: { label: "Piece (pcs)", unit: "pcs", value: ITEM_UNIT.PIECE },
  [ITEM_UNIT.DOZEN]: { label: "Dozen (dozen)", unit: "dozen", value: ITEM_UNIT.DOZEN },
  [ITEM_UNIT.INCH]: { label: "Inches (inch)", unit: "inch", value: ITEM_UNIT.INCH },
  [ITEM_UNIT.FOOT]: { label: "Foot (ft)", unit: "ft", value: ITEM_UNIT.FOOT },
  [ITEM_UNIT.CM]: { label: "Centimeter (cm)", unit: "cm", value: ITEM_UNIT.CM },
  [ITEM_UNIT.METER]: { label: "Meter (m)", unit: "m", value: ITEM_UNIT.METER },
  [ITEM_UNIT.KM]: { label: "Kilometer (km)", unit: "km", value: ITEM_UNIT.KM },
  [ITEM_UNIT.MG]: { label: "Milligram (mg)", unit: "mg", value: ITEM_UNIT.MG },
  [ITEM_UNIT.GRAM]: { label: "Gram (g)", unit: "g", value: ITEM_UNIT.GRAM },
  [ITEM_UNIT.KG]: { label: "Kilogram (kg)", unit: "kg", value: ITEM_UNIT.KG },
  [ITEM_UNIT.LB]: { label: "Pound (lb)", unit: "lb", value: ITEM_UNIT.LB },
  [ITEM_UNIT.HOUR]: { label: "Hour (hr)", unit: "hr", value: ITEM_UNIT.HOUR },
};

export const ITEM_TYPE = {
  SERVICE: "SERVICE",
  PRODUCT: "PRODUCT",
};

export const ITEM_TYPE_OPTIONS = {
  [ITEM_TYPE.SERVICE]: { label: "Service", value: ITEM_TYPE.SERVICE },
  [ITEM_TYPE.PRODUCT]: { label: "Product", value: ITEM_TYPE.PRODUCT },
};
