import httpRequest from "./api/httpRequest";
import { SendMailPayload } from "../state/features/mail/mail.interface";
import { SendSMSPayload, SMSTemplateInterface } from "../state/features/sms/sms.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class SmsService {
  getSMSTemplateData() {
    return httpRequest.get<SMSTemplateInterface>(invoiceAPI + "invoice-builder/default/sms/template");
  }

  smsSend(payload: SendSMSPayload) {
    return httpRequest.post<SendMailPayload>(invoiceAPI + "invoice-builder/invoice/send", payload);
  }
}

export default new SmsService();
