import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItemButton, ListItemIcon, styled, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppDispatch } from "../../state/store";
import {
  selectTrashFolder,
  selectArchivedFolder,
  selectFavoriteFolder,
} from "../../state/features/invoice/invoiceSlice";
import { selectInvoiceFilters } from "../../state/features/invoice/invoiceSelector";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "4px",
  marginBottom: theme.spacing(0.5),
  "&:hover, &.active": {
    backgroundColor: theme.palette.other.secondaryHover,
  },
}));

const SpecialFolderList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isFavorite, isArchived, inTrash } = useSelector(selectInvoiceFilters);

  return (
    <List>
      <StyledListItemButton
        className={isFavorite ? "active" : ""}
        onClick={() => {
          dispatch(selectFavoriteFolder());
        }}
      >
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <StarIcon color='warning' />
        </ListItemIcon>
        <Typography variant='buttonMedium' color='text.secondary'>
          Favorites
        </Typography>
      </StyledListItemButton>

      <StyledListItemButton
        className={isArchived ? "active" : ""}
        onClick={() => {
          dispatch(selectArchivedFolder());
        }}
      >
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <ArchiveIcon color='action' />
        </ListItemIcon>
        <Typography variant='buttonMedium' color='text.secondary'>
          Archive
        </Typography>
      </StyledListItemButton>

      <StyledListItemButton
        className={inTrash ? "active" : ""}
        onClick={() => {
          dispatch(selectTrashFolder());
        }}
      >
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <DeleteIcon color='action' />
        </ListItemIcon>
        <Typography variant='buttonMedium' color='text.secondary'>
          Trash
        </Typography>
      </StyledListItemButton>
    </List>
  );
};

export default SpecialFolderList;
