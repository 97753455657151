export const smsPattern = {
  subject: {
    required: "subject is required!",
    maxLength: {
      value: 100,
      message: "subject must have less than 100 characters!",
    },
  },
  body: {
    required: "message body is required!",
  },
  from: {
    required: "Sender number is required!",
  },
  to: {
    required: "Receiver number is required!",
  },
};
