import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, delay, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import { SendMailPayload } from "./mail.interface";
import MailService from "../../../services/mailService";
import { getMailTemplateDataSuccess, sendMailFailed, sendMailSuccess } from "./mailSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* mailWatcher() {
  yield takeEvery("mail/getMailTemplateData", getMailTemplateDataSaga);
  yield takeEvery("mail/sendMail", sendMailSaga);
}

function* getMailTemplateDataSaga() {
  const response = yield* call(MailService.getMailTemplateData);

  if (response.success) {
    yield put(getMailTemplateDataSuccess(response.data));
  }
}

function* sendMailSaga(action: PayloadAction<SendMailPayload>) {
  yield delay(1000);

  const response = yield* call(MailService.mailSend, action.payload);

  if (response.success) {
    yield put(sendMailSuccess(action.payload));
    showSuccessToaster(response.message);
  } else {
    yield put(sendMailFailed(response.message));
    showErrorToaster(response.message);
  }
}

export default function* mailSaga() {
  yield all([mailWatcher()]);
}
