import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

const PreviewSkeleton = () => {
  return (
    <>
      <Stack spacing={1} p={3} sx={{ height: "85vh", overflow: "hidden" }}>
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Skeleton variant='rounded' width={200} height={80} />
          <Stack alignItems={"flex-end"}>
            <Skeleton variant='text' width={150} height={20} />
            <Skeleton variant='text' width={110} height={20} />
            <Skeleton variant='text' width={100} height={20} />
          </Stack>
        </Stack>
        <Skeleton variant='rectangular' height={60} />
        <Skeleton variant='rounded' height={60} />
        <Stack spacing={1} pt={4}>
          <Skeleton variant='rectangular' height={60} />
          <Skeleton variant='rounded' height={60} />
        </Stack>
        <Stack spacing={1} pt={4}>
          <Skeleton variant='rectangular' height={60} />
          <Skeleton variant='rounded' height={60} />
        </Stack>
        <Stack spacing={1} pt={4}>
          <Skeleton variant='rectangular' height={60} />
          <Skeleton variant='rounded' height={60} />
        </Stack>
      </Stack>
    </>
  );
};

export default PreviewSkeleton;
