import { Box, BoxProps, styled } from "@mui/material";

export const InvoiceBodyPreviewWrp = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.other.outlinedBorder,
}));
export const InvoiceBodyPreview = styled(Box)(({ theme }) => ({
  width: "80%",
  minWidth: "600px",
  maxWidth: "1300px",
  height: "max-content",
  margin: "auto",
  transition: "width 0.3s ease",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    width: "72%",
  },
}));
