const downloadFileFromFileUrl = (fileUrl: string, fileName = "download.pdf") => {
  try {
    if (!fileUrl || !fileName) return;

    const link = document.createElement("a");
    link.download = fileName;
    link.href = fileUrl;
    link.target = "_blank";
    link.setAttribute("download", fileUrl);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log("Error:: ", e);
  }
};

export default downloadFileFromFileUrl;
