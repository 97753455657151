import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import {
  addInvoiceFailed,
  addInvoicesToFoldersFailed,
  addInvoicesToFoldersSuccess,
  addInvoiceSuccess,
  cloneInvoiceFailed,
  cloneInvoiceSuccess,
  deleteInvoiceFailed,
  deleteInvoicesForeverFailed,
  deleteInvoicesForeverSuccess,
  deleteInvoiceSuccess,
  getInvoicesFailed,
  getInvoicesSuccess,
  onShowReportAlert,
  removeFolderFromInvoiceSuccess,
  renamedInvoiceFailed,
  renamedInvoiceSuccess,
  resetInvoiceUpdateSuccess,
  toggleArchiveStatusFailed,
  toggleArchiveStatusSuccess,
  toggleFavStatusSuccess,
} from "./invoiceSlice";
import InvoiceService from "../../../services/invoiceService";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  ArchiveStatusPayload,
  FavoriteStatusPayload,
  GetInvoicesPayload,
  IAddInvoicesToFoldersPayload,
  ICloneInvoicePayload,
  IDeleteForeverPayload,
  InvoiceRemovePayload,
  IRemoveFolderFromInvoicePayload,
  IRenamedInvoicePayload,
} from "./invoice.interface";
import { InvoiceInterface } from "../invoiceBuilder/invoiceBuilderInterface";
import { getInvoiceStateData, getInvoiceStateReportWithDateRange } from "../invoiceState/invoiceStateSlice";

function* invoiceWatcher() {
  yield takeEvery("invoice/getInvoices", getInvoicesSaga);
  yield takeEvery("invoice/fetchInvoices", getInvoicesSaga);
  yield takeEvery("invoice/addInvoice", addInvoiceSaga);
  yield takeEvery("invoice/renamedInvoice", renamedInvoiceSaga);
  yield takeEvery("invoice/deleteInvoices", deleteInvoicesSaga);
  yield takeEvery("invoice/deleteInvoicesForever", deleteInvoicesForeverSaga);
  yield takeEvery("invoice/cloneInvoice", cloneInvoiceSaga);
  yield takeEvery("invoice/toggleFavStatus", toggleInvoiceFavStatusSaga);
  yield takeEvery("invoice/toggleArchiveStatus", toggleInvoiceArchiveStatusSaga);
  yield takeEvery("invoice/addInvoicesToFolders", addInvoicesToFoldersSaga);
  yield takeEvery("invoice/removeFolderFromInvoice", removeInvoiceFromFolderSaga);
}

function* getInvoicesSaga(action: PayloadAction<GetInvoicesPayload>) {
  try {
    const response = yield* call(InvoiceService.getInvoices, action.payload);

    if (response.success) {
      yield put(getInvoicesSuccess(response.data));
    } else {
      yield put(getInvoicesFailed(response.message));
    }
  } catch (err) {
    yield put(getInvoicesFailed("Network or server error"));
  }
}

function* addInvoiceSaga(action: PayloadAction<InvoiceInterface>) {
  try {
    const response = yield* call(InvoiceService.addInvoice, action.payload);

    if (response.success) {
      yield put(addInvoiceSuccess(response.data));
    } else {
      yield put(addInvoiceFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(addInvoiceFailed());
  }
}
function* renamedInvoiceSaga(action: PayloadAction<IRenamedInvoicePayload>) {
  try {
    const response = yield* call(InvoiceService.renamedInvoice, action.payload);

    if (response.success) {
      yield put(renamedInvoiceSuccess(action.payload));
      showSuccessToaster("Invoice renamed successfully!");
    } else {
      yield put(renamedInvoiceFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(renamedInvoiceFailed());
  }
}

function* deleteInvoicesSaga(action: PayloadAction<InvoiceRemovePayload>) {
  try {
    const response = yield* call(InvoiceService.deleteInvoices, action.payload);

    if (response.success) {
      yield put(deleteInvoiceSuccess(action.payload));
      yield put(onShowReportAlert());
      if (action.payload.isTrashed) {
        showSuccessToaster("Invoice moved to trash successfully!");
      } else {
        showSuccessToaster(
          "Invoice" + (action.payload.ids?.length > 1 ? "s" : "") + " restored from trash successfully!"
        );
      }
      yield put(getInvoiceStateData());
      yield put(getInvoiceStateReportWithDateRange());
    } else {
      yield put(deleteInvoiceFailed());
      if (action.payload.isTrashed) showErrorToaster("Failed invoice delete");
      else showErrorToaster("Failed restore invoice" + (action.payload.ids?.length > 1 ? "s" : ""));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteInvoicesForeverSaga(action: PayloadAction<IDeleteForeverPayload>) {
  try {
    const response = yield* call(InvoiceService.deleteInvoicesForever, action.payload);

    if (response.success) {
      yield put(deleteInvoicesForeverSuccess(action.payload));
      showSuccessToaster("Invoice deleted forever successfully!");
      yield put(onShowReportAlert());
    } else {
      yield put(deleteInvoicesForeverFailed());
      showErrorToaster("Failed invoice deleted forever");
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* cloneInvoiceSaga(action: PayloadAction<ICloneInvoicePayload>) {
  try {
    const response = yield* call(InvoiceService.cloneInvoice, action.payload);

    if (response.success) {
      yield put(cloneInvoiceSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(cloneInvoiceFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* toggleInvoiceFavStatusSaga(action: PayloadAction<FavoriteStatusPayload>) {
  try {
    const response = yield* call(InvoiceService.updateInvoiceFavoriteStatus, action.payload);

    if (response.success) {
      yield put(toggleFavStatusSuccess(action.payload));
    } else {
      yield put(getInvoicesFailed(response.message));
    }
  } catch (err) {
    yield put(getInvoicesFailed("Network or server error"));
  }
}

function* toggleInvoiceArchiveStatusSaga(action: PayloadAction<ArchiveStatusPayload>) {
  try {
    const response = yield* call(InvoiceService.updateArchiveStatus, action.payload);

    if (response.success) {
      yield put(toggleArchiveStatusSuccess(action.payload));
      showSuccessToaster(
        action.payload.isArchived ? "Invoice archived successfully!" : "Invoice unarchived successfully!"
      );

      yield put(getInvoiceStateData());
      yield put(getInvoiceStateReportWithDateRange());
      yield put(onShowReportAlert());
    } else {
      yield put(toggleArchiveStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getInvoicesFailed("Network or server error"));
  }
}

function* addInvoicesToFoldersSaga(action: PayloadAction<IAddInvoicesToFoldersPayload>) {
  try {
    const response = yield* call(InvoiceService.addInvoicesToFolders, action.payload);

    if (response.success) {
      yield put(addInvoicesToFoldersSuccess(response.data));
      showSuccessToaster("Invoice moved to folder successfully!");
    } else {
      yield put(addInvoicesToFoldersFailed());
      showErrorToaster("Failed add invoice into folder");
    }
  } catch (err) {
    yield put(addInvoicesToFoldersFailed());
    showErrorToaster("Network or server error");
  }
}

function* removeInvoiceFromFolderSaga(action: PayloadAction<IRemoveFolderFromInvoicePayload>) {
  try {
    const response = yield* call(InvoiceService.removeFolderFromInvoice, action.payload);

    if (response.success) {
      yield put(removeFolderFromInvoiceSuccess(response.data));
      showSuccessToaster("Successfully removed folder from invoice");
    } else {
      yield put(resetInvoiceUpdateSuccess());
      showErrorToaster("Failed removed folder from invoice");
    }
  } catch (err) {
    yield put(resetInvoiceUpdateSuccess());
    showSuccessToaster("Network or server error");
  }
}

export default function* invoiceSaga() {
  yield all([invoiceWatcher()]);
}
