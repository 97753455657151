import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import BuilderBody from "./BuilderBody";
import BuilderHeader from "./BuilderHeader";
import PreviewAndSend from "../Preview/PreviewAndSend";
import CircleLoader from "../../common/LoadingView/CircleLoader";
import { EmptyFormListIcon } from "../../common/Icons/EmptyDataIcons";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import { BUILDER_OPTIONS } from "../../helpers/constant/invoiceConstant";
import {
  changeCurrentBuilderTab,
  getInvoiceBuilderData,
  resetInvoiceBuilderState,
  resetInvoiceBuilderUpdateTime,
} from "../../state/features/invoiceBuilder/invoiceBuilderSlice";
import { selectInvoiceBuilderState } from "../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { getPaymentList } from "../../state/features/paymentList/paymentListSlice";
import { selectFormattedPaymentList } from "../../state/features/paymentList/paymentListSelector";

type InvoiceUseParams = {
  invoiceId: string;
};

type LocationType = {
  tab?: number;
};

type UseEffectReturnFunc = () => void;

const InvoiceBuilder: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationType>();
  const { invoiceId } = useParams<InvoiceUseParams>();

  const paymentList = useSelector(selectFormattedPaymentList);
  const { data, isLoading, isError, currentBuilderTab } = useSelector(selectInvoiceBuilderState);

  useEffect((): UseEffectReturnFunc => {
    if (!data.id && invoiceId) {
      dispatch(getInvoiceBuilderData({ invoiceId: +invoiceId }));
    }

    if (paymentList?.length === 1) dispatch(getPaymentList());

    return () => dispatch(resetInvoiceBuilderState());
  }, [invoiceId]);

  useEffect(() => {
    if (location.state?.tab) {
      dispatch(changeCurrentBuilderTab(location.state.tab));
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(resetInvoiceBuilderUpdateTime());
  }, []);

  if (isLoading) {
    return (
      <div>
        <BuilderHeader
          selectedOption={currentBuilderTab}
          setSelectedOption={(option: number) => dispatch(changeCurrentBuilderTab(option))}
        />
        <CircleLoader />
      </div>
    );
  } else if (!isLoading && isError) {
    return (
      <Box sx={{ height: "80vh" }}>
        <GlobalEmptyPage icon={<EmptyFormListIcon />} title={"404!! No Invoice Found"} />
      </Box>
    );
  }

  return (
    <div>
      <BuilderHeader
        selectedOption={currentBuilderTab}
        setSelectedOption={(option: number) => dispatch(changeCurrentBuilderTab(option))}
      />
      {currentBuilderTab === BUILDER_OPTIONS.BUILDER && <BuilderBody />}
      {currentBuilderTab === BUILDER_OPTIONS.PREVIEW_AND_SEND && <PreviewAndSend />}
      {/*{currentBuilderTab === BUILDER_OPTIONS.SETTINGS && <SettingsPage />}*/}
    </div>
  );
};

export default InvoiceBuilder;
