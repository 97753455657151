export const createItemPattern = {
  name: {
    required: "Item name is required!",
    maxLength: {
      value: 100,
      message: "Item name must have less than 100 characters!",
    },
    setValueAs(value: string) {
      return value.trim();
    },
  },
  description: {
    maxLength: {
      value: 255,
      message: "Description must have less than 255 characters!",
    },
    setValueAs(value: string) {
      return value ? value.trim() : "";
    },
  },
  itemType: {
    required: "Item type is required!",
  },
  quantity: {
    required: "Item quantity is required!",
    pattern: {
      value: /^[+]?\d+([.]\d+)?$/,
      message: "Positive number only",
    },
    maxLength: {
      value: 4,
      message: "Maximum limit of quantity is 9999",
    },
  },
  price: {
    required: "Item price is required!",
    pattern: {
      value: /^[+]?\d+([.]\d+)?$/,
      message: "Positive number only",
    },
  },
  tax: {
    required: false,
    pattern: {
      value: /^[+]?\d+([.]\d+)?$/,
      message: "Positive number only",
    },
  },
};
