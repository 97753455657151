import React from "react";
import { Stack, styled, Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ButtonWrapper = styled(Stack)(() => ({
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  bottom: 10,
  textAlign: "center",
  cursor: "pointer",
}));

interface PropsI {
  onClick?: () => void;
  isScrollToSend?: boolean;
}

const GoToSendInvoiceButton: React.FC<PropsI> = ({ isScrollToSend, onClick }) => {
  return (
    <ButtonWrapper
      spacing={0.5}
      sx={{
        left: { xs: 20, xxl: 100 },
        width: { xs: 80, lg: 120 },
      }}
      onClick={onClick}
    >
      <Typography variant={"body1"}>{isScrollToSend ? "Go to Top" : "Scroll to send your invoice"}</Typography>
      <CoreButton
        color={"secondary"}
        variant={"contained"}
        sx={{
          borderRadius: "50%",
          padding: "6px !important",
          transform: isScrollToSend ? "rotate(180deg)" : "rotate(0)",
          transition: "all 0.2s ease",
        }}
      >
        <ArrowDownwardIcon />
      </CoreButton>
    </ButtonWrapper>
  );
};

export default GoToSendInvoiceButton;
