import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { Box, Divider, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import CoreButton from "../../common/Button/CoreButton";
import CircleLoader from "../../common/LoadingView/CircleLoader";
import { EmptyFormListIcon } from "../../common/Icons/EmptyDataIcons";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import useBoolean from "../../hooks/useBoolean";
import getFullName from "../../helpers/utils/getFullName";
import formatCurrency from "../../helpers/utils/formatCurrency";
import { PAYMENT_STATUS } from "../../helpers/constant/invoiceConstant";
import { getChildInvoices } from "../../state/features/childInvoice/childInvoiceSlice";
import { InvoiceListItemInterface } from "../../state/features/invoice/invoice.interface";
import { selectChildInvoiceState } from "../../state/features/childInvoice/childInvoiceSelector";

interface IProps {
  invoice: InvoiceListItemInterface;
  onClickManualRecordPayment: (childInvoiceId: number, totalAmount: number) => void;
}

const ChildInvoices: React.FC<IProps> = ({ invoice, onClickManualRecordPayment }) => {
  const dispatch = useDispatch();
  const scrollDivRef = useRef<any>();

  const { id, name, invoiceNumber, client } = invoice || {};
  const { data: childInvoiceList = [], totalUnpaidInvoice, isLoading } = useSelector(selectChildInvoiceState);
  const { value: showPreview, setTrue: onShowPreview, setFalse: onHidePreview } = useBoolean(false);
  const [selectedInvoicePdf, setSelectedInvoicePdf] = useState("");

  useEffect(() => {
    dispatch(getChildInvoices({ invoiceId: id as number }));
  }, []);

  const handleShowPreview = (pdfUrl: string) => {
    setSelectedInvoicePdf(pdfUrl);
    onShowPreview();
  };

  const handleHidePreview = () => {
    setSelectedInvoicePdf("");
    onHidePreview();
  };

  let customer: string | undefined;
  if (client?.id) {
    customer = getFullName(client.firstName, client.lastName) || client.email || client.number;
  }

  // ## decide what to render
  let content: any = null;
  if (isLoading) {
    content = <CircleLoader />;
  } else if (childInvoiceList?.length === 0) {
    content = (
      <GlobalEmptyPage
        icon={<EmptyFormListIcon />}
        title={"Child invoices Not Found"}
        description={"All recurring invoices that will send to the customer will be shown here."}
      />
    );
  } else if (childInvoiceList?.length > 0) {
    if (showPreview && selectedInvoicePdf) {
      content = (
        <iframe
          id='child_invoice_preview'
          title='PDF in an i-Frame'
          src={selectedInvoicePdf}
          height={`${scrollDivRef.current.clientHeight - 94}px`}
          width='100%'
        ></iframe>
      );
    } else {
      content = childInvoiceList.map((childInvoice) => (
        <React.Fragment key={childInvoice.id}>
          <ListItem sx={{ px: 3, py: 1 }}>
            <Stack direction={"row"} justifyContent={"space-between"} spacing={2} sx={{ width: "100%" }}>
              <Stack spacing={0.5}>
                <Typography variant={"body2"}>{customer}</Typography>
                <Typography variant={"caption"} color={"secondary.main"}>
                  {childInvoice.invoice?.invoiceNumber}
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography variant={"caption"} color={"text.secondary"}>
                    Due Date: {childInvoice.dueDate ? format(new Date(childInvoice.dueDate), "MMM dd, yyyy") : "-"}
                  </Typography>
                  {childInvoice.invoice?.status === PAYMENT_STATUS.PAID && (
                    <>
                      <Divider light orientation={"vertical"} flexItem />
                      <Typography variant={"caption"} color={"text.secondary"}>
                        Payment Date:{" "}
                        {childInvoice.paymentDate ? format(new Date(childInvoice.paymentDate), "MMM dd, yyyy") : "-"}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>

              <Stack spacing={0.5} alignItems={"flex-end"}>
                <Typography variant={"body2"} color={"text.primary"}>
                  {formatCurrency(childInvoice.totalAmount, "$")}
                </Typography>
                <Typography
                  variant={"overline"}
                  color={
                    childInvoice.invoice?.status === PAYMENT_STATUS.PAID
                      ? "success.main"
                      : childInvoice.invoice?.status === PAYMENT_STATUS.OUTSTANDING
                      ? "secondary"
                      : "error"
                  }
                  sx={{ fontWeight: 600 }}
                >
                  {childInvoice.invoice?.status}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  {childInvoice.invoice?.status !== PAYMENT_STATUS.PAID && (
                    <CoreButton
                      color={"secondary"}
                      variant={"outlined"}
                      size={"small"}
                      onClick={() => onClickManualRecordPayment(childInvoice.id as number, childInvoice.totalAmount)}
                    >
                      Manually Record Payment
                    </CoreButton>
                  )}

                  <CoreButton
                    color={"secondary"}
                    variant={"contained"}
                    size={"small"}
                    onClick={() => handleShowPreview(childInvoice.pdfUrl as string)}
                  >
                    Preview
                  </CoreButton>
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
          <Divider light component='li' />
        </React.Fragment>
      ));
    }
  }

  return (
    <Box sx={{ height: "100%" }} ref={scrollDivRef}>
      {showPreview && selectedInvoicePdf ? (
        <Stack direction={"row"} alignItems={"center"} sx={{ p: 2, cursor: "pointer" }} onClick={handleHidePreview}>
          <IconButton color={"default"}>
            <ArrowBack />
          </IconButton>
          <Typography variant={"body2"} color={"text.secondary"}>
            Back
          </Typography>
        </Stack>
      ) : (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={3}>
          <Typography variant={"body2"}>All Child invoices</Typography>
          <Typography variant={"caption"}>Unpaid Invoices : {formatCurrency(totalUnpaidInvoice, "$")}</Typography>
        </Stack>
      )}
      <Divider light />
      <List>{content}</List>
    </Box>
  );
};

export default ChildInvoices;
