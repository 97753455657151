import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { onCloseReportAlert } from "../../state/features/invoice/invoiceSlice";
import { selectShowReportAlert } from "../../state/features/invoice/invoiceSelector";

const InvoiceReportWarning = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectShowReportAlert);
  return (
    <Box sx={{ marginTop: open ? 0 : "-24px !important" }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                dispatch(onCloseReportAlert());
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          variant='filled'
          severity='info'
        >
          It may take up to 2-3 minutes to update payment reports after making a payment, trashing, archiving, or
          deleting an invoice.
        </Alert>
      </Collapse>
    </Box>
  );
};

export default InvoiceReportWarning;
