import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import { IGetPaymentTermsPayload, PaymentTermInterface } from "../state/features/paymentTerm/paymentTerm.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class PaymentTermService {
  getPaymentTerms(params: IGetPaymentTermsPayload) {
    return httpRequest.get<WithPagination<PaymentTermInterface[]>>(
      invoiceAPI + "invoice-builder/payment/term/list",
      params
    );
  }

  addPaymentTerm(body: PaymentTermInterface) {
    return httpRequest.post<PaymentTermInterface>(invoiceAPI + "invoice-builder/payment/term/create", body);
  }

  updatePaymentTerm(body: PaymentTermInterface) {
    return httpRequest.put<PaymentTermInterface>(invoiceAPI + "invoice-builder/payment/term/update", body);
  }

  deletePaymentTerm(id: number) {
    return httpRequest.delete<number>(invoiceAPI + "invoice-builder/payment/term/delete", { id: id });
  }
}

export default new PaymentTermService();
