import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceFooterBasic: React.FC = () => {
  const { data } = useSelector(selectInvoiceBuilderState);

  return (
    <Box px={10} py={3}>
      <Stack spacing={4}>
        {data.termsAndCondition && (
          <>
            <Stack spacing={1}>
              <Typography variant={"body2Semibold"} color={"other.grayDark"}>
                TERMS & CONDITION
              </Typography>
              <Typography variant={"caption"} color={"other.grayDark"}>
                <div className='preview' dangerouslySetInnerHTML={{ __html: data.termsAndCondition }}></div>
              </Typography>
            </Stack>
          </>
        )}

        {data.note && (
          <>
            <Stack spacing={1}>
              <Typography variant={"body2Semibold"} color={"other.grayDark"}>
                NOTES
              </Typography>
              <Typography variant={"caption"} color={"other.grayDark"}>
                <div className='preview' dangerouslySetInnerHTML={{ __html: data.note }}></div>
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default InvoiceFooterBasic;
