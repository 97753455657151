import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableHead from "@mui/material/TableHead";
import { Box, Checkbox, Paper, Table, TableCell, TableRow } from "@mui/material";
import SingleItemInTrash from "./SingleItemInTrash";
import InvoiceLIstSkeleton from "../../common/LoadingView/InvoiceListSkeleton";
import {
  EmptyArchiveIcon,
  EmptyfavoriteIcon,
  EmptyFormListIcon,
  EmptyTrashIcon,
} from "../../common/Icons/EmptyDataIcons";
import { AppDispatch } from "../../state/store";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import { selectInvoiceState } from "../../state/features/invoice/invoiceSelector";
import removeFalsyObjectProperty from "../../helpers/utils/removeFalsyObjectProperty";
import { deselectAllInvoice, getInvoices, selectedAllInvoice } from "../../state/features/invoice/invoiceSlice";
import InvoiceListSingleItemTable from "./InvoiceListSingleItemTable";
import { InvoiceListTableContainer } from "./InvoiceHomePageStyles";

const getEmptyPageContent = (
  favorite: boolean,
  archive: boolean,
  trash: boolean
): { icon: ReactElement; title: string; description: string } => {
  if (favorite) {
    return {
      icon: <EmptyfavoriteIcon />,
      title: "Favorites is Empty",
      description: "Click the Star Button on any invoice to add a Favorites.",
    };
  } else if (archive) {
    return {
      icon: <EmptyArchiveIcon />,
      title: "Archive is empty",
      description: "You can archive your invoices here if you want",
    };
  } else if (trash) {
    return {
      icon: <EmptyTrashIcon />,
      title: "Trash is Empty",
      description:
        "All invoices that you deleted will be visible here for 30 days. Within this interval you can restore your invoices again.",
    };
  } else {
    return {
      icon: <EmptyFormListIcon />,
      title: "No Outstanding Invoices",
      description:
        'You don\'t have any outstanding invoices. To create a new invoice, click the "Create Invoice" button at the top of the page.',
    };
  }
};

const TableHeader = ({
  onChange,
  checked,
  indeterminate,
}: {
  onChange: () => void;
  checked: boolean;
  indeterminate: boolean;
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell width={60}>
          <Checkbox
            size={"small"}
            edge='start'
            color='secondary'
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChange}
            sx={{
              padding: "4px",
              marginLeft: 0,
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
        </TableCell>
        <TableCell>Invoices</TableCell>
        <TableCell width={200} sx={{ minWidth: 200 }}>
          Invoice Number
        </TableCell>
        <TableCell width={180} sx={{ minWidth: 180 }}>
          Customer
        </TableCell>
        <TableCell width={130} sx={{ minWidth: 130 }}>
          Issue/Start Date
        </TableCell>
        <TableCell width={130} sx={{ minWidth: 130 }}>
          Due/End Date
        </TableCell>
        <TableCell width={100} sx={{ minWidth: 100 }}>
          Amount
        </TableCell>
        <TableCell width={100} sx={{ minWidth: 100 }}>
          Status
        </TableCell>
        <TableCell width={80} sx={{ minWidth: 80 }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const InvoiceListTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, isLoading, currentPage, limit, count, renderId, filters, selectedInvoiceIds } =
    useSelector(selectInvoiceState);
  const { searchText, orderBy, paymentStatus, folderId, isFavorite, isArchived, inTrash } = filters;

  useEffect(() => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage,
      limit,
      searchText,
      orderBy,
      paymentStatus,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getInvoices(payload));
  }, [folderId, searchText, orderBy, paymentStatus, isFavorite, isArchived, inTrash, renderId]);

  const onChangePage = (newPage: number) => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage: newPage,
      limit: limit,
      searchText,
      orderBy,
      paymentStatus,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getInvoices(payload));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage: newPage,
      limit: perPage,
      searchText,
      orderBy,
      paymentStatus,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getInvoices(payload));
  };

  // decide what to render
  let content: null | JSX.Element = null;
  if (isLoading) {
    content = <InvoiceLIstSkeleton count={8} />;
  } else if (data.length === 0) {
    const { icon, title, description } = getEmptyPageContent(isFavorite, isArchived, inTrash);
    content = <GlobalEmptyPage icon={icon} title={title} description={description} />;
  } else if (data.length > 0) {
    const selectAll = data.every((invoice) => selectedInvoiceIds.includes(invoice.id as number));
    const indeterminate = !selectAll && selectedInvoiceIds.length > 0;

    content = (
      <InvoiceListTableContainer className='ib_scroll_bar_light'>
        <Table stickyHeader>
          <TableHeader
            checked={selectAll}
            indeterminate={indeterminate}
            onChange={() => {
              selectAll ? dispatch(deselectAllInvoice()) : dispatch(selectedAllInvoice());
            }}
          />

          {data.map((invoice) => {
            if (inTrash) {
              return <SingleItemInTrash invoice={invoice} key={invoice.id} />;
            }
            return <InvoiceListSingleItemTable invoice={invoice} key={invoice.id} />;
          })}
        </Table>
      </InvoiceListTableContainer>
    );
  }

  return (
    <Paper elevation={0} sx={{ width: "100%", overflow: "hidden", display: "grid" }}>
      {content}
      <Box mb={-2}>
        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </Box>
    </Paper>
  );
};

export default InvoiceListTable;
