import Popover from "@mui/material/Popover";
import { Search } from "@mui/icons-material";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { InputAdornment, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, styled } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import compressString from "../../../helpers/utils/compressString";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { selectTaxState } from "../../../state/features/tax/taxSelector";
import { TaxInterface } from "../../../state/features/tax/tax.interface";
import { getTaxes } from "../../../state/features/tax/taxSlice";

const StyledButton = styled(CoreButton)(({ theme }) => ({
  justifyContent: "flex-start",
  color: theme.palette.text.secondary,
  "& .MuiButton-endIcon": {
    marginLeft: "auto",
  },
  "&.MuiButton-outlinedInherit": {
    borderColor: theme.palette.other.outlinedBorderDarker,
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
  "&.MuiButton-sizeMedium": {
    padding: "6px 10px",
  },
}));

interface IProps {
  callback: (taxInfo: TaxInterface) => void;
  name?: string;
}

const TaxSelect: React.FC<IProps> = ({ callback, name }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const onOpenPopup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const onClosePopup = () => setAnchorEl(null);

  const { data: taxList = [], isLoading, isSuccess } = useSelector(selectTaxState);

  const handleTaxSelect = (tax: TaxInterface) => {
    callback(tax);
    onClosePopup();
  };

  const handleClickTax = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOpenPopup(event);
    if (!isSuccess) {
      dispatch(getTaxes({ searchText: searchText, currentPage: 1, limit: 20 }));
    }
  };

  const handleSearch = debounceHandler((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, 500);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = Array.from({ length: 5 })
      .fill("")
      .map((_, i) => <Skeleton key={i} variant={"rectangular"} height={60} sx={{ mb: 1 }} />);
  } else if (taxList.length) {
    content = [{ id: new Date().getTime(), name: "None", taxPercentage: 0 }, ...taxList]
      .filter((tax) => {
        if (searchText) return tax.name.toLowerCase().includes(searchText.toLowerCase());
        return true;
      })
      .map((taxItem) => (
        <ListItem key={taxItem.id} disablePadding onClick={() => handleTaxSelect(taxItem)}>
          <ListItemButton>
            <ListItemText primary={taxItem.name} secondary={taxItem.taxPercentage + "%"} />
          </ListItemButton>
        </ListItem>
      ));
  }

  const open = Boolean(anchorEl);
  const id = open ? "tax-select-popover" : undefined;

  return (
    <>
      <StyledButton
        fullWidth
        variant={"outlined"}
        color={"inherit"}
        aria-describedby={id}
        onClick={handleClickTax}
        endIcon={<ArrowDropDownIcon />}
        sx={{ fontWeight: 300 }}
      >
        {name ? <span title={name}>{compressString(name, 30)}</span> : "Choose Tax"}
      </StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopup}
        elevation={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          '& input[type="search"]::-webkit-search-decoration, & input[type="search"]::-webkit-search-cancel-button, & input[type="search"]::-webkit-search-results-button, & input[type="search"]::-webkit-search-results-decoration':
            {
              WebkitAppearance: "button",
            },
        }}
      >
        <Stack spacing={1} sx={{ width: { md: 200, lg: 310 }, p: 1 }}>
          <CoreTextField
            fullWidth
            size={"small"}
            type={"search"}
            color={"secondary"}
            placeholder={"Search taxes"}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            defaultValue={searchText}
            onChange={handleSearch}
          />

          <List sx={{ mt: 0, maxHeight: "350px", overflowY: "auto" }} className={"ib_scroll_bar"}>
            {content}
          </List>
        </Stack>
      </Popover>
    </>
  );
};

export default TaxSelect;
