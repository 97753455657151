import httpRequest from "./api/httpRequest";
import { PersonalizeTagInterface } from "../state/features/personalizeTag/personalizeTag.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class PersonalizeTagService {
  getPersonalizeTags() {
    return httpRequest.get<PersonalizeTagInterface[]>(invoiceAPI + "invoice-builder/personalize/tag/list");
  }
}

export default new PersonalizeTagService();
