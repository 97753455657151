import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, FormHelperText, Grid, InputLabel, Stack } from "@mui/material";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import SendInvoiceHeading from "./SendInvoiceHeading";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import CoreInputLabel from "../../../common/TextField/CoreInputLabel";
import PersonalizedTag from "../../../common/Personalized/PersonalizedTag";
import { castArray } from "../../../helpers/utils/castArray";
import { smsPattern } from "../../../helpers/validation/smsPattern";
import { insertPersonalizedTag } from "../../../helpers/utils/insertPersonalizedTag";
import { INVOICE_SEND_TYPE } from "../../../helpers/constant/invoiceSettingConstant";
import { selectSms } from "../../../state/features/sms/smsSelector";
import {
  selectInvoiceBuilderId,
  selectInvoiceClient,
} from "../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { SMSFormInterface } from "../../../state/features/sms/sms.interface";
import { getSMSTemplateData, resetSMSState, sendSms } from "../../../state/features/sms/smsSlice";
import { selectDefaultInfoData } from "../../../state/features/defaultInfo/defaultInfoSelector";

const SentWithSms: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bodyRef = useRef(null);
  const [needToRedirect, setNeedToRedirect] = useState(false);

  const { control, setValue, handleSubmit } = useForm<SMSFormInterface>({ mode: "onSubmit" });
  const invoiceId = useSelector(selectInvoiceBuilderId);
  const { number } = useSelector(selectInvoiceClient) || {};
  const { data, isSuccess, isLoading, isSMSSent } = useSelector(selectSms);
  const { phone: fromPhoneNumber } = useSelector(selectDefaultInfoData);

  const { body } = data || {};

  useEffect(() => {
    if (!isSuccess) dispatch(getSMSTemplateData());
  }, []);

  useEffect(() => {
    if (isSuccess && body) {
      setValue("body", body);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (fromPhoneNumber) setValue("from", fromPhoneNumber);
    if (number) setValue("to", number);
  }, [fromPhoneNumber, number]);

  useEffect(() => {
    if (isSMSSent) {
      dispatch(resetSMSState());
      if (needToRedirect) history.push("/ibuilder/invoices");
    }
  }, [isSMSSent]);

  const submitHandler = (data: SMSFormInterface) => {
    const payload = {
      contentType: INVOICE_SEND_TYPE.SMS,
      invoiceId: invoiceId as number,
      to: castArray(data.to),
      from: data.from,
      body: data.body,
    };

    dispatch(sendSms(payload));
  };

  return (
    <>
      <SendInvoiceHeading heading='Send Via Text' icon={<TextsmsOutlinedIcon />} />

      <Box component='form' onSubmit={handleSubmit(submitHandler)} sx={{ p: 3, pt: 0 }}>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"from"}
              defaultValue={""}
              rules={smsPattern.from || null}
              render={({ field: { value }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <CoreInputLabel variant='standard'>Sender Number</CoreInputLabel>
                  <CoreTextField
                    size='small'
                    type='phone'
                    color='secondary'
                    placeholder={"Sender phone number"}
                    value={value}
                    disabled={true}
                    error={invalid}
                    helperText={invalid && error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name={"to"}
              rules={smsPattern.to || null}
              render={({ field: { value }, fieldState: { invalid, error } }) => (
                <FormControl required fullWidth error={invalid}>
                  <CoreInputLabel variant='standard'>To</CoreInputLabel>
                  <CoreTextField
                    size='small'
                    type='phone'
                    color='secondary'
                    placeholder={"Receiver phone number"}
                    value={value}
                    disabled={true}
                    error={invalid}
                    helperText={invalid && error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={"body"}
              rules={smsPattern.body || null}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <Stack direction='row' alignItems='center' justifyContent={"space-between"} mt={2} mb={1}>
                    <InputLabel variant='standard' shrink sx={{ fontSize: "18px", position: "relative", top: 16 }}>
                      SMS Body
                    </InputLabel>
                    <PersonalizedTag
                      onClick={(personalizedTag) =>
                        insertPersonalizedTag(personalizedTag, bodyRef, (value) => setValue("body", value))
                      }
                      isSMS={true}
                    />
                  </Stack>

                  <CoreTextField
                    multiline
                    size='small'
                    type='text'
                    color='secondary'
                    minRows={4}
                    maxRows={6}
                    inputRef={bodyRef}
                    {...field}
                  />
                  <FormHelperText error={invalid}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 3 }}>
              <LoadingButton
                size={"small"}
                type={"submit"}
                isLoading={needToRedirect && isLoading}
                variant='outlined'
                color='secondary'
                onClick={() => setNeedToRedirect(true)}
              >
                Send Text & Exit
              </LoadingButton>
              <LoadingButton
                size={"small"}
                type={"submit"}
                isLoading={!needToRedirect && isLoading}
                variant='contained'
                color='secondary'
                onClick={() => setNeedToRedirect(false)}
              >
                Send SMS
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SentWithSms;
