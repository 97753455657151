import TableCell from "@mui/material/TableCell";
import { Box, Stack, styled, tableCellClasses } from "@mui/material";

export const ItemsMainWrapper = styled(Box)(() => ({
  height: "calc(100vh - 96px)",
  background: "linear-gradient(0deg, rgba(148, 157, 178, 0.12), rgba(148, 157, 178, 0.12)), #FFFFFF",
}));

export const HeaderWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1.5),
}));

export const ItemsBodyWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 85px)",
  flex: "1 1 100%",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

// items table
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e4e6ed",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
  },
}));
