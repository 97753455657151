import React from "react";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AddOrEditFolderName from "./AddOrEditFolderName";
import CoreButton from "../../common/Button/CoreButton";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../hooks/useBoolean";

const CreateFolder: React.FC = () => {
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  return (
    <Box mb={1.5}>
      <CoreButton fullWidth variant='contained' color='secondary' startIcon={<Add />} onClick={onOpenModal}>
        Create New Folder
      </CoreButton>

      <BasicModalWithHeader
        title='Create New Folder'
        icon={<CreateNewFolderIcon />}
        open={openModal}
        onClose={onCloseModal}
      >
        <AddOrEditFolderName onClose={onCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
    </Box>
  );
};

export default CreateFolder;
