import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  hover?: boolean;
}

const RouteLink: React.FC<Props> = ({ to = "", children, hover = false, ...rest }) => {
  return (
    <Link
      to={to}
      style={{
        color: "inherit",
        textDecoration: hover ? "underline" : "none",
        display: "inline-flex",
      }}
      {...rest}
    >
      {children || null}
    </Link>
  );
};

export default RouteLink;
