import React from "react";
import { Grid } from "@mui/material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { green, red } from "@mui/material/colors";
import SingleReportCard from "./SingleReportCard";

// const formatNumber = (number=0) => {
//     return number.toLocaleString('en-US');
// }

const ReportFooter = () => {
  return (
    <Grid container spacing={2} sx={{ mt: 0 }}>
      <Grid item xs={12} sm={6} lg={3}>
        <SingleReportCard
          title={"Due"}
          subtitle={"Due from 10 invoices"}
          amount={"$2233.51"}
          icon={<PendingActionsIcon />}
          color={"warning.main"}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SingleReportCard
          title={"Overdue"}
          subtitle={"Overdue from 10 invoices"}
          amount={"$2233.51"}
          icon={<EventBusyIcon />}
          color={red[500]}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SingleReportCard
          title={"Pending"}
          subtitle={"45 invoices needed to sent "}
          amount={"$9986.91"}
          icon={<ForwardToInboxIcon />}
          color={"primary.light"}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SingleReportCard
          title={"Collected"}
          subtitle={"Collected from 45 invoives"}
          amount={"$5897.98"}
          icon={<PaidOutlinedIcon />}
          color={green[300]}
        />
      </Grid>
    </Grid>
  );
};

export default ReportFooter;
