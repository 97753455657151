export const addressPattern = {
  address: {
    maxLength: {
      value: 50,
      message: "Address must have less than 50 characters!",
    }
  },
  city: {
    maxLength: {
      value: 30,
      message: "City must have less than 30 characters!",
    }
  },
  state: {
    maxLength: {
      value: 30,
      message: "Address must have less than 30 characters!",
    }
  },
  country: {
    maxLength: {
      value: 30,
      message: "Address must have less than 30 characters!",
    }
  },
  zip: {
    maxLength: {
      value: 10,
      message: "Address must have less than 10 characters!",
    }
  }
}