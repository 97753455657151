import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, FormHelperText, Grid, InputLabel, Stack } from "@mui/material";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import SendInvoiceLoadingContent from "./SendInvoiceLoadingContent";
import SendInvoiceHeading from "./SendInvoiceHeading";
import BasicEditor from "../../../common/Editor/BasicEditor";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import CoreInputLabel from "../../../common/TextField/CoreInputLabel";
import PersonalizedTag from "../../../common/Personalized/PersonalizedTag";
import { castArray } from "../../../helpers/utils/castArray";
import { mailPattern } from "../../../helpers/validation/mailPattern";
import { insertPersonalizedTag } from "../../../helpers/utils/insertPersonalizedTag";
import { INVOICE_SEND_TYPE } from "../../../helpers/constant/invoiceSettingConstant";
import { selectMailState } from "../../../state/features/mail/mailSelector";
import {
  selectInvoiceBuilderId,
  selectInvoiceClient,
} from "../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { MailFormInterface } from "../../../state/features/mail/mail.interface";
import { getMailTemplateData, resetMailState, sendMail } from "../../../state/features/mail/mailSlice";
import { selectDefaultInfoData } from "../../../state/features/defaultInfo/defaultInfoSelector";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModal from "../../../common/Modal/BasicModal";

const SendWithMail: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subjectRef = useRef(null);
  const bodyRef = useRef(null);
  const [needToRedirect, setNeedToRedirect] = useState(false);

  const invoiceId = useSelector(selectInvoiceBuilderId);
  const { data, isSuccess, isLoading, isMailSent } = useSelector(selectMailState);
  const { subject, body } = data || {};
  const { email: fromEmail } = useSelector(selectDefaultInfoData);
  const { email = "" } = useSelector(selectInvoiceClient) || {};

  const { control, setValue, handleSubmit } = useForm<MailFormInterface>({ mode: "onSubmit" });

  useEffect(() => {
    if (!isSuccess) dispatch(getMailTemplateData());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setValue("subject", subject);
      setValue("body", body);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (fromEmail) setValue("from", fromEmail);
    if (email) setValue("to", email);
  }, [fromEmail, email]);

  useEffect(() => {
    if (isMailSent) {
      dispatch(resetMailState());
      if (needToRedirect) history.push("/ibuilder/invoices");
    }
  }, [isMailSent]);

  // ## submit form action
  const onSubmit = (data: MailFormInterface) => {
    const payload = {
      contentType: INVOICE_SEND_TYPE.EMAIL,
      invoiceId: invoiceId as number,
      from: data.from,
      to: castArray(data.to),
      subject: data.subject as string,
      body: data.body,
    };

    dispatch(sendMail(payload));
  };

  return (
    <>
      <SendInvoiceHeading heading='Send Via Email' icon={<ForwardToInboxOutlinedIcon />} />

      <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ p: 3, pt: 0 }}>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"from"}
              defaultValue={""}
              rules={mailPattern.from || null}
              render={({ field: { value }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <CoreInputLabel variant='standard'>From</CoreInputLabel>
                  <CoreTextField
                    size='small'
                    type='email'
                    color='secondary'
                    placeholder={"Sender email address"}
                    value={value}
                    disabled={true}
                    error={invalid}
                    helperText={invalid && error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name={"to"}
              rules={mailPattern.to || null}
              render={({ field: { value }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <CoreInputLabel variant='standard'>To</CoreInputLabel>
                  <CoreTextField
                    size='small'
                    type='email'
                    color='secondary'
                    disabled={true}
                    placeholder={"Receiver email address"}
                    value={value}
                    error={invalid}
                    helperText={invalid && error?.message}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={"subject"}
              rules={mailPattern.subject || null}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <CoreInputLabel variant='standard' sx={{ mb: 3 }}>
                    Subject
                  </CoreInputLabel>

                  <Stack direction='row' alignItems='center' spacing={2}>
                    <CoreTextField
                      fullWidth
                      size='small'
                      type='text'
                      color='secondary'
                      inputRef={subjectRef}
                      placeholder='Type email subject'
                      value={value}
                      onChange={onChange}
                    />
                    <PersonalizedTag
                      onClick={(personalizedTag) =>
                        insertPersonalizedTag(personalizedTag, subjectRef, (value) => setValue("subject", value))
                      }
                    />
                  </Stack>
                  <FormHelperText error={invalid}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={"body"}
              rules={mailPattern.body || null}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <FormControl fullWidth error={invalid} required>
                  <Stack direction='row' alignItems='center' justifyContent={"space-between"} mt={2} mb={1}>
                    <InputLabel variant='standard' shrink sx={{ fontSize: "18px", position: "relative", top: 16 }}>
                      Email Body
                    </InputLabel>
                    <PersonalizedTag
                      onClick={(personalizedTag) =>
                        insertPersonalizedTag(personalizedTag, bodyRef, (value) => setValue("body", value))
                      }
                      isSMS={false}
                    />
                  </Stack>
                  <BasicEditor ref={bodyRef} value={value} onChangeBody={onChange} />
                  <FormHelperText error={invalid}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{ mt: 3 }}>
              <LoadingButton
                size={"small"}
                type={"submit"}
                isLoading={needToRedirect && isLoading}
                variant='outlined'
                color='secondary'
                onClick={() => setNeedToRedirect(true)}
              >
                Send Email & Exit
              </LoadingButton>
              <CoreButton
                size={"small"}
                type={"submit"}
                variant='contained'
                color='secondary'
                onClick={() => setNeedToRedirect(false)}
              >
                Send Email
              </CoreButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <BasicModal open={isLoading} showCloseIcon={false} onClose={() => {}} disableOutSideClick={true}>
        <SendInvoiceLoadingContent isLoading={isLoading} />
      </BasicModal>
    </>
  );
};

export default SendWithMail;
