import React from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { RepeatOutlined } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InvoiceBody from "./InvoiceBody";
import CommonTitleBar from "../../common/CommonTitleBar";
import { BuilderBodyContent, BuilderBodyWrapper } from "./BuilderStyles";
import { selectInvoiceBuilderData } from "../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { INVOICE_TYPE } from "../../helpers/constant/invoiceConstant";

const BuilderBody: React.FC = () => {
  const { name, invoiceType } = useSelector(selectInvoiceBuilderData);
  return (
    <BuilderBodyWrapper className='ib_scroll_bar'>
      <BuilderBodyContent>
        <Stack spacing={2} sx={{ width: "100%", height: "max-content", pb: 3, px: 0 }}>
          <CommonTitleBar
            name={name}
            icon={
              invoiceType === INVOICE_TYPE.ONE_TIME ? (
                <DescriptionOutlinedIcon fontSize={"large"} sx={{ color: "common.white" }} />
              ) : (
                <RepeatOutlined fontSize={"large"} sx={{ color: "common.white" }} />
              )
            }
          />
          <InvoiceBody />
        </Stack>
      </BuilderBodyContent>
    </BuilderBodyWrapper>
  );
};

export default BuilderBody;
