import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChildInvoiceInterface,
  ChildInvoiceResponseInterface,
  IGetChildInvoicePayload,
} from "./childInvoice.interface";

const initialState = {
  data: [] as ChildInvoiceInterface[],
  totalUnpaidInvoice: 0,
  currentPage: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: "",
};

const childInvoiceSlice = createSlice({
  name: "childInvoice",
  initialState,
  reducers: {
    getChildInvoices: (state, _action: PayloadAction<IGetChildInvoicePayload>) => {
      state.isLoading = true;
    },
    getChildInvoicesSuccess: (state, action: PayloadAction<ChildInvoiceResponseInterface>) => {
      state.data = action.payload.listData;
      state.totalUnpaidInvoice = action.payload.totalUnpaidInvoice;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getChildInvoicesFailed: (state, action) => {
      state.data = [];
      state.totalUnpaidInvoice = 0;
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { getChildInvoices, getChildInvoicesFailed, getChildInvoicesSuccess } = childInvoiceSlice.actions;

export default childInvoiceSlice.reducer;
