import React from "react";
import { useSelector } from "react-redux";
import { Stack, styled, Typography, useTheme } from "@mui/material";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const StyledBox = styled(Stack)(({ theme }) => ({
  borderTop: `2px solid ${theme.palette.other.outlinedBorderDarker}`,
  marginTop: 16,
  padding: theme.spacing(2, 1),
  gap: 32,
}));

const InvoiceFooterStandard: React.FC = () => {
  const { data } = useSelector(selectInvoiceBuilderState);

  return (
    <>
      <StyledBox>
        {data.termsAndCondition && (
          <div>
            <Typography variant={"h6"} color={"text.primary"} mb={1}>
              TERMS & CONDITION
            </Typography>
            <div className="preview" dangerouslySetInnerHTML={{ __html: data.termsAndCondition }}></div>
          </div>
        )}

        {data.note && (
          <div>
            <Typography variant={"h6"} color={"text.primary"} mb={1}>
              NOTES
            </Typography>
            <div className="preview" dangerouslySetInnerHTML={{ __html: data.note }}></div>
          </div>
        )}
      </StyledBox>
    </>
  );
};

export default InvoiceFooterStandard;
