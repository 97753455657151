import { call } from "typed-redux-saga";
import { all, put, takeEvery } from "redux-saga/effects";
import InvoiceStateService from "../../../services/invoiceStateService";
import {
  getInvoiceStateDataError,
  getInvoiceStateDataSuccess,
  getInvoiceStateReportWithDateRangeError,
  getInvoiceStateReportWithDateRangeSuccess,
} from "./invoiceStateSlice";
import { showErrorToaster } from "../../../helpers/utils/toaster";

function* invoiceStateWatcher() {
  yield takeEvery("invoiceState/getInvoiceStateData", getInvoiceStateDataSaga);
  yield takeEvery("invoiceState/getInvoiceStateReportWithDateRange", getInvoiceStateReportWithDateRangeSaga);
}

function* getInvoiceStateDataSaga() {
  try {
    const response = yield* call(InvoiceStateService.getInvoiceStateData);

    if (response.success) {
      yield put(getInvoiceStateDataSuccess(response.data));
    } else {
      yield put(getInvoiceStateDataError(response.message as string));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getInvoiceStateDataError("Network Error!!"));
  }
}

function* getInvoiceStateReportWithDateRangeSaga() {
  try {
    const response = yield* call(InvoiceStateService.getInvoiceStateReportWithDateRange);

    if (response.success) {
      yield put(getInvoiceStateReportWithDateRangeSuccess(response.data));
    } else {
      yield put(getInvoiceStateReportWithDateRangeError(response.message as string));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getInvoiceStateReportWithDateRangeError("Network Error!!"));
  }
}

export default function* invoiceStateSaga() {
  yield all([invoiceStateWatcher()]);
}
