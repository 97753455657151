import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "./ItemsStyles";
import ItemsActionButtons from "./ItemsActionButtons";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import TableLoaderSkeleton from "../../common/LoadingView/TableLoaderSkeleton";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import { AppDispatch } from "../../state/store";
import compressString from "../../helpers/utils/compressString";
import { ITEM_TYPE_OPTIONS, ITEM_UNIT_OPTIONS } from "../../helpers/constant/itemConstant";
import { getItems, resetItemState } from "../../state/features/item/itemSlice";
import { selectItemState } from "../../state/features/item/itemSelector";

const ItemSingleList = styled(TableRow)(({ theme }) => {
  return {
    "&:hover": {
      backgroundColor: "#006df514",
      "& .ib-single-list-action-icons": {
        visibility: "visible",
        pointerEvents: "all",
      },
    },
  };
});

const ItemsTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    data: itemList,
    currentPage,
    limit,
    count,
    filter: { searchText },
  } = useSelector(selectItemState);

  useEffect(() => {
    dispatch(getItems({ searchText, currentPage: currentPage, limit: limit }));
  }, [searchText]);

  useEffect(() => {
    return () => {
      dispatch(resetItemState());
    };
  }, []);

  const onChangePage = (newPage: number) => {
    dispatch(getItems({ searchText, currentPage: newPage, limit: limit }));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    dispatch(getItems({ searchText, currentPage: newPage, limit: perPage }));
  };

  // decide what to render
  let content: null | JSX.Element = null;
  if (isLoading) {
    content = <TableLoaderSkeleton columns={6} numberOfRows={10} />;
  } else if (itemList.length === 0) {
    content = <GlobalEmptyPage title='Items not found' />;
  } else if (itemList.length > 0) {
    content = (
      <Stack justifyContent='space-between' sx={{ height: "calc(100% - 45px)" }}>
        <TableContainer sx={{ maxHeight: "calc(100% - 54px)" }} className='ib_scroll_bar'>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: 300 }}>Name</StyledTableCell>
                <StyledTableCell style={{ width: 400 }}>Description</StyledTableCell>
                <StyledTableCell>Item Type</StyledTableCell>
                <StyledTableCell>Quantity</StyledTableCell>
                <StyledTableCell>Rate</StyledTableCell>
                <StyledTableCell>Tax</StyledTableCell>
                <StyledTableCell align='right'>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemList.map((item, index) => (
                <ItemSingleList key={index}>
                  <StyledTableCell>
                    <div className={"multiline_truncate"} title={item.name}>
                      {compressString(item.name, 20)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className='multiline_truncate' title={item.description}>
                      {item.description}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>{ITEM_TYPE_OPTIONS[item.itemType]?.label}</StyledTableCell>
                  <StyledTableCell>
                    {item.quantity} {ITEM_UNIT_OPTIONS[item.unitOfItem]?.unit}
                  </StyledTableCell>
                  <StyledTableCell>${item.price || 0}</StyledTableCell>
                  <StyledTableCell>${item.tax}%</StyledTableCell>
                  <StyledTableCell align='right'>
                    <ItemsActionButtons item={item} />
                  </StyledTableCell>
                </ItemSingleList>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </Stack>
    );
  }

  return content;
};

export default ItemsTable;
