import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetInvoiceBuilderData, InvoiceInterface, IRequestUpdateClientCanSee } from "./invoiceBuilderInterface";
import { BUILDER_OPTIONS } from "../../../helpers/constant/invoiceConstant";
import { InvoiceTypeEnum } from "../invoice/invoice.interface";

export const PAYMENT_METHOD_CUSTOM = 0;

const initialState = {
  data: {} as InvoiceInterface,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,
  isFetchedSuccess: false,
  pdfGenerating: false,
  isUpdatingSettings: false,
  updatedSettingsSuccess: false,

  updateTime: "",
  currentBuilderTab: BUILDER_OPTIONS.BUILDER,
};

const invoiceBuilderSlice = createSlice({
  name: "invoiceBuilder",
  initialState,
  reducers: {
    getInvoiceBuilderData: (state, action: PayloadAction<IGetInvoiceBuilderData>) => {
      state.isLoading = true;
    },
    getInvoiceBuilderDataSuccess: (state, action: PayloadAction<InvoiceInterface>) => {
      const today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      state.data = {
        ...state.data,
        ...action.payload,
        invoiceDate: action.payload.invoiceDate || new Date(),
        dueDate: action.payload.dueDate || tomorrow,
        startDate: action.payload.startDate || new Date(),
        endDate: action.payload.endDate || new Date(),
        endDateStatus: action.payload.endDateStatus ?? "NEVER",
        paymentMethod: action.payload.paymentMethods
          ? action.payload.paymentMethods[0] || PAYMENT_METHOD_CUSTOM
          : PAYMENT_METHOD_CUSTOM,
        ...(action.payload.invoiceType === InvoiceTypeEnum.RECURRING && !action.payload.repeatSettings
          ? {
              repeatSettings: {
                automaticRecurring: true,
                recurringCycle: "MONTHLY",
                sendEvery: 10,
                dayOfSent: "SUN",
                dateOfSent: 1,
                monthOfSent: 1,
                sentAt: 0,
              },
            }
          : {}),
      };
      state.isLoading = false;
      state.isFetchedSuccess = true;
      state.isError = false;
      state.error = "";
    },
    getInvoiceBuilderDataFailed: (state, action: PayloadAction<string>) => {
      state.data = {} as InvoiceInterface;
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
      state.isFetchedSuccess = false;
    },

    updateInvoiceBuilderData: (state, action: PayloadAction<InvoiceInterface>) => {
      state.isUpdating = true;
      if (state.data.client) {
        state.data.client = {
          ...state.data.client,
          ...action.payload.client,
        };
      }
      state.error = "";
    },
    updateInvoiceBuilderDataSuccess: (state, action: PayloadAction<InvoiceInterface>) => {
      state.data = {
        ...state.data,
        ...action.payload,
        useSystemDefaultLogo: action.payload.useSystemDefaultLogo,
        logoOfCompany: action.payload.logoOfCompany,
        ...(state.data.client && {
          client: {
            ...state.data.client,
            contactId: action.payload.client ? action.payload.client.id : 0,
          },
        }),
      };
      state.isUpdating = false;
      state.currentBuilderTab = BUILDER_OPTIONS.PREVIEW_AND_SEND;
    },
    updateInvoiceBuilderDataFailed: (state, action: PayloadAction<string>) => {
      state.isUpdating = false;
      state.error = action.payload;
    },

    updateInvoiceClientCanSee: (state, _action: PayloadAction<IRequestUpdateClientCanSee>) => {
      state.isUpdatingSettings = true;
    },
    updateInvoiceClientCanSeeSuccess: (state, action: PayloadAction<IRequestUpdateClientCanSee>) => {
      state.isUpdatingSettings = false;
      state.updatedSettingsSuccess = true;
      state.data.settings = action.payload.settings;
    },
    resetUpdateInvoiceClientCanSee: (state) => {
      state.isUpdatingSettings = false;
      state.updatedSettingsSuccess = false;
    },

    generatePDF: (state, action: PayloadAction<string | number>) => {
      state.pdfGenerating = true;
    },
    generatePDFSuccess: (state, action: PayloadAction<string>) => {
      state.pdfGenerating = false;
    },
    generatePDFError: (state, action: PayloadAction<string>) => {
      state.pdfGenerating = false;
    },

    resetInvoiceBuilderState: (state) => {
      state.data = {} as InvoiceInterface;
      state.isLoading = false;
      state.isFetchedSuccess = false;
      state.isError = false;
      state.error = "";
      state.currentBuilderTab = BUILDER_OPTIONS.BUILDER;
    },
    changeCurrentBuilderTab: (state, action) => {
      state.currentBuilderTab = action.payload;
    },

    updateInvoiceBuilderUpdateTime: (state, action: PayloadAction<string>) => {
      state.updateTime = action.payload;
    },
    resetInvoiceBuilderUpdateTime: (state) => {
      state.updateTime = "";
    },
  },
});

export const {
  getInvoiceBuilderData,
  getInvoiceBuilderDataSuccess,
  getInvoiceBuilderDataFailed,
  resetInvoiceBuilderState,

  updateInvoiceBuilderData,
  updateInvoiceBuilderDataSuccess,
  updateInvoiceBuilderDataFailed,

  updateInvoiceClientCanSee,
  updateInvoiceClientCanSeeSuccess,
  resetUpdateInvoiceClientCanSee,

  generatePDF,
  generatePDFSuccess,
  generatePDFError,

  changeCurrentBuilderTab,
  updateInvoiceBuilderUpdateTime,
  resetInvoiceBuilderUpdateTime,
} = invoiceBuilderSlice.actions;

export default invoiceBuilderSlice.reducer;
