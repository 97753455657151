import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { invoiceStateInterface, invoiceStateReportInterface } from "../../../interface/invoiceState.interface";

const initialState = {
  data: {
    totalDueAmount: 0,
    totalDueInvoice: 0,
    totalPaidAmount: 0,
    totalPaidInvoice: 0,
    totalOutstandingAmount: 0,
    totalOutstandingInvoice: 0,
  } as invoiceStateInterface,
  isLoading: false,
  isError: false,
  error: "",

  report: {
    last30DaysPastDue: {
      totalDueAmount: 0,
      totalInvoiceDue: 0,
    },
    last30To60DaysPastDue: {
      totalDueAmount: 0,
      totalInvoiceDue: 0,
    },
    moreThan60DaysPastDue: {
      totalDueAmount: 0,
      totalInvoiceDue: 0,
    },
    notYetPastDue: {
      totalNotYetPastDueAmount: 0,
      totalNotYetPastDueInvoice: 0,
    },
    todayTotalOutstandingAmount: 0,
    currentDate: new Date(),
  } as invoiceStateReportInterface,

  isReportLoading: false,
};

const invoiceStateSlice = createSlice({
  name: "invoiceState",
  initialState,
  reducers: {
    getInvoiceStateData: (state) => {
      state.isLoading = true;
    },
    getInvoiceStateDataSuccess: (state, action: PayloadAction<invoiceStateInterface>) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    getInvoiceStateDataError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getInvoiceStateReportWithDateRange: (state) => {
      state.isReportLoading = true;
    },
    getInvoiceStateReportWithDateRangeSuccess: (state, action: PayloadAction<invoiceStateReportInterface>) => {
      state.report.last30DaysPastDue = action.payload.last30DaysPastDue;
      state.report.last30To60DaysPastDue = action.payload.last30To60DaysPastDue;
      state.report.moreThan60DaysPastDue = action.payload.moreThan60DaysPastDue;
      state.report.notYetPastDue = action.payload.notYetPastDue;
      state.report.todayTotalOutstandingAmount = action.payload.todayTotalOutstandingAmount;
      state.report.currentDate = action.payload.currentDate;
      state.isReportLoading = false;
      state.isError = false;
    },
    getInvoiceStateReportWithDateRangeError: (state, action: PayloadAction<string>) => {
      state.isReportLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const {
  getInvoiceStateData,
  getInvoiceStateDataSuccess,
  getInvoiceStateDataError,

  getInvoiceStateReportWithDateRange,
  getInvoiceStateReportWithDateRangeSuccess,
  getInvoiceStateReportWithDateRangeError,
} = invoiceStateSlice.actions;
export default invoiceStateSlice.reducer;
