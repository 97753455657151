import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import EmailText from "../../../../common/FormatText/EmailText";
import TelephoneText from "../../../../common/FormatText/TelephoneText";
import getFullName from "../../../../helpers/utils/getFullName";
import { LogoBox, PreviewHeaderWrapper } from "../DefaultTemplateStyles";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceHeaderPreview: React.FC = () => {
  const { data } = useSelector(selectInvoiceBuilderState);
  const { logoOfCompany, client } = data || {};

  return (
    <PreviewHeaderWrapper>
      <LogoBox>{logoOfCompany && <img src={logoOfCompany} alt={"branding logo"} />}</LogoBox>
      <Stack sx={{ textAlign: "right" }}>
        <Typography variant={"h5"} color={"text.primary"}>
          {client && getFullName(client.firstName, client.lastName)}
        </Typography>
        <Typography variant={"body1"} color={"text.primary"}>
          {client && <EmailText email={client.email} />}
        </Typography>
        <Typography variant={"body1"} color={"text.primary"}>
          {client && <TelephoneText phoneNumber={client.number} />}
        </Typography>
      </Stack>
    </PreviewHeaderWrapper>
  );
};

export default InvoiceHeaderPreview;
