import React from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonForIcon from "../../common/Button/ButtonForIcon";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import RouteLink from "../../common/Link/RouteLink";
import CoreButton from "../../common/Button/CoreButton";

const ExportFileHeader: React.FC = () => {
  const width = window?.innerWidth;

  return (
    <Paper elevation={0} sx={{ width: "100%" }}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={2} p={2}>
        <div>
          <Typography variant={"h5"} color={"text.primary"} sx={{ width: "max-content" }}>
            Export Files
          </Typography>
          <Typography variant={"body2"} color={"text.secondary"}>
            All of your exported invoice will be shown here
          </Typography>
        </div>
        <RouteLink to={`/ibuilder/invoices`}>
          {width < 600 ? (
            <CustomTooltip title='Back to Home' arrow placement='top'>
              <ButtonForIcon startIcon={<ArrowBackIcon />}></ButtonForIcon>
            </CustomTooltip>
          ) : (
            <CoreButton variant={"outlined"} color={"secondary"} startIcon={<ArrowBackIcon />}>
              Back to Homepage
            </CoreButton>
          )}
        </RouteLink>
      </Stack>
      <Divider light />
      {/*<Stack direction={'row'} alignItems={'center'} spacing={2} p={2} pt={1}>*/}
      {/*  <ReportTimeTabs />*/}
      {/*</Stack>*/}
    </Paper>
  );
};

export default ExportFileHeader;
