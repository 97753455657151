import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import RecurringSettingInterface, { IUpdateRecurringSetting } from "../../../interface/recurringSettings.interface";

const initialState = {
  data: {
    automaticRecurring: false,
  } as RecurringSettingInterface,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,
  isUpdateSuccess: true,
};

const invoiceSettingSlice = createSlice({
  name: "recurringSetting",
  initialState,
  reducers: {
    getRecurringSetting: (state, _action: PayloadAction<number>) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    },
    getRecurringSettingSuccess: (state, action: PayloadAction<RecurringSettingInterface>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getRecurringSettingFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateRecurringSetting: (state, action: PayloadAction<IUpdateRecurringSetting>) => {
      state.isUpdating = true;
      state.error = "";
    },
    updateRecurringSettingSuccess: (state, action: PayloadAction<RecurringSettingInterface>) => {
      state.isUpdating = false;
      state.data = {
        ...state.data,
        ...action.payload,
      };
      state.isUpdateSuccess = true;
    },
    updateRecurringSettingFailed: (state, action: PayloadAction<string>) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
      state.error = action.payload;
    },
  },
});

export const {
  getRecurringSetting,
  getRecurringSettingFailed,
  getRecurringSettingSuccess,

  updateRecurringSetting,
  updateRecurringSettingFailed,
  updateRecurringSettingSuccess,
} = invoiceSettingSlice.actions;
export default invoiceSettingSlice.reducer;
