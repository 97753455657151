import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import BasicEditor from "../../../common/Editor/BasicEditor";
import { InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";
import ChooseTemplate from "../../Settings/InvoiceSettings/ChooseTemplate";
import { InvoiceContainer } from "../BuilderStyles";

const InvoiceFooterContent: React.FC = () => {
  const { control, getValues, register } = useFormContext<InvoiceInterface>();

  return (
    <InvoiceContainer>
      <Stack direction={"row"} spacing={3} py={3}>
        <Box sx={{ width: "50%" }}>
          <Typography variant='subtitle1' color='text.primary' sx={{ mb: 1 }}>
            Customer Notes{" "}
            <Typography component={"span"} variant='body2' color='text.secondary'>
              (optional)
            </Typography>
          </Typography>
          <Controller
            control={control}
            name={"note"}
            render={({ field: { value, onChange } }) => (
              <BasicEditor value={value} onChangeBody={onChange} height={240} />
            )}
          />
        </Box>

        <Box sx={{ width: "50%" }}>
          <Typography variant='subtitle1' color='text.primary' sx={{ mb: 1 }}>
            Terms & Conditions{" "}
            <Typography component={"span"} variant='body2' color='text.secondary'>
              (optional)
            </Typography>
          </Typography>
          <Controller
            control={control}
            name={"termsAndCondition"}
            render={({ field: { value, onChange } }) => (
              <BasicEditor value={value} onChangeBody={onChange} height={240} />
            )}
          />
        </Box>
      </Stack>

      <Box sx={{ mt: 3 }}>
        <Typography variant='subtitle1' color='text.primary' sx={{ mb: 1 }}>
          Choose Template
        </Typography>
        <ChooseTemplate {...register("settings.template")} selectedTemplate={getValues("settings.template")} />
      </Box>
    </InvoiceContainer>
  );
};

export default InvoiceFooterContent;
