import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  ArchiveStatusPayload,
  FavoriteStatusPayload,
  GetInvoicesPayload,
  IAddInvoicesToFoldersPayload,
  ICloneInvoicePayload,
  IDeleteForeverPayload,
  InvoiceRemovePayload,
  IRemoveFolderFromInvoicePayload,
  IRenamedInvoicePayload,
} from "../state/features/invoice/invoice.interface";
import { InvoiceInterface } from "../state/features/invoiceBuilder/invoiceBuilderInterface";
import {
  ChildInvoiceResponseInterface,
  IGetChildInvoicePayload,
} from "../state/features/childInvoice/childInvoice.interface";
import { ManuallyRecordPaymentInterface } from "../interface/manuallyRecordPayment.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class InvoiceService {
  getInvoices(body: GetInvoicesPayload) {
    return httpRequest.get<WithPagination<InvoiceInterface>>(invoiceAPI + "invoice-builder/invoice/list", body);
  }

  addInvoice(body: InvoiceInterface) {
    return httpRequest.post<InvoiceInterface>(invoiceAPI + "invoice-builder/invoice/create", body);
  }

  renamedInvoice(body: IRenamedInvoicePayload) {
    return httpRequest.put<InvoiceInterface>(invoiceAPI + "invoice-builder/invoice/rename", body);
  }

  deleteInvoices(params: InvoiceRemovePayload) {
    return httpRequest.put<number>(invoiceAPI + "invoice-builder/invoice/trash", params);
  }

  deleteInvoicesForever(params: IDeleteForeverPayload) {
    return httpRequest.delete<number>(invoiceAPI + "invoice-builder/invoice/delete", {}, params);
  }

  updateInvoiceFavoriteStatus(payload: FavoriteStatusPayload) {
    return httpRequest.put<FavoriteStatusPayload>(invoiceAPI + "invoice-builder/invoice/favorite", payload);
  }

  updateArchiveStatus(payload: ArchiveStatusPayload) {
    return httpRequest.put<ArchiveStatusPayload>(invoiceAPI + "invoice-builder/invoice/archive", payload);
  }

  cloneInvoice(payload: ICloneInvoicePayload) {
    return httpRequest.post<ArchiveStatusPayload>(invoiceAPI + "invoice-builder/invoice/clone", payload);
  }

  addInvoicesToFolders(payload: IAddInvoicesToFoldersPayload) {
    return httpRequest.post<IAddInvoicesToFoldersPayload>(invoiceAPI + "invoice-builder/invoice/folder/add", payload);
  }

  removeFolderFromInvoice(payload: IRemoveFolderFromInvoicePayload) {
    return httpRequest.delete(invoiceAPI + "invoice-builder/invoice/folder/remove", payload);
  }

  getChildInvoices(params: IGetChildInvoicePayload) {
    return httpRequest.get<ChildInvoiceResponseInterface>(
      invoiceAPI + "invoice-builder/invoice/recurring/payment/list",
      params
    );
  }

  manuallyRecordPayment(body: ManuallyRecordPaymentInterface) {
    return httpRequest.put<any>(invoiceAPI + "invoice-builder/invoice/manually/record/payment", body);
  }
}

export default new InvoiceService();
