import httpRequest from "./api/httpRequest";
import { IGetTaxesPayload, TaxInterface } from "../state/features/tax/tax.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class TaxService {
  getTaxes(params: IGetTaxesPayload) {
    return httpRequest.get<TaxInterface[]>(invoiceAPI + "invoice-builder/tax/list", params);
  }
}

export default new TaxService();
