import React from "react";
import { useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TotalAmountTable } from "../DefaultTemplateStyles";
import { DataCellTd } from "../../../Builder/InvoiceItems/ItemsStyes";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { CLIENT_CAN_SEE_KEY, IF_CLIENT_CAN_SEE_FALSE } from "../../../../helpers/constant/invoiceSettingConstant";
import numberToDollar from "../../../../helpers/utils/numberToDollar";

const TotalItemsTable: React.FC = () => {
  const {
    data: {
      items = [],
      shippingCharge = 0,
      discount = 0,
      taxes = 0,
      taxName,
      taxPercentage,
      totalAmountOfInvoice,
      settings,
    },
  } = useSelector(selectInvoiceBuilderState);
  const { clientCanSee = [] } = settings || {};

  const subTotal = items?.reduce((acc, item) => {
    const { quantity, price } = item;
    const amount = price * quantity;
    return acc + amount;
  }, 0);

  return (
    <TotalAmountTable>
      <TableBody>
        <TableRow>
          <DataCellTd>Sub Total</DataCellTd>
          <TableCell variant={"head"} align='right'>
            {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SUB_TOTAL) ? numberToDollar(subTotal) : IF_CLIENT_CAN_SEE_FALSE}
          </TableCell>
        </TableRow>

        <TableRow>
          <DataCellTd>After Discount</DataCellTd>
          <TableCell align='right' sx={{ color: "success.main" }}>
            {clientCanSee.includes(CLIENT_CAN_SEE_KEY.DISCOUNT)
              ? numberToDollar(subTotal - discount)
              : IF_CLIENT_CAN_SEE_FALSE}
          </TableCell>
        </TableRow>

        <TableRow>
          <DataCellTd>Shipping Charges</DataCellTd>
          <TableCell align='right'>
            {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SHIPPING_CHARGE)
              ? numberToDollar(shippingCharge)
              : IF_CLIENT_CAN_SEE_FALSE}
          </TableCell>
        </TableRow>

        <TableRow>
          <DataCellTd>
            Taxes{" "}
            {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) &&
              (taxName ? `(${taxName} | ${taxPercentage}%)` : `(${taxPercentage}%)`)}
          </DataCellTd>
          <TableCell align='right'>
            {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) ? numberToDollar(taxes) : IF_CLIENT_CAN_SEE_FALSE}
          </TableCell>
        </TableRow>

        <TableRow>
          <DataCellTd component={"th"} sx={{ color: "secondary.main" }}>
            Total
          </DataCellTd>
          <TableCell align='right' className={"ib-topBottomBordered"}>
            {numberToDollar(totalAmountOfInvoice)}
          </TableCell>
        </TableRow>
      </TableBody>
    </TotalAmountTable>
  );
};

export default TotalItemsTable;
