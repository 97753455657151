import React from "react";
import { useSelector } from "react-redux";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import { Stack, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { StyledTableCell, StyledTableRow, SummeryTable, TotalTableRow } from "../StandardInvoiceStyles";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { CLIENT_CAN_SEE_KEY, IF_CLIENT_CAN_SEE_FALSE } from "../../../../helpers/constant/invoiceSettingConstant";
import getInvoiceDateAndDueDate from "../../../../helpers/utils/getInvoiceDateAndDueDate";
import previewDateFormat from "../../../../helpers/utils/previewDateFormat";
import numberToDollar from "../../../../helpers/utils/numberToDollar";

const InvoiceTableStandard: React.FC = () => {
  const {
    data: {
      items,
      orderId,
      invoiceNumber,
      invoiceDate,
      dueDate,
      startDate,
      discount = 0,
      shippingCharge = 0,
      taxes = 0,
      taxName,
      taxPercentage,
      totalAmountOfInvoice = 0,
      settings,
      invoiceType,
      paymentTerms,
    },
  } = useSelector(selectInvoiceBuilderState);

  const { clientCanSee } = settings || {};

  const { formattedInvoiceDate, formattedDueDate } = getInvoiceDateAndDueDate(
    invoiceType,
    invoiceDate,
    dueDate,
    startDate,
    paymentTerms
  );

  let subTotal = 0;
  if (Array.isArray(items)) {
    subTotal = items.reduce((acc, item) => {
      const { quantity, price } = item;
      const amount = price * quantity;
      return acc + amount;
    }, 0);
  }

  let content = <></>;

  content = (
    <TableBody>
      {items.map((item, index) => {
        const { tax, quantity, price, name, description } = item;
        const amount = price * quantity;
        const taxAmount = amount * (tax ? tax / 100 : 0);
        const lineTotal = taxAmount + amount;
        return (
          <StyledTableRow key={index}>
            <TableCell align='left'>
              <Stack>
                <Typography variant={"body2Semibold"}>{name ? name : "-"}</Typography>
                <Typography variant={"caption"} className={"multiline_truncate"}>
                  {description ? description : "-"}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align='center'>
              {clientCanSee.includes(CLIENT_CAN_SEE_KEY.QUANTITY) ? quantity : IF_CLIENT_CAN_SEE_FALSE}
            </TableCell>
            <TableCell align='right'>
              {clientCanSee.includes(CLIENT_CAN_SEE_KEY.PRICE) ? numberToDollar(price) : IF_CLIENT_CAN_SEE_FALSE}
            </TableCell>

            <TableCell align='right'>
              {clientCanSee.includes(CLIENT_CAN_SEE_KEY.LINE_TOTAL) ? numberToDollar(amount) : IF_CLIENT_CAN_SEE_FALSE}
            </TableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left'>Items & Description</StyledTableCell>
            <StyledTableCell align='center'>Quantity</StyledTableCell>
            <StyledTableCell align='right'>Rate</StyledTableCell>
            <StyledTableCell align='right'>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        {content}
      </Table>

      <Stack direction={"row"} justifyContent={"space-between"}>
        <SummeryTable>
          <TableBody>
            <TableRow>
              <TableCell component={"th"} align={"left"}>
                Invoice #
              </TableCell>
              <TableCell align={"right"}>{invoiceNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component={"th"} align={"left"}>
                Order No.
              </TableCell>
              <TableCell align={"right"}>{orderId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component={"th"} align={"left"}>
                Date
              </TableCell>
              <TableCell align={"right"}>{formattedInvoiceDate && previewDateFormat(formattedInvoiceDate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component={"th"} align={"left"}>
                Due Date
              </TableCell>
              <TableCell align={"right"}>{formattedDueDate && previewDateFormat(formattedDueDate)}</TableCell>
            </TableRow>
          </TableBody>
        </SummeryTable>
        <Table sx={{ width: "50%" }}>
          <TableBody>
            <TotalTableRow>
              <TableCell colSpan={5} align='right'></TableCell>
              <TableCell align='left'>Sub Total</TableCell>
              <TableCell align='right'>
                {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SUB_TOTAL)
                  ? numberToDollar(subTotal)
                  : IF_CLIENT_CAN_SEE_FALSE}
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell colSpan={5} align='right'></TableCell>
              <TableCell align='left'>After Discount</TableCell>
              <TableCell align='right'>
                {clientCanSee.includes(CLIENT_CAN_SEE_KEY.DISCOUNT) ? numberToDollar(subTotal - discount) : "-"}
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell colSpan={5} align='right'></TableCell>
              <TableCell align='left'>Shipping Charges</TableCell>
              <TableCell align='right'>
                {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SHIPPING_CHARGE)
                  ? numberToDollar(shippingCharge)
                  : IF_CLIENT_CAN_SEE_FALSE}
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell colSpan={5} align='right'></TableCell>
              <TableCell align='left'>
                Taxes{" "}
                {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) &&
                  (taxName ? `(${taxName} | ${taxPercentage}%)` : `(${taxPercentage}%)`)}
              </TableCell>
              <TableCell align='right'>
                {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) ? numberToDollar(taxes) : IF_CLIENT_CAN_SEE_FALSE}
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell colSpan={2} sx={{ position: "relative" }}></TableCell>
              <TableCell colSpan={3} align='right'></TableCell>
              <TableCell align='left' className={"iv_tableCell_color"}>
                <Typography variant={"body1SemiBold"}>TOTAL</Typography>
              </TableCell>
              <TableCell align='right' className={"iv_tableCell_color"}>
                <Typography variant={"body1SemiBold"}>{numberToDollar(totalAmountOfInvoice)}</Typography>
              </TableCell>
            </TotalTableRow>
          </TableBody>
        </Table>
      </Stack>
    </TableContainer>
  );
};

export default InvoiceTableStandard;
