import * as React from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { IconButton, Typography, useTheme } from "@mui/material";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { ExportFileBody } from "./ExportFileStyles";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";

interface Column {
  id: "name" | "time" | "status" | "action";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "File Name", minWidth: 170 },
  { id: "time", label: "Requested Time", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
  },
];

const ExportFilesTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const processed = true;

  return (
    <ExportFileBody elevation={0}>
      <TableContainer className='ib_scroll_bar' sx={{ height: "calc(100% - 52px)" }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: theme.palette.other.outlinedBorderDarker }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role='checkbox' tabIndex={-1}>
              <TableCell>new invoice</TableCell>
              <TableCell>13 March 2023</TableCell>
              <TableCell align='center'>
                {processed ? (
                  <Typography sx={{ display: "inline-flex", alignItems: "center", gap: "4px" }}>
                    <SentimentSatisfiedAltIcon color='success' /> Processed
                  </Typography>
                ) : (
                  <Typography sx={{ display: "inline-flex", alignItems: "center", gap: "4px" }}>
                    <SentimentVeryDissatisfiedIcon color='error' /> Failed
                  </Typography>
                )}
              </TableCell>
              <TableCell align='right'>
                <CustomTooltip title='Download' arrow placement='top'>
                  <IconButton size='small'>
                    <DownloadIcon />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title='Delete' arrow placement='top'>
                  <IconButton size='small' sx={{ ml: 2 }} onClick={() => console.log("clicked on the delete icon")}>
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={10}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiTablePagination-toolbar": { minHeight: "40px", "& .MuiTablePagination-spacer": { flex: "0.5 0" } },
        }}
      />
    </ExportFileBody>
  );
};
export default ExportFilesTable;
