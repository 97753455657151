import React from "react";
import { Stack } from "@mui/material";
import InvoiceListFilterHeader from "./InvoiceListFilterHeader";
import InvoiceListTable from "./InvoiceListTable";
import InvoiceOverviewReport from "./InvoiceOverviewReport";
import InvoiceCollections from "./InvoiceCollections";
import { InvoiceBodyWrapper } from "./InvoiceHomePageStyles";
import InvoiceReportWarning from "./InvoiceReportWarning";

const InvoiceListBody: React.FC = () => {
  return (
    <Stack spacing={2} sx={{ width: "100%", overflow: "hidden" }}>
      <InvoiceReportWarning />
      <InvoiceOverviewReport />

      <InvoiceBodyWrapper>
        <InvoiceListFilterHeader />
        <InvoiceListTable />
      </InvoiceBodyWrapper>

      <InvoiceBodyWrapper>
        <InvoiceCollections />
      </InvoiceBodyWrapper>
    </Stack>
  );
};

export default InvoiceListBody;
