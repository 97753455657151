import httpRequest from "./api/httpRequest";
import {
  IGetInvoiceBuilderData,
  InvoiceInterface,
  IRequestUpdateClientCanSee,
} from "../state/features/invoiceBuilder/invoiceBuilderInterface";
import ResponseInterface from "../interface/response.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class InvoiceService {
  getInvoiceBuilderData(params: IGetInvoiceBuilderData) {
    return httpRequest.get<InvoiceInterface>(invoiceAPI + "invoice-builder/invoice/details", params);
  }

  updateInvoiceBuilderData(body: InvoiceInterface) {
    return httpRequest.put<InvoiceInterface>(invoiceAPI + "invoice-builder/invoice/update", body);
  }

  updateInvoiceClientCanSee(body: IRequestUpdateClientCanSee) {
    return httpRequest.put<IRequestUpdateClientCanSee>(invoiceAPI + "invoice-builder/invoice/settings/update", body);
  }

  generatePDF(id: string | number) {
    return httpRequest.get<ResponseInterface<{ url: string }>>(
      invoiceAPI + `invoice-builder/invoice/pdf/generate?invoiceId=${id}`
    );
  }
}

export default new InvoiceService();
