import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import AddItemDrawerContent from "./AddItemDrawerContent";

import CoreButton from "../../common/Button/CoreButton";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import CustomBreadcrumb from "../../common/Breadcrumb/CustomBreadcrumb";

import useBoolean from "../../hooks/useBoolean";
import { HeaderWrapper } from "./ItemsStyles";

const ItemsHeader: React.FC = () => {
  const { value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer } = useBoolean(false);

  return (
    <>
      <HeaderWrapper>
        <Box>
          <Typography variant='h5' color='text.primary'>
            Items
          </Typography>

          <CustomBreadcrumb
            parentLocation='All Invoices'
            icon={<FolderIcon fontSize='small' />}
            currentLocation='items'
            parentUri='/ibuilder/invoices'
            color='text.secondary'
          />
        </Box>

        <CoreButton color='secondary' variant='contained' size='large' startIcon={<AddIcon />} onClick={onOpenDrawer}>
          Add New Items
        </CoreButton>
      </HeaderWrapper>

      <BasicDrawer open={openDrawer} title='Add New Item' toggleDrawer={onCloseDrawer}>
        <AddItemDrawerContent onClose={onCloseDrawer} />
      </BasicDrawer>
    </>
  );
};

export default ItemsHeader;
