export interface InvoiceListItemInterface {
  id?: number;
  name: string;
  invoiceNumber: string;
  invoiceType: InvoiceTypeEnum;
  dueDate: string | Date;
  invoiceDate: string | Date;

  orderId: number;
  totalAmount: number;
  amount: number;
  pdfFileUrl: string;
  folder: { name: string; folderId: number; color: string }[] | null;
  client: { id: number; firstName: string; lastName: string; email?: string; number?: string } | null;

  totalRecurringInvoice: number;
  startDate?: string | Date;
  endDateStatus?: string; // "NEVER", "BY", "AFTER"
  endDate?: string | Date;

  status: "INACTIVE" | "ACTIVE" | "RECURRING" | "COMPLETED";
  isFavorite: boolean;
  isArchived: boolean;
  inTrash: boolean;
  createdAt: string | Date;
  updatedAt: string | Date;
  formattedCreatedAt: string;
}

export enum InvoiceTypeEnum {
  "ONE_TIME" = "ONE_TIME",
  "RECURRING" = "RECURRING",
}

export interface IAddInvoicePayload {
  name: string;
  folderId?: number;
  invoiceType: InvoiceTypeEnum;
}

export interface GetInvoicesPayload {
  folderId?: number;
  currentPage?: number;
  limit?: number;
  isFavorite?: boolean;
  isArchived?: boolean;
  inTrash?: boolean;
  searchText?: string;
  orderBy?: string;
  paymentStatus?: string;
}

export interface InvoiceRemovePayload {
  ids: number[];
  isTrashed: boolean;
}

export interface IDeleteForeverPayload {
  ids: number[];
}

export interface FavoriteStatusPayload {
  id: number;
  isFavorite: boolean;
}

export interface ArchiveStatusPayload {
  ids: number[];
  isArchived: boolean;
}

export interface ICloneInvoicePayload {
  invoiceId: number;
}

export interface IRenamedInvoicePayload {
  invoiceId: number;
  name: string;
}

export interface IAddInvoicesToFoldersPayload {
  invoiceIds: number[];
  folderIds: number[];
}

export interface IRemoveFolderFromInvoicePayload {
  folderId: number;
  invoiceId: number;
}
