import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import FollowupSettingInterface, { IUpdateFollowupSetting } from "../../../interface/followupSetting.interface";

const initialState = {
  data: {
    automaticRecurring: false,
  } as FollowupSettingInterface,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,
  isUpdateSuccess: true,
};

const followupSettingSlice = createSlice({
  name: "followupSetting",
  initialState,
  reducers: {
    getFollowupSetting: (state, action: PayloadAction<number>) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    },
    getFollowupSettingSuccess: (state, action: PayloadAction<FollowupSettingInterface>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getFollowupSettingFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFollowupSetting: (state, _action: PayloadAction<IUpdateFollowupSetting>) => {
      state.isUpdating = true;
      state.error = "";
    },
    updateFollowupSettingSuccess: (state, action: PayloadAction<FollowupSettingInterface>) => {
      state.isUpdating = false;
      state.data = {
        ...state.data,
        ...action.payload,
      };
      state.isUpdateSuccess = true;
    },
    updateFollowupSettingFailed: (state, action: PayloadAction<string>) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
      state.error = action.payload;
    },
  },
});

export const {
  getFollowupSetting,
  getFollowupSettingFailed,
  getFollowupSettingSuccess,

  updateFollowupSetting,
  updateFollowupSettingFailed,
  updateFollowupSettingSuccess,
} = followupSettingSlice.actions;

export default followupSettingSlice.reducer;
