import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { IconButton, Popover, Stack } from "@mui/material";
import { CreateNewFolder, Delete, DeleteForever, RestoreFromTrash } from "@mui/icons-material";
import AddToFolderInvoice from "../AddToFolderInvoice";
import CoreButton from "../../../common/Button/CoreButton";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import { AppDispatch } from "../../../state/store";
import { selectedInvoicesFolderIds, selectInvoiceState } from "../../../state/features/invoice/invoiceSelector";
import {
  deleteInvoices,
  deleteInvoicesForever,
  resetDeleteInvoiceForeverSuccess,
  toggleArchiveStatus,
} from "../../../state/features/invoice/invoiceSlice";
import useBoolean from "../../../hooks/useBoolean";

const InvoiceListBulkActions = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { selectedInvoiceIds, isDeleting, isDeleteSuccess, filters } = useSelector(selectInvoiceState);
  const { inTrash, isArchived } = filters;
  const selectedInvoiceFolderIds = useSelector(selectedInvoicesFolderIds);
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseModal();
      dispatch(resetDeleteInvoiceForeverSuccess());
    }
  }, []);

  // handle delete move in trash
  const deleteInvoiceHandler = () => {
    dispatch(deleteInvoices({ ids: selectedInvoiceIds, isTrashed: true }));
  };

  // handle restore from in trash
  const handleRestoreInvoice = () => {
    dispatch(deleteInvoices({ ids: selectedInvoiceIds, isTrashed: false }));
  };

  const onConfirmDeleteForever = () => {
    dispatch(deleteInvoicesForever({ ids: selectedInvoiceIds }));
  };

  // handle restore from archived
  const toggleArchiveInvoice = () => {
    dispatch(
      toggleArchiveStatus({
        ids: selectedInvoiceIds,
        isArchived: !isArchived,
      })
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // decide what to render
  let element: JSX.Element | null = null;
  if (selectedInvoiceIds.length > 0 && inTrash) {
    element = (
      <>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <CoreButton
            variant={"contained"}
            color={"inherit"}
            startIcon={<RestoreFromTrash />}
            onClick={handleRestoreInvoice}
            sx={{ maxWidth: "100%" }}
          >
            Restore
          </CoreButton>
          <CoreButton
            variant={"contained"}
            color={"error"}
            startIcon={<DeleteForever />}
            onClick={onOpenModal}
            sx={{ minWidth: "120px", maxWidth: "100%" }}
          >
            Delete Forever
          </CoreButton>
        </Stack>

        <DeleteWarningModal
          title={"Delete Forever!!"}
          warningSubtitle={"Are you sure, you want to delete this invoices?"}
          warningContent={"After delete, this operation cannot be undone."}
          buttonText={"Yes, Delete Forever"}
          cancelButtonText={"Cancel"}
          open={openModal}
          onClose={onCloseModal}
          onConfirm={onConfirmDeleteForever}
          isLoading={isDeleting}
        />
      </>
    );
  } else if (selectedInvoiceIds.length > 0) {
    element = (
      <>
        <Stack direction='row' spacing={1} alignItems='center'>
          <CoreButton variant='outlined' color='secondary' startIcon={<CreateNewFolder />} onClick={handleClick}>
            Add to folder
          </CoreButton>

          <Stack direction='row' spacing={0.25} alignItems='center'>
            <CustomTooltip title={!isArchived ? "Archive" : "Unarchive"} arrow placement='top'>
              <IconButton onClick={toggleArchiveInvoice}>
                {!isArchived ? <ArchiveIcon color='action' /> : <UnarchiveIcon color='action' />}
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title='Delete' arrow placement='top'>
              <IconButton onClick={deleteInvoiceHandler}>
                <Delete color='action' />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <AddToFolderInvoice
            invoiceIds={selectedInvoiceIds}
            folderIds={selectedInvoiceFolderIds}
            onClose={handleClose}
          />
        </Popover>
      </>
    );
  }

  return element;
};

export default InvoiceListBulkActions;
