import React from "react";
import Table from "@mui/material/Table";
import { useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Box, Divider, Stack, Typography } from "@mui/material";
import TotalItemsTable from "./TotalItemsTable";
import numberToDollar from "../../../../helpers/utils/numberToDollar";
import { CLIENT_CAN_SEE_KEY, IF_CLIENT_CAN_SEE_FALSE } from "../../../../helpers/constant/invoiceSettingConstant";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceItemsList: React.FC = () => {
  const {
    data: { items = [], settings },
  } = useSelector(selectInvoiceBuilderState);
  const { clientCanSee = [] } = settings || {};

  return (
    <Box px={4}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Items & Description</TableCell>
            <TableCell align='right'>Quantity</TableCell>
            <TableCell align='right'>Rate</TableCell>
            <TableCell align='right'>Amount</TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ "& tr": { border: "none" } }}>
          {Array.isArray(items) &&
            items.map((item, index) => {
              const { quantity, price, name, description } = item;
              const amount = price * quantity;

              return (
                <TableRow key={index}>
                  <TableCell align='left'>
                    <Stack direction={"column"}>
                      <Typography variant={"body2"} color={"text.primary"}>
                        {name ? name : "-"}
                      </Typography>
                      <Typography variant={"body2"} color={"text.secondary"}>
                        {description ? description : "-"}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='right'>
                    {clientCanSee.includes(CLIENT_CAN_SEE_KEY.QUANTITY) ? quantity : IF_CLIENT_CAN_SEE_FALSE}
                  </TableCell>
                  <TableCell align='right'>
                    {clientCanSee.includes(CLIENT_CAN_SEE_KEY.PRICE) ? numberToDollar(price) : IF_CLIENT_CAN_SEE_FALSE}
                  </TableCell>
                  <TableCell align='right'>
                    {clientCanSee.includes(CLIENT_CAN_SEE_KEY.LINE_TOTAL)
                      ? numberToDollar(amount)
                      : IF_CLIENT_CAN_SEE_FALSE}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <Divider light />

      <TotalItemsTable />
    </Box>
  );
};

export default InvoiceItemsList;
