import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, FormHelperText, MenuItem, Stack } from "@mui/material";
import SelectPaymentTerms from "../InvoicePaymentTerm/SelectPaymentTerms";
import CoreTextField from "../../../common/TextField/CoreTextField";
import {
  DAILY_SEND_EVERY,
  MONTHLY_SEND_EVERY,
  RECURRING_CYCLE,
  RECURRING_CYCLE_LIST,
  SEND_AT,
  WEEKLY_SEND_EVERY_LIST,
  YEARLY_SEND_EVERY_MONTH,
} from "../../../helpers/constant/recurringSettingConstant";
import { registerInvoiceBuilder } from "../../../helpers/constant/registerPattern";
import { InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";
import { InvoiceEndDateStatus, InvoiceEndDateStatusList } from "../../../helpers/constant/invoiceConstant";
import { PaymentTermInterface } from "../../../state/features/paymentTerm/paymentTerm.interface";
import { StyledInputLabel, StyledSelect } from "./HeaderStyles";

const InvoiceRecurringSettings = () => {
  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useFormContext<InvoiceInterface>();

  const handleSelectPaymentTerm = (paymentTerm: PaymentTermInterface) => {
    setValue("paymentTerms", { ...paymentTerm });
    setError("paymentTerms", {});
  };

  const endDateStatusOptions = Object.values(InvoiceEndDateStatusList);
  return (
    <>
      <Stack spacing={2}>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            minWidth={{ xs: 100, lg: 120 }}
          >
            <StyledInputLabel
              variant='standard'
              required
              error={!!(errors?.repeatSettings?.recurringCycle && errors.repeatSettings.recurringCycle.message)}
              sx={{ width: 120 }}
            >
              Repeat
            </StyledInputLabel>
            <StyledInputLabel variant='standard'>:</StyledInputLabel>
          </Stack>

          <Box width={{ md: 120, lg: 200 }}>
            <Controller
              name='repeatSettings.recurringCycle'
              control={control}
              rules={registerInvoiceBuilder.recurringCycle}
              render={({ field: { value, onChange } }) => {
                return (
                  <StyledSelect
                    fullWidth
                    size='small'
                    color='secondary'
                    value={value ? value : RECURRING_CYCLE.DAILY}
                    onChange={onChange}
                  >
                    {RECURRING_CYCLE_LIST.map((cycle) => (
                      <MenuItem key={cycle.value} value={cycle.value}>
                        {cycle.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                );
              }}
            />
          </Box>
          {getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.DAILY && (
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} spacing={2}>
              <StyledInputLabel>Send Every</StyledInputLabel>
              <Controller
                control={control}
                name='repeatSettings.sendEvery'
                render={({ field: { value, onChange } }) => {
                  return (
                    <StyledSelect
                      color='secondary'
                      size='small'
                      value={value ? value : DAILY_SEND_EVERY[0].value}
                      onChange={onChange}
                      fullWidth
                    >
                      {DAILY_SEND_EVERY.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  );
                }}
              />
            </Stack>
          )}
          {getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.WEEKLY && (
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} gap={2}>
              <StyledInputLabel>Send Every</StyledInputLabel>
              <Controller
                control={control}
                name='repeatSettings.dayOfSent'
                render={({ field: { value, onChange } }) => {
                  return (
                    <StyledSelect
                      color='secondary'
                      size='small'
                      value={value ? value : WEEKLY_SEND_EVERY_LIST[0].value}
                      onChange={onChange}
                      fullWidth
                    >
                      {WEEKLY_SEND_EVERY_LIST.map((value) => (
                        <MenuItem key={value.value} value={value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  );
                }}
              />
            </Stack>
          )}
          {(getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.MONTHLY ||
            getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.YEARLY) && (
            <Controller
              control={control}
              name='repeatSettings.dateOfSent'
              render={({ field: { value, onChange } }) => {
                const label = MONTHLY_SEND_EVERY.find((v) => {
                  return v.value === value;
                })?.label;

                return (
                  <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} gap={2}>
                    <StyledInputLabel>
                      {/* Send on the {value} of Every Month */}
                      {getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.YEARLY
                        ? "Send Yearly On The"
                        : "Send Monthly On The"}
                    </StyledInputLabel>
                    <StyledSelect
                      color='secondary'
                      size='small'
                      value={value ? value : MONTHLY_SEND_EVERY[0].value}
                      onChange={onChange}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          style: { maxHeight: 250 },
                        },
                      }}
                    >
                      {MONTHLY_SEND_EVERY.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Stack>
                );
              }}
            />
          )}

          {getValues("repeatSettings.recurringCycle") === RECURRING_CYCLE.YEARLY && (
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} gap={2}>
              <StyledInputLabel>of</StyledInputLabel>
              <Controller
                control={control}
                name='repeatSettings.monthOfSent'
                render={({ field: { value, onChange } }) => {
                  return (
                    <StyledSelect
                      color='secondary'
                      size='small'
                      value={value ? value : YEARLY_SEND_EVERY_MONTH[0].value}
                      onChange={onChange}
                      fullWidth
                    >
                      {YEARLY_SEND_EVERY_MONTH.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  );
                }}
              />
            </Stack>
          )}

          <Controller
            control={control}
            name='repeatSettings.sentAt'
            render={({ field: { value, onChange } }) => {
              const label = SEND_AT.find((v) => v.value === +value)?.label;
              return (
                <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} spacing={2}>
                  <StyledInputLabel>at</StyledInputLabel>
                  <StyledSelect
                    color='secondary'
                    size='small'
                    value={value ? value : SEND_AT[0].value}
                    onChange={onChange}
                    fullWidth
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    }}
                  >
                    {SEND_AT.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Stack>
              );
            }}
          />
        </Stack>

        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            minWidth={{ xs: 100, lg: 120 }}
          >
            <StyledInputLabel variant='standard' required error={!!(errors.startDate && errors.startDate.message)}>
              Start Date
            </StyledInputLabel>
            <StyledInputLabel variant='standard'>:</StyledInputLabel>
          </Stack>

          <Stack direction={"row"} flex={1} alignItems={"center"} gap={2}>
            <Stack width={{ md: 120, lg: 200 }}>
              <CoreTextField
                size='small'
                type='date'
                color='secondary'
                fullWidth
                error={!!(errors.startDate && errors.startDate.message)}
                helperText={errors.startDate && errors.startDate.message}
                {...register("startDate", registerInvoiceBuilder.startDate)}
                className={"ib_editor_light_textField"}
              />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} gap={2} flex={1}>
              <StyledInputLabel>End Date</StyledInputLabel>
              <Controller
                control={control}
                name='endDateStatus'
                render={({ field: { value, onChange } }) => {
                  return (
                    <StyledSelect
                      color='secondary'
                      size='small'
                      value={value ? value : YEARLY_SEND_EVERY_MONTH[0].value}
                      onChange={onChange}
                    >
                      {endDateStatusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  );
                }}
              />
              {getValues("endDateStatus") !== InvoiceEndDateStatus.NEVER && (
                <CoreTextField
                  size='small'
                  type='date'
                  color='secondary'
                  fullWidth
                  error={!!(errors.endDate && errors.endDate.message)}
                  helperText={errors.endDate && errors.endDate.message}
                  {...register("endDate", registerInvoiceBuilder.endDate)}
                  className={"ib_editor_light_textField"}
                  sx={{ maxWidth: 200 }}
                />
              )}
              <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
                <StyledInputLabel
                  required
                  variant='standard'
                  error={!!(errors?.paymentTerms && errors.paymentTerms.message)}
                >
                  Payment terms:
                </StyledInputLabel>
                <Stack direction={"column"} flex={1}>
                  <input
                    type='text'
                    style={{ width: 0, height: 0, border: 0 }}
                    {...register("paymentTerms", registerInvoiceBuilder.paymentTerms)}
                  />
                  <SelectPaymentTerms callback={handleSelectPaymentTerm} paymentTerm={getValues("paymentTerms")} />
                  {errors?.paymentTerms && <FormHelperText error={true}>{errors.paymentTerms.message}</FormHelperText>}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default InvoiceRecurringSettings;
