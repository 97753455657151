import httpRequest from "./api/httpRequest";
import { invoiceStateInterface, invoiceStateReportInterface } from "../interface/invoiceState.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class InvoiceStateService {
  getInvoiceStateData() {
    return httpRequest.get<invoiceStateInterface>(invoiceAPI + `invoice-builder/report/stat/data`);
  }

  getInvoiceStateReportWithDateRange() {
    return httpRequest.get<invoiceStateReportInterface>(invoiceAPI + `invoice-builder/report/stat/data/date/range`);
  }
}

export default new InvoiceStateService();
