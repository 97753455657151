import * as React from "react";
import Popover from "@mui/material/Popover";
import { Box, styled, useTheme, ButtonProps } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CoreButton from "../Button/CoreButton";

interface CustomPopOverProps {
  buttonText: string;
  children: React.ReactNode;
  color?: ButtonProps["color"];
}

const CustomPopOver: React.FC<CustomPopOverProps> = ({ buttonText, children, color = "primary" }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <CoreButton
        variant={"contained"}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ backgroundColor: "#635dff !important" }}
      >
        {buttonText}
      </CoreButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ "& .MuiPopover-paper": { maxHeight: "245px", height: "100%" } }}
      >
        <Box sx={{ background: theme.palette.other.greyLight }} className={"ib_scroll_bar"}>
          {children}
        </Box>
      </Popover>
    </>
  );
};
export default CustomPopOver;
