import httpRequest from "./api/httpRequest";
import { SystemDefaultInfoInterface } from "../state/features/defaultInfo/defaultInfoInterfaces";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class DefaultInfoService {
  getSystemDefaultInfo() {
    return httpRequest.get<SystemDefaultInfoInterface>(invoiceAPI + "invoice-builder/invoice/system/default/info");
  }
}

export default new DefaultInfoService();
