import React from "react";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { useForm, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import CoreButton from "../../../common/Button/CoreButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { addressPattern } from "../../../helpers/validation/addressPattern";
import { AddressInterface, InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";

const TextFieldWithLabel = styled(CoreTextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    position: "relative",
    transform: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: 0,
    "& legend": {
      display: "none",
    },
  },
}));

interface Props {
  onClose: () => void;
  isShippingAddress?: boolean;
  setValue: UseFormSetValue<InvoiceInterface>;
  getValues: UseFormGetValues<InvoiceInterface>;
}

const AddressDrawer: React.FC<Props> = ({ onClose, isShippingAddress, setValue, getValues }) => {
  const addressType = isShippingAddress ? "shippingAddress" : "billingAddress";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressInterface>({
    defaultValues: getValues(`client.${addressType}`),
  });

  const onSubmitHandler = (data: AddressInterface) => {
    setValue(`client.${addressType}`, data);
    onClose();
  };

  return (
    <Box component='form'>
      <Grid container spacing={1} p={3}>
        <Grid item xs={12}>
          <TextFieldWithLabel
            fullWidth
            label={"Address"}
            color='secondary'
            placeholder='Enter Street Location Here'
            {...register("address", addressPattern.address)}
            error={errors.address ? true : false}
            helperText={errors.address?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldWithLabel
            label={"City"}
            color='secondary'
            placeholder='Enter City Here'
            {...register("city", addressPattern.city)}
            error={errors.city ? true : false}
            helperText={errors.city?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldWithLabel
            label={"State"}
            color='secondary'
            placeholder='Enter State Here'
            {...register("state", addressPattern.state)}
            error={errors.state ? true : false}
            helperText={errors.state?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldWithLabel
            label={"Zip Code"}
            color='secondary'
            placeholder='Enter Zip Code Here'
            {...register("zip", addressPattern.zip)}
            error={errors.zip ? true : false}
            helperText={errors.zip?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldWithLabel
            label={"Country"}
            color='secondary'
            placeholder='Enter Country Here'
            {...register("country", addressPattern.country)}
            error={errors.country ? true : false}
            helperText={errors.country?.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={1} direction='row' alignItems='center' justifyContent='flex-end'>
            <CoreButton variant='text' color='inherit' onClick={onClose}>
              Cancel
            </CoreButton>
            <CoreButton type='button' variant='contained' color='secondary' onClick={handleSubmit(onSubmitHandler)}>
              Save
            </CoreButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressDrawer;
