import { takeLatest } from "@redux-saga/core/effects";
import { all, call, delay, put } from "typed-redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  generatePDFError,
  generatePDFSuccess,
  getInvoiceBuilderDataFailed,
  getInvoiceBuilderDataSuccess,
  updateInvoiceBuilderDataFailed,
  updateInvoiceBuilderDataSuccess,
  updateInvoiceBuilderUpdateTime,
  resetUpdateInvoiceClientCanSee,
  updateInvoiceClientCanSeeSuccess,
} from "./invoiceBuilderSlice";
import invoiceBuilderService from "../../../services/invoiceBuilderService";
import { IGetInvoiceBuilderData, InvoiceInterface, IRequestUpdateClientCanSee } from "./invoiceBuilderInterface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import updateBuilderSettingsAt from "../../../helpers/utils/updateBuilderSettingsAt";
import savingBuilderSetting from "../../../helpers/utils/savingBuilderSetting";
import downloadFileFromFileUrl from "../../../helpers/utils/downloadFileFromFileUrl";

function* invoiceBuilderWatcher() {
  yield takeLatest("invoiceBuilder/getInvoiceBuilderData", getInvoiceBuilderDataSaga);
  yield takeLatest("invoiceBuilder/updateInvoiceBuilderData", updateInvoiceBuilderDataSaga);
  yield takeLatest("invoiceBuilder/generatePDF", generatePDFSaga);
  yield takeLatest("invoiceBuilder/updateInvoiceClientCanSee", updateInvoiceClientCanSeeSaga);
}

function* getInvoiceBuilderDataSaga(action: PayloadAction<IGetInvoiceBuilderData>) {
  try {
    const response = yield* call(invoiceBuilderService.getInvoiceBuilderData, action.payload);

    if (response.success) {
      yield put(getInvoiceBuilderDataSuccess(response.data));
    } else {
      yield put(getInvoiceBuilderDataFailed(response.message || ""));
    }
  } catch (err) {
    yield put(getInvoiceBuilderDataFailed("Network or server error"));
  }
}

function* updateInvoiceBuilderDataSaga(action: PayloadAction<InvoiceInterface>) {
  try {
    yield put(updateInvoiceBuilderUpdateTime(savingBuilderSetting()));

    const response = yield* call(invoiceBuilderService.updateInvoiceBuilderData, action.payload);

    if (response.success) {
      yield put(updateInvoiceBuilderDataSuccess(action.payload));
      showSuccessToaster("Invoice header saved successfully!");
    } else {
      yield put(updateInvoiceBuilderDataFailed(response.message));
      showErrorToaster(response.message);
    }
    yield put(updateInvoiceBuilderUpdateTime(updateBuilderSettingsAt()));
  } catch (err) {
    yield put(updateInvoiceBuilderDataFailed("Network error!!"));
    showErrorToaster("Network error!!");
  }
}

function* generatePDFSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(invoiceBuilderService.generatePDF, action.payload);

    if (response.success) {
      downloadFileFromFileUrl(response.data.url);
      yield delay(1000);
      yield put(generatePDFSuccess(response.data.url));
      showSuccessToaster("Successfully download file");
    } else {
      yield put(generatePDFError(response.message as string));
      showErrorToaster("Failed, to download file");
    }
  } catch (err) {
    showErrorToaster("Network error!!");
  }
}

function* updateInvoiceClientCanSeeSaga(action: PayloadAction<IRequestUpdateClientCanSee>) {
  try {
    yield delay(1000);
    const response = yield* call(invoiceBuilderService.updateInvoiceClientCanSee, action.payload);

    if (response.success) {
      yield put(updateInvoiceClientCanSeeSuccess(action.payload));
      showSuccessToaster("Successfully update client can see columns");
    } else {
      yield put(resetUpdateInvoiceClientCanSee());
      showErrorToaster("Failed, to update client can see columns");
    }
  } catch (err) {
    showErrorToaster("Network error!!");
  }
}

export default function* invoiceBuilderSaga() {
  yield all([invoiceBuilderWatcher()]);
}
