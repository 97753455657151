import { all, call, debounce, put } from "typed-redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import ContactService from "../../../services/contactService";
import { getContactsFailed, getContactsSuccess } from "./contactSlice";
import { ContactInterface, getContactInterface, IStageContact } from "./contactInterfaces";
import { GLOBAL_DELAY } from "../../../helpers/constant/coreConstants";
import getFullName from "../../../helpers/utils/getFullName";
import { getCookie } from "../../../helpers/Cookie";

function* contactWatcher() {
  yield debounce(GLOBAL_DELAY.DEBOUNCE, "contact/getContacts", getContactsSaga);
}

function* getContactsSaga(action: PayloadAction<getContactInterface>) {
  try {
    if (process.env.REACT_APP_PRODUCTION_MODE !== "true") {
      const payload = {
        "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN as string),
        page: action.payload.page,
        perPage: action.payload.size,
        q: action.payload.search,
      };
      const response = yield* call(ContactService.getStageContactList, payload);
      if (response.status) {
        const contacts: ContactInterface[] = response.data.pypepro_contacts?.map((contact: IStageContact) => {
          const { first_name, last_name, ...rest } = contact || {};
          return { ...rest, firstName: first_name, lastName: last_name, fullName: getFullName(first_name, last_name) };
        });

        yield put(getContactsSuccess(contacts || []));
      } else {
        yield put(getContactsFailed(response.message as string));
      }
    } else {
      const response = yield* call(ContactService.getContactList, action.payload);

      if (response.status === "SUCCESS") {
        yield put(getContactsSuccess(response.data.contactInfos));
      } else {
        yield put(getContactsFailed(response.message as string));
      }
    }
  } catch (err) {
    yield put(getContactsFailed("Network or server error"));
  }
}

export default function* contactSaga() {
  yield all([contactWatcher()]);
}
