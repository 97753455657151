import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import {
  ArchiveStatusPayload,
  FavoriteStatusPayload,
  GetInvoicesPayload,
  IAddInvoicePayload,
  ICloneInvoicePayload,
  InvoiceListItemInterface,
  InvoiceRemovePayload,
  IAddInvoicesToFoldersPayload,
  IRemoveFolderFromInvoicePayload,
  IRenamedInvoicePayload,
  IDeleteForeverPayload,
} from "./invoice.interface";
import { InvoiceOrderByList } from "../../../helpers/constant/invoiceConstant";
import { InvoiceInterface } from "../invoiceBuilder/invoiceBuilderInterface";

const showReportAlert = localStorage.getItem("showReportAlert") === "true";
const initialState = {
  data: [] as InvoiceListItemInterface[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 10,
  nextPage: 1,
  totalPages: 1,

  renderId: "",

  invoice: {} as InvoiceInterface,
  selectedInvoiceIds: [] as number[],

  isNewInvoiceInsideFolder: false,
  folderIdForCreateNewInvoice: 0,

  isInvoiceFetching: false,
  isInvoiceFetchingSuccess: false,

  isAdding: false,
  isAddSuccess: false,
  addedInvoiceData: {} as InvoiceInterface,

  isRenaming: false,
  isRenameSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  showReportAlert: showReportAlert,

  filters: {
    folderId: null as number | null,
    isFavorite: false,
    isArchived: false,
    inTrash: false,
    searchText: "",
    orderBy: InvoiceOrderByList[2].value,
    paymentStatus: "",
  },
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    getInvoices: (state, action: PayloadAction<GetInvoicesPayload>) => {
      if (!state.renderId) state.isLoading = true;
    },
    getInvoicesSuccess: (state, action: PayloadAction<WithPagination<InvoiceListItemInterface>>) => {
      const { content, count, limit, currentPage } = action.payload;
      state.data = content;
      state.count = count;
      state.limit = +limit;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getInvoicesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    forceInvoiceRender: (state) => {
      state.renderId = nanoid(5);
    },

    addInvoice: (state, action: PayloadAction<IAddInvoicePayload>) => {
      state.isAdding = true;
    },
    addInvoiceSuccess: (state, action: PayloadAction<InvoiceInterface>) => {
      state.addedInvoiceData = action.payload;
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addInvoiceFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    resetAddInvoiceSuccess: (state) => {
      state.isAddSuccess = false;
      state.addedInvoiceData = {} as InvoiceInterface;
    },

    renamedInvoice: (state, action: PayloadAction<IRenamedInvoicePayload>) => {
      state.isRenaming = true;
    },
    renamedInvoiceSuccess: (state, action: PayloadAction<IRenamedInvoicePayload>) => {
      state.isRenaming = false;
      state.isRenameSuccess = true;
      state.data = state.data.map((invoice) => {
        if (invoice.id === action.payload.invoiceId) return { ...invoice, name: action.payload.name };
        return invoice;
      });
    },
    renamedInvoiceFailed: (state) => {
      state.isRenaming = false;
      state.isRenameSuccess = false;
    },
    resetRenamedInvoiceSuccess: (state) => {
      state.isRenameSuccess = false;
    },

    deleteInvoices: (state, action: PayloadAction<InvoiceRemovePayload>) => {
      state.isDeleting = true;
    },
    deleteInvoiceSuccess: (state, action: PayloadAction<InvoiceRemovePayload>) => {
      if (action.payload) {
        state.data = state.data.filter((invoice) => !action.payload.ids.includes(invoice.id as number));
      }
      state.isDeleting = false;
      state.selectedInvoiceIds = [];
      state.renderId = nanoid(5);
    },
    deleteInvoiceFailed: (state) => {
      state.isDeleting = false;
    },

    deleteInvoicesForever: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      state.isDeleting = true;
    },
    deleteInvoicesForeverSuccess: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      if (action.payload) {
        state.data = state.data.filter((invoice) => !action.payload.ids.includes(invoice.id as number));
      }
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.selectedInvoiceIds = [];
      state.renderId = nanoid(5);
    },
    deleteInvoicesForeverFailed: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },
    resetDeleteInvoiceForeverSuccess: (state) => {
      state.isDeleteSuccess = false;
    },

    cloneInvoice: (state, action: PayloadAction<ICloneInvoicePayload>) => {
      state.isUpdating = true;
    },
    cloneInvoiceSuccess: (state) => {
      state.isUpdating = false;
      state.renderId = nanoid(5);
    },
    cloneInvoiceFailed: (state) => {
      state.isUpdating = false;
    },

    toggleFavStatus: (state, action: PayloadAction<FavoriteStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleFavStatusSuccess: (state, action: PayloadAction<FavoriteStatusPayload>) => {
      state.data = state.data.map((invoice) => {
        if (invoice.id === action.payload.id)
          return {
            ...invoice,
            isFavorite: action.payload.isFavorite,
          };
        return invoice;
      });
      state.isUpdating = false;
      state.selectedInvoiceIds = [];
      if (state.filters.isFavorite) state.renderId = nanoid(5);
    },
    toggleFavStatusFailed: (state) => {
      state.isUpdating = false;
    },

    toggleArchiveStatus: (state, action: PayloadAction<ArchiveStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleArchiveStatusSuccess: (state, action: PayloadAction<ArchiveStatusPayload>) => {
      state.data = state.data.filter((invoice) => !action.payload.ids.includes(invoice.id as number));
      state.isUpdating = false;
      state.selectedInvoiceIds = [];
      state.renderId = nanoid(5);
    },
    toggleArchiveStatusFailed: (state) => {
      state.isUpdating = false;
    },

    // invoice selection actions
    selectedAllInvoice: (state) => {
      state.selectedInvoiceIds = state.data.map((data) => Number(data.id));
    },
    deselectAllInvoice: (state) => {
      state.selectedInvoiceIds = [];
    },
    toggleSelectedSingleInvoice: (state, action) => {
      const currentFormIndex = state.selectedInvoiceIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedInvoiceIds.splice(currentFormIndex, 1);
      } else {
        state.selectedInvoiceIds.push(action.payload);
      }
    },

    addInvoicesToFolders: (state, action: PayloadAction<IAddInvoicesToFoldersPayload>) => {
      state.isUpdating = true;
    },

    addInvoicesToFoldersSuccess: (state, action: PayloadAction<IAddInvoicesToFoldersPayload>) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.renderId = nanoid(5);
    },
    addInvoicesToFoldersFailed: (state) => {
      state.isUpdating = false;
    },

    resetInvoiceUpdateSuccess: (state) => {
      state.isUpdateSuccess = false;
    },

    removeFolderFromInvoice: (state, action: PayloadAction<IRemoveFolderFromInvoicePayload>) => {
      state.isUpdating = true;
    },

    removeFolderFromInvoiceSuccess: (state, action: PayloadAction<IRemoveFolderFromInvoicePayload>) => {
      state.isUpdating = false;
      const { folderId, invoiceId } = action.payload;
      if (folderId === state.filters.folderId) {
        state.data = state.data.filter((invoice) => invoice.id !== invoiceId);
      } else {
        state.data = state.data.map((invoice) => {
          if (invoice.id === invoiceId) {
            return {
              ...invoice,
              folder: invoice.folder?.filter((singleFolder) => singleFolder.folderId !== folderId) || [],
            };
          }
          return invoice;
        });
      }
    },

    onShowReportAlert: (state) => {
      localStorage.setItem("showReportAlert", "true");
      state.showReportAlert = true;
    },
    onCloseReportAlert: (state) => {
      localStorage.setItem("showReportAlert", "false");
      state.showReportAlert = false;
    },

    selectFolderId: (state, action) => {
      state.filters.folderId = action.payload;
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.selectedInvoiceIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectFavoriteFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = true;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.selectedInvoiceIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectArchivedFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = false;
      state.filters.isArchived = true;
      state.filters.inTrash = false;
      state.selectedInvoiceIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectTrashFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = true;
      state.selectedInvoiceIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },

    addInvoiceSearchTextFilter: (state, action: PayloadAction<string>) => {
      state.filters.searchText = action.payload;
      state.currentPage = 1;
    },
    addInvoiceOrderByFilter: (state, action: PayloadAction<string>) => {
      state.filters.orderBy = action.payload;
      state.currentPage = 1;
    },
    addInvoicePaymentStatus: (state, action: PayloadAction<string>) => {
      state.filters.paymentStatus = action.payload;
      state.currentPage = 1;
    },

    resetInvoiceFilter: (state) => {
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.filters.searchText = "";
      state.filters.orderBy = InvoiceOrderByList[2].value;
      state.filters.paymentStatus = "";
    },
    resetOnlyInvoiceFilter: (state) => {
      state.filters.searchText = "";
      state.filters.orderBy = InvoiceOrderByList[2].value;
      state.filters.paymentStatus = "";
    },
  },
});

export const {
  addInvoice,
  addInvoiceFailed,
  addInvoiceSuccess,
  resetAddInvoiceSuccess,
  forceInvoiceRender,

  renamedInvoice,
  renamedInvoiceFailed,
  renamedInvoiceSuccess,
  resetRenamedInvoiceSuccess,

  deleteInvoices,
  deleteInvoiceFailed,
  deleteInvoiceSuccess,

  deleteInvoicesForever,
  deleteInvoicesForeverFailed,
  deleteInvoicesForeverSuccess,
  resetDeleteInvoiceForeverSuccess,

  getInvoices,
  getInvoicesFailed,
  getInvoicesSuccess,

  cloneInvoice,
  cloneInvoiceFailed,
  cloneInvoiceSuccess,

  toggleFavStatus,
  toggleFavStatusSuccess,
  toggleFavStatusFailed,

  toggleArchiveStatus,
  toggleArchiveStatusSuccess,
  toggleArchiveStatusFailed,

  selectedAllInvoice,
  deselectAllInvoice,
  toggleSelectedSingleInvoice,

  addInvoicesToFolders,
  addInvoicesToFoldersSuccess,
  addInvoicesToFoldersFailed,

  removeFolderFromInvoice,
  removeFolderFromInvoiceSuccess,

  onShowReportAlert,
  onCloseReportAlert,

  addInvoiceSearchTextFilter,
  addInvoiceOrderByFilter,
  addInvoicePaymentStatus,

  selectFolderId,
  selectFavoriteFolder,
  selectArchivedFolder,
  selectTrashFolder,

  resetInvoiceUpdateSuccess,
  resetInvoiceFilter,
  resetOnlyInvoiceFilter,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
