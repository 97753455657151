import React from "react";
import { Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  value: string | number;
  color: string;
}

const TitleAndValue: React.FC<Props> = ({ title = "", value = "", color = "" }) => {
  return (
    <Stack>
      <Typography variant={"subtitle1"} color={color}>
        {title}
      </Typography>
      <Typography variant={"body2"} color={color}>
        {value}
      </Typography>
    </Stack>
  );
};

export default TitleAndValue;
