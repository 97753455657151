import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContactInterface, getContactInterface } from "./contactInterfaces";

const initialState = {
  data: [] as ContactInterface[],
  page: 1,
  perPage: 50,
  isLoading: true,
  isError: false,
  error: "",
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    getContacts(state, action: PayloadAction<getContactInterface>) {
      state.isLoading = true;
    },
    getContactsSuccess(state, action: PayloadAction<ContactInterface[]>) {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getContactsFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { getContacts, getContactsSuccess, getContactsFailed } = contactSlice.actions;
export default contactSlice.reducer;
