import Popover from "@mui/material/Popover";
import { Search } from "@mui/icons-material";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InputAdornment, List, Skeleton, Stack, styled } from "@mui/material";
import SinglePaymentTerm from "./SinglePaymentTerm";
import AddOrEditPaymentTerm from "./AddOrEditPaymentTerm";
import CoreButton from "../../../common/Button/CoreButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import compressString from "../../../helpers/utils/compressString";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { getPaymentTerms } from "../../../state/features/paymentTerm/paymentTermSlice";
import { selectPaymentTermState } from "../../../state/features/paymentTerm/paymentTermSelector";
import { PaymentTermInterface } from "../../../state/features/paymentTerm/paymentTerm.interface";
import { PAYMENT_TERM_STATUS_DEFAULT_LIST } from "../../../helpers/constant/paymentTermConstant";
import { showErrorToaster } from "../../../helpers/utils/toaster";

const StyledButton = styled(CoreButton)(({ theme }) => ({
  justifyContent: "flex-start",
  color: theme.palette.text.secondary,
  "& .MuiButton-endIcon": {
    marginLeft: "auto",
  },
  "&.MuiButton-outlinedInherit": {
    borderColor: theme.palette.other.outlinedBorderDarker,
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
  "&.MuiButton-sizeMedium": {
    padding: "7px 10px",
  },
}));

interface IProps {
  callback: (paymentTermInfo: PaymentTermInterface) => void;
  paymentTerm?: PaymentTermInterface;
}

const defaultDays = [0, 3, 7, 10, 15, 20, 25, 30, 60];

const SelectPaymentTerms: React.FC<IProps> = ({ callback, paymentTerm }) => {
  const dispatch = useDispatch();

  const { name } = paymentTerm || {};
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const onOpenPopup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);

  const onClosePopup = () => setAnchorEl(null);

  const { data: paymentTermList = [], isLoading } = useSelector(selectPaymentTermState);
  let customDays = paymentTermList?.map((paymentTerm) => paymentTerm.value) ?? [];
  const alreadyHasDays = [...defaultDays, ...customDays];

  const handleSelectPaymentTerms = (paymentTerm: PaymentTermInterface) => {
    callback(paymentTerm);
    onClosePopup();
  };

  const handleChangeNewPaymentTerm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (paymentTermList?.length < 10) {
      onOpenModal();
    } else {
      showErrorToaster("You already added 10 custom payment terms. Which is the max that you can add");
    }
  };

  const handleClickPaymentTerm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOpenPopup(event);
    dispatch(getPaymentTerms({ searchText: searchText, currentPage: 1, limit: 10 }));
  };

  const handleSearch = debounceHandler((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, 500);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = Array.from({ length: 5 })
      .fill("")
      .map((_, i) => <Skeleton key={i} variant={"rectangular"} height={60} sx={{ mb: 1 }} />);
  } else if (paymentTermList.length) {
    content = paymentTermList
      .filter((paymentTerm) => {
        if (searchText) return paymentTerm.name.toLowerCase().includes(searchText.toLowerCase());
        return true;
      })
      .map((paymentTerm) => (
        <SinglePaymentTerm
          key={paymentTerm.id}
          paymentTermInfo={paymentTerm}
          handleSelectPaymentTerms={handleSelectPaymentTerms}
          alreadyHasDays={alreadyHasDays}
        />
      ));
  }

  const defaultContent = PAYMENT_TERM_STATUS_DEFAULT_LIST.filter((paymentTerm) => {
    if (searchText) return paymentTerm.name.toLowerCase().includes(searchText.toLowerCase());
    return true;
  }).map((paymentTerm) => (
    <SinglePaymentTerm
      key={paymentTerm.id}
      paymentTermInfo={paymentTerm}
      handleSelectPaymentTerms={handleSelectPaymentTerms}
      isDefault={true}
    />
  ));

  const open = Boolean(anchorEl);
  const id = open ? "paymentTerm-select-popover" : undefined;

  return (
    <>
      <StyledButton
        fullWidth
        variant={"outlined"}
        color={"inherit"}
        aria-describedby={id}
        onClick={handleClickPaymentTerm}
        endIcon={<ArrowDropDownIcon />}
        sx={{ fontWeight: 300, minWidth: "max-content" }}
      >
        {name ? <span title={name}>{compressString(name, 30)}</span> : "Choose Payment Term"}
      </StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopup}
        elevation={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          '& input[type="search"]::-webkit-search-decoration, & input[type="search"]::-webkit-search-cancel-button, & input[type="search"]::-webkit-search-results-button, & input[type="search"]::-webkit-search-results-decoration':
            {
              WebkitAppearance: "button",
            },
        }}
      >
        <Stack spacing={1} sx={{ width: { md: 200, lg: 310 }, p: 1 }}>
          <CoreTextField
            fullWidth
            size={"small"}
            type={"search"}
            color={"secondary"}
            placeholder={"Search Payment Terms"}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            defaultValue={searchText}
            onChange={handleSearch}
          />

          <List sx={{ mt: 0, maxHeight: "350px", overflowY: "auto" }} className={"ib_scroll_bar"}>
            {content}
            {defaultContent}
          </List>

          <CoreButton
            fullWidth
            size={"small"}
            variant={"outlined"}
            color={"secondary"}
            startIcon={<AddCircleIcon />}
            onClick={handleChangeNewPaymentTerm}
          >
            Add Custom
          </CoreButton>
        </Stack>
      </Popover>

      <BasicModalWithHeader title={"Add New Payment Term"} open={openModal} onClose={onCloseModal}>
        <AddOrEditPaymentTerm onClose={onCloseModal} isEditMode={false} alreadyHasDays={alreadyHasDays} />
      </BasicModalWithHeader>
    </>
  );
};

export default SelectPaymentTerms;
