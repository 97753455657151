import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { AppDispatch } from "../../state/store";
import { Avatar, Box, FormControl, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CoreTextField from "../../common/TextField/CoreTextField";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreInputLabel from "../../common/TextField/CoreInputLabel";
import { registerInvoiceBuilder } from "../../helpers/constant/registerPattern";
import {
  addInvoice,
  renamedInvoice,
  resetAddInvoiceSuccess,
  resetRenamedInvoiceSuccess,
} from "../../state/features/invoice/invoiceSlice";
import { selectInvoiceState } from "../../state/features/invoice/invoiceSelector";
import { InvoiceListItemInterface, InvoiceTypeEnum } from "../../state/features/invoice/invoice.interface";
import { INVOICE_TYPE, INVOICE_TYPE_LIST } from "../../helpers/constant/invoiceConstant";
import { CreateInvoiceOption } from "./InvoiceHomePageStyles";

interface Props {
  onClose: () => void;
  isEditMode: boolean;
  invoiceInfo?: InvoiceListItemInterface;
  folderId?: number;
}

const AddOrEditInvoiceName: React.FC<Props> = ({ onClose, isEditMode, invoiceInfo, folderId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { isAdding, isAddSuccess, isRenameSuccess, isRenaming, addedInvoiceData } = useSelector(selectInvoiceState);
  const { id: invoiceId } = addedInvoiceData || {};
  const [invoiceType, setInvoiceType] = useState<InvoiceTypeEnum>(INVOICE_TYPE.ONE_TIME as InvoiceTypeEnum);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<InvoiceListItemInterface>();

  const onSubmit: SubmitHandler<InvoiceListItemInterface> = (data) => {
    if (isEditMode && invoiceInfo?.id) {
      dispatch(renamedInvoice({ invoiceId: invoiceInfo.id as number, name: data.name }));
    } else {
      dispatch(addInvoice({ name: data.name, folderId, invoiceType: invoiceType }));
    }
  };

  useEffect(() => {
    if (invoiceInfo) {
      setValue("name", invoiceInfo.name);
      if (invoiceInfo.invoiceType) setInvoiceType(invoiceInfo.invoiceType);
    }
  }, [invoiceInfo]);

  useEffect(() => {
    if (isAddSuccess || isRenameSuccess) {
      onClose();
      dispatch(resetAddInvoiceSuccess());
      dispatch(resetRenamedInvoiceSuccess());
    }
    if (isAddSuccess) {
      history.push("/ibuilder/invoice/" + invoiceId);
    }
  }, [isAddSuccess, isRenameSuccess]);

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
          <CoreInputLabel variant='standard'>Invoice Title</CoreInputLabel>
          <CoreTextField
            type='text'
            size='small'
            color='secondary'
            placeholder='Enter your invoice title'
            error={!!(errors.name && errors.name.message)}
            helperText={errors.name && errors.name.message}
            {...register("name", registerInvoiceBuilder.name)}
          />
        </FormControl>

        <Stack spacing={1} sx={isEditMode ? { display: "none" } : null}>
          <CoreInputLabel variant='standard'>Choose Invoice Type</CoreInputLabel>
          <Stack direction={"row"} spacing={2}>
            {Object.values(INVOICE_TYPE_LIST).map(({ value, label, content, icon }) => (
              <CreateInvoiceOption
                key={value}
                variant={"outlined"}
                className={value === invoiceType ? "ib_optionSelected" : ""}
                onClick={() => setInvoiceType(value as InvoiceTypeEnum)}
              >
                <CheckCircleOutlineIcon className={"ib_checkedIcon"} />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Avatar className={"ib_invoice_type_icon"}>{icon}</Avatar>
                  <Box>
                    <Typography variant={"body2"} color={"text.primary"}>
                      {label}
                    </Typography>
                    <Typography variant={"helperText"} color={"action.active"}>
                      {content}
                    </Typography>
                  </Box>
                </Stack>
              </CreateInvoiceOption>
            ))}
          </Stack>
        </Stack>
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          size='large'
          color='success'
          type='submit'
          loadingText={isEditMode ? "Renaming..." : "Creating..."}
          isLoading={isAdding || isRenaming}
        >
          {isEditMode ? "Rename" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditInvoiceName;
