import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  hover?: boolean;
  to: string;
}

const RouteLinkWithPrevPath: React.FC<Props> = ({ to = "", children, hover = false, ...rest }) => {
  return (
    <Link
      to={{ pathname: to, state: { prevPath: location.pathname } }}
      style={{
        color: "inherit",
        textDecoration: hover ? "underline" : "none",
        display: "inline-flex",
      }}
      {...rest}
    >
      {children || null}
    </Link>
  );
};

export default RouteLinkWithPrevPath;
