import { format } from "date-fns-tz";

// const previewDateFormat = (value: string | Date) => {
//   const time = new Date(value).toISOString();
//   const parsedTime = parseISO(time);
//
//   const formatInTimeZone = (date: string | Date, fmt: string, tz: string) =>
//     format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
//
//   return formatInTimeZone(parsedTime, "EEE MMM dd, yyyy", "UTC");
// };
//
// export default previewDateFormat;

// export const dateFormatInTimeZone = (value: string | Date) => {
//   const time = new Date(value).toISOString();
//   const parsedTime = parseISO(time);
//
//   const formatInTimeZone = (date: string | Date, fmt: string, tz: string) =>
//     format(utcToZonedTime(date, tz), fmt, { timeZone: tz });
//
//   return formatInTimeZone(parsedTime, "yyyy-MM-dd", "UTC");
// };

// export const parseUTCDateToRequireDate = (value: string) => {
//   let dataArray = value.slice(0, 10).split("-");
//   let d = new Date();
//   d.setFullYear(+dataArray[0], +dataArray[1] - 1, +dataArray[2]);
//
//   return d;
// };

const previewDateFormat = (value: string | Date) => {
  const date = new Date(value);
  return format(date, "EEE MMM dd, yyyy");
};

export const inputDateFormat = (value: string | Date) => {
  const date = new Date(value);
  return format(date, "yyyy-MM-dd");
};

export default previewDateFormat;
