import { all, call, put, takeLatest } from "typed-redux-saga";
import DefaultInfoService from "../../../services/defaultInfoService";
import { getSystemDefaultInfoFailed, getSystemDefaultInfoSuccess } from "./defaultInfoSlice";

function* defaultInfoWatcher() {
  yield takeLatest("defaultInfo/getSystemDefaultInfo", getSystemDefaultInfoSaga);
}

function* getSystemDefaultInfoSaga() {
  try {
    const response = yield* call(DefaultInfoService.getSystemDefaultInfo);

    if (response.success) {
      yield put(getSystemDefaultInfoSuccess(response.data));
    } else {
      yield put(getSystemDefaultInfoFailed(response.message));
    }
  } catch (err) {
    yield put(getSystemDefaultInfoFailed("Network or server error"));
  }
}

export default function* defaultInfoSaga() {
  yield all([defaultInfoWatcher()]);
}
