import { alpha, Box, Stack, styled, Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";

export const BuilderHeaderWrapper = styled(Stack)(({ theme }) => ({
  height: "52px",
  padding: "10px 16px",
  background: theme.palette.other.darkNavy,
  width: "100%",
  zIndex: 99,
}));

interface BuilderHeaderActionProps {
  selectedOption: boolean;
}
export const BuilderHeaderAction = styled(CoreButton, {
  shouldForwardProp: (prop: string) => prop !== "selectedOption",
})<BuilderHeaderActionProps>(({ theme, selectedOption }) => ({
  backgroundColor: selectedOption ? theme.palette.common.white : alpha(theme.palette.common.white, 0.1),
  color: selectedOption ? theme.palette.other.darkNavy : theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.other.darkNavy,
  },
  "&.MuiButton-contained:focus": {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.other.darkNavy} !important`,
  },
}));

//builder
export const BuilderBodyWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100vh - 52px)",
  padding: theme.spacing(4, 0),
  overflowY: "auto",
  backgroundColor: theme.palette.other.outlinedBorder,
}));
export const BuilderBodyContent = styled(Box)(({ theme }) => ({
  width: "80%",
  maxWidth: "1300px",
  height: "100%",
  margin: "auto",
  transition: "width 0.3s ease",
  [theme.breakpoints.down("lg")]: {
    width: "95%",
  },
}));

interface CompletedTextProps {
  completed: string;
}

export const CompletedText = styled(Typography)<CompletedTextProps>(({ theme, completed }) => ({
  marginLeft: theme.spacing(1),
  transitionOrigin: "left",
  transition: "all 0.3s ease",
  opacity: completed === "true" ? 1 : 0,
  pointerEvents: completed === "true" ? "all" : "none",
}));

export const InvoiceContainer = styled(Box)(({ theme }) => ({
  height: "max-content",
  margin: "0 auto",
  padding: theme.spacing(3),
}));

export const SectionWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2.5, 0),
  borderTop: `1px dashed ${theme.palette.secondary.main}`,
}));

export const BuilderStickyButtonWrapper = styled(Stack)(({ theme }) => ({
  position: "sticky",
  bottom: -32,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  background: theme.palette.common.white,
  marginTop: 12,
  zIndex: 99,
}));
