import React from "react";
import { Avatar, Paper, Skeleton, Stack, Typography } from "@mui/material";

interface Props {
  title?: string;
  subtitle?: string;
  amount?: string;
  icon?: React.ReactNode;
  color?: string;
  isLoading?: boolean;
}

const SingleReportCard: React.FC<Props> = ({ title, subtitle, amount = 0, icon, color, isLoading = false }) => {
  return (
    <>
      <Paper elevation={0} sx={{ p: 4, width: "100%", height: "100%" }}>
        <Stack justifyContent={"center"} alignItems={"center"} spacing={2} sx={{ textAlign: "center" }}>
          <Avatar variant={"circular"} sx={{ bgcolor: color }}>
            {icon}
          </Avatar>
          <Typography variant={"subtitle1"} color={color} fontWeight={500}>
            {title}
          </Typography>
          <Typography variant={"body2"} color={"text.secondary"}>
            {subtitle}
          </Typography>
          <Typography variant={"h5"} color={color}>
            {isLoading ? <Skeleton width={40} height={40} /> : amount}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};

export default SingleReportCard;
