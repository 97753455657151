import { styled, TextField } from "@mui/material";

interface CoreTextFiledProps {
  textAlign?: string;
}

const CoreTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => prop !== "textAlign",
})<CoreTextFiledProps>(({ theme, textAlign }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& input": {
    height: "40px !important",
    padding: "0 14px !important",
    margin: "0 !important",
    border: "none !important",
    textAlign: textAlign || "left",
    "&.MuiOutlinedInput-input": {
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
    "&.MuiInputBase-inputSizeSmall": {
      padding: "0 12px !important",
    },
    "&:focus": {
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  "& .MuiInputBase-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
      },
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.other.outlinedBorderDarker,
      },
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
  "&.ib_editor_light_textField": {
    "& input,& textarea": {
      fontWeight: 300,
      color: theme.palette.text.secondary,
    },
  },
}));

export default CoreTextField;
