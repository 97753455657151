import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import FolderListSkeleton from "../../common/LoadingView/FolderListSkeleton";
import LoadingButton from "../../common/Button/LoadingButton";
import { AppDispatch } from "../../state/store";
import arrayEquals from "../../helpers/utils/arrayEquals";
import removeDuplicates from "../../helpers/utils/removeDuplicates";
import { getFolders } from "../../state/features/folder/folderSlice";
import { selectFolderState } from "../../state/features/folder/folderSelector";
import { selectInvoiceState } from "../../state/features/invoice/invoiceSelector";
import { addInvoicesToFolders, resetInvoiceUpdateSuccess } from "../../state/features/invoice/invoiceSlice";

const StyledListIcon = styled(ListItemIcon)(() => ({
  minWidth: "36px",
  height: "24px",
}));

interface IProps {
  invoiceIds: number[];
  folderIds: number[];
  onClose: () => void;
}

const AddToFolderInvoice: React.FC<IProps> = ({ invoiceIds, folderIds, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isUpdating, isUpdateSuccess } = useSelector(selectInvoiceState);
  const { data: folderList, count, isLoading } = useSelector(selectFolderState);
  const [selectedFolderIds, setSelectedFolderIds] = useState([...folderIds]);

  // if all folders is not in folder list
  useEffect(() => {
    if (folderList?.length < count) dispatch(getFolders({ limit: count, currentPage: 1 }));
  }, []);

  useEffect(() => {
    if (isUpdateSuccess) {
      onClose();
      dispatch(resetInvoiceUpdateSuccess());
    }
  }, [isUpdateSuccess]);

  const handleToggle = (folderId: number) => () => {
    const folderIds = [...selectedFolderIds];
    const folderIdIndex = folderIds.indexOf(folderId);

    if (folderIdIndex !== -1) {
      folderIds.splice(folderIdIndex, 1);
    } else {
      folderIds.push(folderId);
    }

    setSelectedFolderIds(folderIds);
  };

  const handleSave = () => {
    const newAddFolderIds = selectedFolderIds.filter((id) => folderIds.indexOf(id) === -1);

    const folderIdList = removeDuplicates([...newAddFolderIds, ...selectedFolderIds]);
    // handle invoice list add to folder
    if (folderIdList.length > 0) {
      dispatch(addInvoicesToFolders({ folderIds: folderIdList, invoiceIds: invoiceIds }));
    }
  };

  const isEqual = arrayEquals(folderIds, selectedFolderIds);

  // ## decide what to render on view
  let folderContent = null;
  if (isLoading) {
    folderContent = <FolderListSkeleton count={4} />;
  } else {
    folderContent = folderList.map((folder, index) => {
      const labelId = `checkbox-list-label-${index}`;
      const isChecked = selectedFolderIds.indexOf(folder.id as number) !== -1;

      return (
        <ListItem key={folder.id as number} disablePadding>
          <ListItemButton
            onClick={handleToggle(folder.id as number)}
            sx={{ py: 0.5, px: 1, borderRadius: "4px" }}
            dense
          >
            <StyledListIcon>
              <Checkbox
                checked={isChecked}
                edge='start'
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </StyledListIcon>
            <ListItemText id={labelId} primary={folder.name} />
          </ListItemButton>
        </ListItem>
      );
    });
  }

  return (
    <Box p={1} sx={{ maxHeight: 300 }}>
      <Box
        sx={{ width: 260, maxHeight: "calc(300px - 51px)", marginBottom: "6px", overflowY: "auto" }}
        className={"ib_scroll_bar"}
      >
        <List sx={{ bgcolor: "background.paper" }} color='secondary'>
          {folderContent}
        </List>
      </Box>
      <LoadingButton
        fullWidth
        disabled={isEqual}
        loadingText={"Saving"}
        isLoading={isUpdating}
        size={"small"}
        variant={"contained"}
        color={"secondary"}
        onClick={handleSave}
      >
        Save
      </LoadingButton>
    </Box>
  );
};

export default AddToFolderInvoice;
