import { alpha, InputLabel, Paper, Select, Stack, styled } from "@mui/material";

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  minWidth: "max-content",
}));

export const ContactStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(1),
  backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity),
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  height: 90,
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  // border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  p: 1,
  // flex: 1,
  // borderRadius: "4px",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "-20px",
    top: "10px",
  },
  "& img": {
    // width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export const AddressBox = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.other.outlinedBorderDarker,
  padding: "6px 8px",
  minHeight: "80px",
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& fieldset": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
}));
