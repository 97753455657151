import React from "react";
import { Skeleton } from "@mui/material";

const InvoiceLIstSkeleton = ({ count = 1 }) => {
  return (
    <>
      <Skeleton variant='rectangular' height={28} />
      {Array.from({ length: count }).map((rowNum, index) => (
        <Skeleton key={index} variant='rectangular' sx={{ mt: 1 }} height={30} />
      ))}
    </>
  );
};

export default InvoiceLIstSkeleton;
