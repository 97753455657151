import React, { useEffect } from "react";
import { format, setHours, setMinutes } from "date-fns";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";
import InvoiceItemsBody from "./InvoiceItems/InvoiceItemsBody";
import InvoiceHeaderContent from "./InvoiceHeader/InvoiceHeaderContent";
import InvoiceFooterContent from "./InvoiceFooter/InvoiceFooterContent";
import LoadingButton from "../../common/Button/LoadingButton";
import { AppDispatch } from "../../state/store";
import { showErrorToaster } from "../../helpers/utils/toaster";
import { INVOICE_TYPE, TEMPLATE_TYPE } from "../../helpers/constant/invoiceConstant";
import { CLIENT_CAN_SEE_KEY } from "../../helpers/constant/invoiceSettingConstant";
import { selectDefaultInfoData } from "../../state/features/defaultInfo/defaultInfoSelector";
import { InvoiceInterface } from "../../state/features/invoiceBuilder/invoiceBuilderInterface";
import {
  PAYMENT_METHOD_CUSTOM,
  updateInvoiceBuilderData,
} from "../../state/features/invoiceBuilder/invoiceBuilderSlice";
import { selectInvoiceBuilderState } from "../../state/features/invoiceBuilder/invoiceBuilderSelector";
import generateDefaultLineItem from "../../helpers/utils/generateDefaultLineItem";
import { BuilderStickyButtonWrapper } from "./BuilderStyles";

const getDateTimeFormat = (givenDate: string) => {
  // Specific date in "dd-mm-yyyy" format
  const [year, month, day] = givenDate.split("-").map(Number);

  // Specific hour and minute
  const specificHour = new Date().getHours();
  const specificMinute = new Date().getMinutes();

  // Create a new date object with the specific date
  let specificDate = new Date(year, month - 1, day); // Note: month is zero-based

  // Set the specific hour and minute
  specificDate = setHours(specificDate, specificHour);
  specificDate = setMinutes(specificDate, specificMinute);

  return specificDate;
};

type InvoiceUseParams = {
  invoiceId: string;
};

const InvoiceBody: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { invoiceId } = useParams<InvoiceUseParams>();

  const {
    data: {
      client,
      id,
      orderId,
      invoiceNumber,
      invoiceDate,
      dueDate,
      logoOfCompany,
      useSystemDefaultLogo,
      items = [],
      totalAmountOfInvoice,
      shippingCharge,
      discount,
      taxes,
      taxPercentage,
      taxName,
      note,
      termsAndCondition,
      settings,
      invoiceType,
      paymentMethod,
      repeatSettings,
      startDate,
      endDateStatus,
      endDate,
      paymentTerms,
    },
    isUpdating,
    isFetchedSuccess,
    error,
  } = useSelector(selectInvoiceBuilderState);
  const { logo: systemDefaultLogo } = useSelector(selectDefaultInfoData);

  const methods = useForm<InvoiceInterface>({
    mode: "onSubmit",
    defaultValues: {
      id,
      client,
      dueDate,
      invoiceDate,
      logoOfCompany,
      useSystemDefaultLogo,
      items: [],
      totalAmountOfInvoice: 0,
      shippingCharge: 0,
      discount: 0,
      taxes: 0,
      paymentMethod,
      note,
      invoiceType,
      termsAndCondition,
      settings: {
        template: TEMPLATE_TYPE.DEFAULT,
        clientCanSee: Object.values(CLIENT_CAN_SEE_KEY),
      },
    },
  });

  const { setValue, setError, handleSubmit } = methods || {};

  useEffect(() => {
    // ## After load client data
    if (isFetchedSuccess && client && client.id) {
      const { contactId, fullName, email, number, billingAddress, shippingAddress } = client;
      setValue("client.id", contactId);
      setValue("client.contactId", contactId);
      setValue("client.fullName", fullName);
      setValue("client.email", email);
      setValue("client.number", number);
      setValue("client.billingAddress", billingAddress || {});
      setValue("client.shippingAddress", shippingAddress || {});
    }
    if (isFetchedSuccess) {
      setValue("orderId", orderId);
      setValue("invoiceNumber", invoiceNumber || "");
      if (invoiceDate) setValue("invoiceDate", format(new Date(invoiceDate), "yyyy-MM-dd"));
      if (dueDate) setValue("dueDate", format(new Date(dueDate), "yyyy-MM-dd"));

      if (Array.isArray(items) && items.length > 0) {
        setValue("items", items);
      } else {
        setValue("items", [generateDefaultLineItem()]);
      }

      setValue("discount", discount ?? 0);
      setValue("taxes", taxes ?? 0);
      setValue("taxPercentage", taxPercentage ?? 0);
      setValue("taxName", taxName);
      setValue("shippingCharge", shippingCharge ?? 0);
      setValue("totalAmountOfInvoice", totalAmountOfInvoice ?? 0);

      if (settings?.clientCanSee) setValue("settings.clientCanSee", settings.clientCanSee);
      if (settings?.template) setValue("settings.template", settings.template);

      setValue("paymentMethod", paymentMethod);
      setValue("note", note);
      setValue("termsAndCondition", termsAndCondition);

      // repeat settings
      if (repeatSettings?.recurringCycle) {
        setValue("repeatSettings", repeatSettings);
      }
      if (typeof paymentTerms === "object") {
        setValue("paymentTerms", paymentTerms);
      }
      if (startDate) setValue("startDate", format(new Date(startDate), "yyyy-MM-dd"));
      if (endDate) setValue("endDate", format(new Date(endDate), "yyyy-MM-dd"));
      setValue("endDateStatus", endDateStatus);
    }
  }, [isFetchedSuccess]);

  useEffect(() => {
    if (isFetchedSuccess) {
      setValue("logoOfCompany", logoOfCompany ? logoOfCompany : systemDefaultLogo);
    }
  }, [isFetchedSuccess, systemDefaultLogo]);

  useEffect(() => {
    if (error) {
      if (error === "Due date must be higher than the current date.") {
        if (invoiceType == INVOICE_TYPE.ONE_TIME) {
          setError("dueDate", { type: "custom", message: "Due date must be higher than the current date" });
        } else {
          setError("endDate", { type: "custom", message: "End date must be higher than the current date" });
        }
      }
    }
  }, [error]);

  const onSubmitHandler = (data: InvoiceInterface) => {
    data.id = +invoiceId as number;
    data.invoiceType = invoiceType;
    data.useSystemDefaultLogo = Number(data.useSystemDefaultLogo);
    if (invoiceType === INVOICE_TYPE.ONE_TIME && data.dueDate) {
      if (typeof data.dueDate === "string") data.dueDate = getDateTimeFormat(data.dueDate);
      if (typeof data.invoiceDate === "string") data.invoiceDate = getDateTimeFormat(data.invoiceDate);
      if (typeof data.startDate === "string") data.startDate = getDateTimeFormat(data.startDate);
      if (typeof data.endDate === "string") data.endDate = getDateTimeFormat(data.endDate);
    } else {
      if (typeof data.startDate === "string") data.startDate = getDateTimeFormat(data.startDate);
      if (typeof data.endDate === "string") data.endDate = getDateTimeFormat(data.endDate);
    }

    if (!Array.isArray(data.items) || data.items?.length === 0) {
      showErrorToaster("No invoice items added yet!");
      return;
    }

    // ## items properties
    data.items = data.items?.map((item) => {
      const description = item.description ? item.description : "";
      const { quantity, price } = item || {};
      const amount = quantity * price;
      return { ...item, totalPrice: amount, description };
    });

    // get items value when change input value
    const discountValue = data.discount || 0;
    const shippingChargeValue = data.shippingCharge || 0;
    const taxPercentage = data.taxPercentage || 0;

    const subTotalAmount = data.items?.reduce((previousValue, currentValue) => {
      const amount = Number(currentValue.quantity || 0) * Number(currentValue.price || 0);
      return previousValue + amount;
    }, 0);

    const subTotalAfterSubtractionDiscount = subTotalAmount - discountValue;
    const totalTax = (subTotalAfterSubtractionDiscount * taxPercentage) / 100;
    const totalAmount = subTotalAfterSubtractionDiscount + totalTax + shippingChargeValue;

    data.subTotal = subTotalAmount;
    data.discount = discountValue;
    data.shippingCharge = shippingChargeValue;
    data.taxPercentage = taxPercentage;
    data.taxes = totalTax;
    data.totalAmountOfInvoice = Number(totalAmount.toFixed(2));

    data.paymentMethods = data.paymentMethod ? [data.paymentMethod] : [PAYMENT_METHOD_CUSTOM];

    // now this feature is off. so, we activated all the key
    data.settings.clientCanSee = Object.values(CLIENT_CAN_SEE_KEY);
    dispatch(updateInvoiceBuilderData(data));
  };

  return (
    <FormProvider {...methods}>
      <Box component='form' onSubmit={handleSubmit(onSubmitHandler)} sx={{ position: "relative" }}>
        <Stack spacing={2}>
          <Paper elevation={0}>
            <InvoiceHeaderContent />
          </Paper>
          <Paper elevation={0}>
            <InvoiceItemsBody />
          </Paper>
          <Paper elevation={0}>
            <InvoiceFooterContent />
          </Paper>
        </Stack>

        <BuilderStickyButtonWrapper direction='row' justifyContent='flex-end' spacing={2}>
          <LoadingButton isLoading={isUpdating} type='submit' variant='contained' size='large' color='secondary'>
            Next
          </LoadingButton>
        </BuilderStickyButtonWrapper>
      </Box>
    </FormProvider>
  );
};

export default InvoiceBody;
