import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendSMSPayload, SMSTemplateInterface } from "./sms.interface";

const initialState = {
  data: {} as SMSTemplateInterface,
  isSuccess: false,
  isLoading: false,
  isError: false,
  error: "",
  isSMSSent: false,
};

const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {
    getSMSTemplateData: (state) => {},
    getSMSTemplateDataSuccess: (state, action: PayloadAction<SMSTemplateInterface>) => {
      state.data = action.payload;
      state.isSuccess = true;
    },

    sendSms: (state, action: PayloadAction<SendSMSPayload>) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    },

    sendSmsSuccess: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.isSMSSent = true;
    },

    sendSmsFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetSMSState: (state) => {
      state.isSMSSent = false;
      state.isError = true;
      state.error = "";
    },
  },
});

export const { getSMSTemplateData, getSMSTemplateDataSuccess, sendSms, sendSmsSuccess, sendSmsFailed, resetSMSState } =
  smsSlice.actions;
export default smsSlice.reducer;
