import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Close, PersonAdd } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddressDrawer from "./AddressDrawer";
import CoreButton from "../../../common/Button/CoreButton";
import BasicDrawer from "../../../common/Drawer/BasicDrawer";
import CoreTextField from "../../../common/TextField/CoreTextField";
import CustomImageUploadWrapper from "../../../common/FileUpload/CustomImageUploadWrapper";
import useBoolean from "../../../hooks/useBoolean";
import { AppDispatch } from "../../../state/store";
import getFullName from "../../../helpers/utils/getFullName";
import { registerInvoiceBuilder } from "../../../helpers/constant/registerPattern";
import { getContacts } from "../../../state/features/contact/contactSlice";
import { selectContactState } from "../../../state/features/contact/contactSelector";
import { ContactInterface } from "../../../state/features/contact/contactInterfaces";
import { InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";
import { selectInvoiceBuilderState } from "../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { AddressBox, ContactStack, StyledInputLabel, StyledStack } from "./HeaderStyles";
import { INVOICE_TYPE } from "../../../helpers/constant/invoiceConstant";
import InvoiceRecurringSettings from "./InvoiceRecurringSettings";
import generateAddress from "../../../helpers/utils/generateAddress";

const InvoiceHeaderContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { invoiceType },
  } = useSelector(selectInvoiceBuilderState);
  const { data: contactList, isLoading, perPage } = useSelector(selectContactState);
  const [imageUploading, setImageUploading] = useState(false);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<InvoiceInterface>();

  const { value: openBillingDrawer, toggle: toggleOpenBillingDrawer } = useBoolean(false);
  const { value: openShippingDrawer, toggle: toggleOpenShippingDrawer } = useBoolean(false);

  const [inputValue, setInputValue] = React.useState("");

  const onChangeSearchInput = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value);
    dispatch(getContacts({ search: value, size: perPage, page: 1 }));
  };

  const onFocus = () => {
    dispatch(getContacts({ size: perPage, page: 1 }));
  };

  const handleCustomerSelect = (event: React.SyntheticEvent<Element, Event>, data: ContactInterface | null) => {
    if (data) {
      const { id, firstName, lastName, email, number, address, city, country, state, zip } = data;

      setValue("client", {
        id,
        contactId: id,
        email,
        number,
        fullName: getFullName(firstName, lastName),
        firstName: firstName,
        lastName: lastName,
        billingAddress: { address, city, state, zip, country },
        shippingAddress: { address, city, state, zip, country },
      });
    }
  };

  const handleAddContact = () => {
    window.open("/contacts", "_blank");
  };

  return (
    <>
      <Stack spacing={3} p={3}>
        <Stack direction='row' alignItems='center' gap={1}>
          <input type='hidden' {...register("logoOfCompany")} />
          <StyledStack width={300}>
            {getValues("logoOfCompany") && <img width={300} src={getValues("logoOfCompany")} alt={"profile logo"} />}
            <CustomImageUploadWrapper
              onImageUpload={(image) => setValue("logoOfCompany", image)}
              onUploading={setImageUploading}
              maxFileSizeMB={2}
            >
              <IconButton size='small' color='secondary'>
                {imageUploading ? <CircularProgress size={16} color={"inherit"} /> : <EditIcon sx={{ fontSize: 16 }} />}
              </IconButton>
            </CustomImageUploadWrapper>
          </StyledStack>
        </Stack>

        <Box sx={{ flex: 1 }}>
          {/*## client search and add section ## */}
          <Stack direction={"column"} spacing={1} sx={{ mb: 1 }}>
            <Stack direction='row' spacing={1} sx={{ width: "100%" }}>
              <Autocomplete
                fullWidth
                size='small'
                popupIcon={<SearchIcon />}
                onFocus={onFocus}
                loading={isLoading}
                onChange={handleCustomerSelect}
                inputValue={inputValue}
                onInputChange={onChangeSearchInput}
                options={contactList}
                filterOptions={(option) => option}
                getOptionLabel={(option) => ""}
                sx={{ "& .MuiAutocomplete-popupIndicator": { transform: "none" } }}
                renderOption={(props, option) => {
                  const fullName = getFullName(option.firstName, option.lastName);
                  return (
                    <Box {...props} key={option.id} component='li' sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                      <Avatar sx={{ width: 30, height: 30 }} />
                      <Stack sx={{ ml: 1 }}>
                        {fullName && (
                          <Typography variant='body2' color={"text.primary"}>
                            {fullName}
                          </Typography>
                        )}
                        {option.email && (
                          <Typography variant='caption' color='text.secondary'>
                            {option.email}
                          </Typography>
                        )}
                        {option.number && (
                          <Typography variant='caption' color='text.secondary'>
                            {option.number}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <CoreTextField
                    {...params}
                    color={"secondary"}
                    placeholder='Type here to search for a customer'
                    autoComplete={"off"}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                    className={"ib_editor_light_textField"}
                    sx={{ "& .MuiOutlinedInput-root": { padding: "0 !important" } }}
                  />
                )}
              />

              <CoreButton
                variant='contained'
                color='secondary'
                size={"small"}
                startIcon={<PersonAdd />}
                onClick={handleAddContact}
                sx={{ minWidth: "max-content" }}
              >
                Add Contact
              </CoreButton>
            </Stack>

            <input
              type='text'
              style={{ width: 0, height: 0, border: 0 }}
              {...register("client.id", { required: "Please search for your client here to add them to the invoice." })}
            />
            {errors?.client?.id?.message && !getValues(`client.id`) && (
              <FormHelperText error={true}>{errors.client.id.message}</FormHelperText>
            )}

            {getValues("client.id") && (
              <ContactStack>
                <Avatar sx={{ width: 30, height: 30 }} />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ ml: 1, width: "100%" }}
                >
                  <div>
                    {getValues("client.firstName") && (
                      <Typography variant='body2'>
                        {getFullName(getValues("client.firstName"), getValues("client.lastName"))}
                      </Typography>
                    )}
                    {getValues("client.email") && (
                      <Typography component={"p"} variant='caption' color='text.secondary'>
                        {getValues("client.email")}
                      </Typography>
                    )}
                    {getValues("client.number") && (
                      <Typography component={"p"} variant='caption' color='text.secondary'>
                        {getValues("client.number")}
                      </Typography>
                    )}
                  </div>

                  <IconButton size={"small"} onClick={() => setValue("client", null)}>
                    <Close fontSize={"small"} />
                  </IconButton>
                </Stack>
              </ContactStack>
            )}
          </Stack>

          <Stack direction={"row"} justifyContent={"space-between"} spacing={3}>
            <Box sx={{ display: getValues("client.id") ? "block" : "none", width: "100%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <StyledInputLabel>Billing Address </StyledInputLabel>
                <IconButton size='small' onClick={toggleOpenBillingDrawer}>
                  <EditIcon color='action' fontSize={"small"} />
                </IconButton>
              </Stack>
              <AddressBox variant={"outlined"}>
                <Typography variant={"body2"} fontWeight={300}>
                  {getValues("client.billingAddress.address")}
                </Typography>
                <Typography variant={"body2"} fontWeight={300}>
                  {generateAddress([getValues("client.billingAddress.city"), getValues("client.billingAddress.state")])}{" "}
                  {getValues("client.billingAddress.zip")}
                </Typography>
                <Typography variant={"body2"} fontWeight={300} textTransform={"capitalize"}>
                  {getValues("client.billingAddress.country")}
                </Typography>
              </AddressBox>
            </Box>

            <Box sx={{ display: getValues("client.id") ? "block" : "none", width: "100%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <StyledInputLabel>Shipping Address</StyledInputLabel>
                <IconButton size='small' onClick={toggleOpenShippingDrawer}>
                  <EditIcon color='action' fontSize={"small"} />
                </IconButton>
              </Stack>
              <AddressBox variant={"outlined"}>
                <Typography variant={"body2"} fontWeight={300}>
                  {getValues("client.shippingAddress.address")}
                </Typography>
                <Typography variant={"body2"} fontWeight={300}>
                  {generateAddress([
                    getValues("client.shippingAddress.city"),
                    getValues("client.shippingAddress.state"),
                  ])}{" "}
                  {getValues("client.shippingAddress.zip")}
                </Typography>
                <Typography variant={"body2"} fontWeight={300} textTransform={"capitalize"}>
                  {getValues("client.shippingAddress.country")}
                </Typography>
              </AddressBox>
            </Box>
          </Stack>
        </Box>

        <Box>
          <Stack direction={"row"} spacing={4} mb={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                minWidth={{ xs: 100, lg: 150 }}
              >
                <StyledInputLabel variant='standard'>Invoice Number</StyledInputLabel>
                <StyledInputLabel variant='standard'>:</StyledInputLabel>
              </Stack>

              <Stack flex={1}>
                <CoreTextField
                  size='small'
                  type='text'
                  color='secondary'
                  fullWidth
                  placeholder='Invoice Number'
                  disabled
                  error={!!(errors.invoiceNumber && errors.invoiceNumber.message)}
                  helperText={errors.invoiceNumber && errors.invoiceNumber.message}
                  {...register("invoiceNumber")}
                  className={"ib_editor_light_textField"}
                />
              </Stack>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                minWidth={{ xs: 100, lg: 150 }}
              >
                <StyledInputLabel variant='standard'>Order Number (Optional)</StyledInputLabel>
                <StyledInputLabel variant='standard'>:</StyledInputLabel>
              </Stack>

              <Stack flex={1}>
                <CoreTextField
                  size='small'
                  type='text'
                  color='secondary'
                  fullWidth
                  placeholder='Order Number'
                  error={!!(errors.orderId && errors.orderId.message)}
                  helperText={errors.orderId && errors.orderId.message}
                  {...register("orderId", registerInvoiceBuilder.orderId)}
                  className={"ib_editor_light_textField"}
                />
              </Stack>
            </Stack>
          </Stack>

          {invoiceType === INVOICE_TYPE.ONE_TIME && (
            <Stack direction={"row"} spacing={4} mb={2}>
              <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  minWidth={{ xs: 100, lg: 150 }}
                >
                  <StyledInputLabel
                    variant='standard'
                    required
                    error={!!(errors.invoiceDate && errors.invoiceDate.message)}
                  >
                    Invoice Date
                  </StyledInputLabel>
                  <StyledInputLabel variant='standard'>:</StyledInputLabel>
                </Stack>

                <Stack flex={1}>
                  <CoreTextField
                    size='small'
                    type='date'
                    color='secondary'
                    fullWidth
                    error={!!(errors.invoiceDate && errors.invoiceDate.message)}
                    helperText={errors.invoiceDate && errors.invoiceDate.message}
                    {...register("invoiceDate", registerInvoiceBuilder.invoiceDate(getValues("dueDate")))}
                    className={"ib_editor_light_textField"}
                  />
                </Stack>
              </Stack>

              <Stack direction={"row"} alignItems={"center"} spacing={2} flex={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  minWidth={{ xs: 100, lg: 150 }}
                >
                  <StyledInputLabel variant='standard' required error={!!(errors.dueDate && errors.dueDate.message)}>
                    Due Date
                  </StyledInputLabel>
                  <StyledInputLabel variant='standard'>:</StyledInputLabel>
                </Stack>

                <Stack flex={1}>
                  <CoreTextField
                    size='small'
                    type='date'
                    color='secondary'
                    fullWidth
                    error={!!(errors.dueDate && errors.dueDate.message)}
                    helperText={errors.dueDate && errors.dueDate.message}
                    {...register("dueDate", registerInvoiceBuilder.dueDate)}
                    className={"ib_editor_light_textField"}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}

          {invoiceType === INVOICE_TYPE.RECURRING && <InvoiceRecurringSettings />}
        </Box>
      </Stack>

      <BasicDrawer width={500} open={openBillingDrawer} title='Billing Address' toggleDrawer={toggleOpenBillingDrawer}>
        <AddressDrawer onClose={toggleOpenBillingDrawer} getValues={getValues} setValue={setValue} />
      </BasicDrawer>

      <BasicDrawer
        width={500}
        open={openShippingDrawer}
        title='Shipping Address'
        toggleDrawer={toggleOpenShippingDrawer}
      >
        <AddressDrawer onClose={toggleOpenShippingDrawer} getValues={getValues} setValue={setValue} isShippingAddress />
      </BasicDrawer>
    </>
  );
};

export default InvoiceHeaderContent;
