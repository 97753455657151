import { RootState } from "../../store";
import { PAYMENT_METHOD } from "../invoiceBuilder/invoiceBuilderInterface";
import Stripe from "../../../components/Builder/InvoicePayment/images/stripe.png";
import Paypal from "../../../components/Builder/InvoicePayment/images/paypal.png";
import Authorize from "../../../components/Builder/InvoicePayment/images/authorize.png";
import CustomPayment from "../../../components/Builder/InvoicePayment/images/customPayment.png";
import { PAYMENT_METHOD_CUSTOM } from "../invoiceBuilder/invoiceBuilderSlice";

const PAYMENT_ICON_BY_TYPES = {
  [PAYMENT_METHOD.stripe]: Stripe,
  [PAYMENT_METHOD.paypal]: Paypal,
  [PAYMENT_METHOD.authorizeDotNet]: Authorize,
};

export const selectPaymentListState = (state: RootState) => state.paymentList;

export const selectFormattedPaymentList = (state: RootState) => {
  const paymentList = [
    {
      label: "Custom Payment",
      value: PAYMENT_METHOD_CUSTOM,
      icon: CustomPayment,
    },
  ];
  (state.paymentList.data || []).forEach(({ id, paymentName, type }) => {
    paymentList.push({ label: paymentName, value: id, icon: PAYMENT_ICON_BY_TYPES[type] || "" });
  });

  return paymentList;
};
