import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alpha, Avatar, Box, Grid, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import formatCurrency from "../../helpers/utils/formatCurrency";
import { getInvoiceStateData } from "../../state/features/invoiceState/invoiceStateSlice";
import { selectStateOfInvoice } from "../../state/features/invoiceState/invoiceStateSelector";

const InvoiceOverviewReport = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { data, isLoading } = useSelector(selectStateOfInvoice);
  const {
    totalDueAmount = 0,
    totalDueInvoice = 0,
    totalPaidAmount = 0,
    totalPaidInvoice = 0,
    totalOutstandingAmount = 0,
    totalOutstandingInvoice = 0,
  } = data || {};

  useEffect(() => {
    dispatch(getInvoiceStateData());
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Paper elevation={0} sx={{ p: 2, px: 3 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar sx={{ bgcolor: "other.secondaryHover" }}>
                <PendingActionsIcon color={"secondary"} />
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography variant={"overline"} color={"secondary.main"} fontSize={16}>
                    outstanding
                  </Typography>
                  <Typography component={"span"} variant={"h5"} color={"text.secondary"}>
                    {isLoading ? <Skeleton width={50} /> : totalOutstandingInvoice}
                  </Typography>
                </Stack>
                <Typography variant={"h5"} color={"text.secondary"}>
                  {isLoading ? <Skeleton width={100} /> : formatCurrency(totalOutstandingAmount, "$")}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={6} md={4}>
          <Paper elevation={0} sx={{ p: 2, px: 3 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar sx={{ bgcolor: alpha(theme.palette.error.main, 0.1) }}>
                <EventBusyIcon color={"error"} />
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography variant={"overline"} color={"error.main"} fontSize={16}>
                    past due
                  </Typography>
                  <Typography component={"span"} variant={"h5"} color={"text.secondary"}>
                    {isLoading ? <Skeleton width={50} /> : totalDueInvoice}
                  </Typography>
                </Stack>
                <Typography variant={"h5"} color={"text.secondary"}>
                  {isLoading ? <Skeleton width={100} /> : formatCurrency(totalDueAmount, "$")}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={6} md={4}>
          <Paper elevation={0} sx={{ p: 2, px: 3 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar sx={{ bgcolor: alpha(theme.palette.success.main, 0.1) }}>
                <MonetizationOnOutlinedIcon color={"success"} />
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography variant={"overline"} color={"success.main"} fontSize={16}>
                    paid
                  </Typography>
                  <Typography component={"span"} variant={"h5"} color={"text.secondary"}>
                    {isLoading ? <Skeleton width={50} /> : totalPaidInvoice}
                  </Typography>
                </Stack>
                <Typography variant={"h5"} color={"text.secondary"}>
                  {isLoading ? <Skeleton width={100} /> : formatCurrency(totalPaidAmount, "$")}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceOverviewReport;
