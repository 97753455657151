import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, styled, Typography } from "@mui/material";
import EmailText from "../../../../common/FormatText/EmailText";
import TelephoneText from "../../../../common/FormatText/TelephoneText";
import getFullName from "../../../../helpers/utils/getFullName";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import generateAddress from "../../../../helpers/utils/generateAddress";
import addressView from "../../../../helpers/utils/addressView";

const LogoBox = styled(Box)(({ theme }) => ({
  width: "210px",
  height: "80px",
  "& img": {
    width: "auto",
    maxWidth: "100%",
    height: "100%",
    objectFit: "contain",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90px",
  },
}));

const InvoiceHeaderBasic: React.FC = () => {
  const { data } = useSelector(selectInvoiceBuilderState);
  const { logoOfCompany, client } = data || {};

  const billingAddress = data.client && addressView(data.client.billingAddress);
  const shippingAddress = data.client && addressView(data.client.shippingAddress);

  return (
    <Box px={10}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant={"h2"} color={"other.grayDark"}>
          INVOICE
        </Typography>
        <Stack sx={{ textAlign: "right", alignItems: "flex-end" }}>
          <LogoBox>{logoOfCompany && <img src={logoOfCompany} alt={"branding logo"} />}</LogoBox>
          <Typography variant={"body1SemiBold"} color={"other.grayDark"}>
            {client && getFullName(client.firstName, client.lastName)}
          </Typography>
          <Typography variant={"caption"} color={"other.grayDark"}>
            {client && <EmailText email={client.email} />}
          </Typography>
          <Typography variant={"caption"} color={"other.grayDark"}>
            {client && <TelephoneText phoneNumber={client.number} />}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"} mt={4}>
        {client?.billingAddress?.address || billingAddress ? (
          <Stack>
            <Typography variant={"body2"} color={"other.grayDark"} sx={{ marginBottom: "10px" }}>
              BILL TO:
            </Typography>
            {client?.billingAddress?.address && (
              <Typography variant={"caption"} color={"other.grayDark"}>
                {client?.billingAddress?.address}
              </Typography>
            )}
            {(client?.billingAddress?.city || client?.billingAddress?.state || client?.billingAddress?.zip) && (
              <Typography variant={"caption"} color={"other.grayDark"}>
                {generateAddress([client.billingAddress.city, client.billingAddress.state])} {client.billingAddress.zip}
              </Typography>
            )}
            {client?.billingAddress?.country && (
              <Typography variant={"caption"} color={"other.grayDark"} textTransform={"capitalize"}>
                {client.billingAddress.country}
              </Typography>
            )}
          </Stack>
        ) : (
          <div></div>
        )}

        {(client?.shippingAddress?.address || shippingAddress) && (
          <Stack alignItems='end'>
            <Typography variant={"body2"} color={"other.grayDark"} sx={{ marginBottom: "10px" }}>
              SHIP TO:
            </Typography>
            {client?.shippingAddress?.address && (
              <Typography variant={"caption"} color={"other.grayDark"}>
                {client?.shippingAddress?.address}
              </Typography>
            )}
            {(client?.shippingAddress?.city || client?.shippingAddress?.state || client?.shippingAddress?.zip) && (
              <Typography variant={"caption"} color={"other.grayDark"}>
                {generateAddress([client.shippingAddress.city, client.shippingAddress.state])}{" "}
                {client.shippingAddress.zip}
              </Typography>
            )}
            {client?.shippingAddress?.country && (
              <Typography variant={"caption"} color={"other.grayDark"} textTransform={"capitalize"}>
                {client.shippingAddress.country}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default InvoiceHeaderBasic;
