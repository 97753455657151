import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Control, Controller, FieldError, RegisterOptions } from "react-hook-form";
import { FormHelperText, Typography } from "@mui/material";
import { StyledSelect } from "../../../common/Select/StyledSelect";
import CoreInputLabel from "../../../common/TextField/CoreInputLabel";
import { ItemInterface } from "../../../state/features/item/item.interface";

interface OptionInterface {
  label: string;
  value: string;
}

interface Props {
  options: OptionInterface[];
  isError?: boolean;
  err?: FieldError;
  control: Control<ItemInterface, unknown>;
  rules?: Omit<RegisterOptions<ItemInterface, keyof ItemInterface>, "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs">;
  label?: string;
  name: string;
  helperText?: string;
  placeholder?: string;
  required?: boolean;
}

const CustomSelect: React.FC<Props> = ({ options, label, placeholder, required = false, control, name, rules }) => {
  return (
    <Controller
      control={control}
      name={name as keyof ItemInterface}
      rules={rules}
      render={({ field: { value, onChange }, field, fieldState: { error } }) => {
        return (
          <FormControl fullWidth size='small' error={error ? true : false} required={required}>
            <CoreInputLabel sx={{ WebkitTransform: "unset", mb: 0.5 }} variant='standard'>
              {label}
            </CoreInputLabel>
            <StyledSelect
              color='secondary'
              {...field}
              value={value ? value : ""}
              onChange={(event) => {
                const value = event.target.value as string;
                onChange(value);
              }}
              displayEmpty
            >
              <MenuItem disabled value=''>
                <Typography variant={"body1"} color={"text.secondary"}>
                  {placeholder}
                </Typography>
              </MenuItem>

              {options.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
            <FormHelperText sx={{ ml: 0 }}>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

CustomSelect.displayName = "CustomSelect";

export default CustomSelect;
