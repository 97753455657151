import { all, call, put, takeLatest } from "typed-redux-saga";
import PersonalizeTagService from "../../../services/personalizeTagService";
import { getPersonalizeTagsFailed, getPersonalizeTagsSuccess } from "./personalizeTagSlice";

function* personalizeTagWatcher() {
  yield takeLatest("personalizeTag/getPersonalizeTags", getPersonalizeTagsSaga);
}

function* getPersonalizeTagsSaga() {
  try {
    const response = yield* call(PersonalizeTagService.getPersonalizeTags);

    if (response.success) {
      yield put(getPersonalizeTagsSuccess(response.data));
    } else {
      yield put(getPersonalizeTagsFailed());
    }
  } catch (err) {
    console.log("Error:: ", "Network or server error");
  }
}

export default function* personalizeTagSaga() {
  yield all([personalizeTagWatcher()]);
}
