import React from "react";
import { BrowserRouter, Route, RouteComponentProps, Router, Switch } from "react-router-dom";
import InvoiceIndex from "./components/Invoice/InvoiceIndex";
import ErrorPage from "./common/ErrorPage";
import InvoiceBuilder from "./components/Builder/InvoiceBuilder";
import AllItems from "./components/Items/AllItems";
import Reports from "./components/Reports/Reports";
import ExportFile from "./components/ExportFiles/ExportFile";

interface Props {
  history?: RouteComponentProps["history"];
}

interface RouterWrapperProps {
  history?: RouteComponentProps["history"];
  children: React.ReactNode;
}

const RouterWrapper = ({ history, children }: RouterWrapperProps) => {
  return history ? <Router history={history}>{children}</Router> : <BrowserRouter>{children}</BrowserRouter>;
};

const AppRouters: React.FC<Props> = ({ history }) => {
  return (
    <RouterWrapper history={history}>
      <div>
        <Switch>
          <Route path='/ibuilder/invoices' component={InvoiceIndex} />
          <Route path={"/ibuilder/invoice/:invoiceId"} component={InvoiceBuilder} />
          <Route path='/ibuilder/items' component={AllItems} />
          <Route path='/ibuilder/reports' component={Reports} />
          <Route path='/ibuilder/export' component={ExportFile} />
          <Route path='/*' component={ErrorPage} />
        </Switch>
      </div>
    </RouterWrapper>
  );
};

export default AppRouters;
