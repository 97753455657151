import React, { useCallback, useState } from "react";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceLeftSide from "./InvoiceLeftSide";
import InvoiceListBody from "./InvoiceListBody";
import { InvoiceBuilderMainWrapper, InvoicesList } from "./InvoiceHomePageStyles";

const isHideLeftSideSaveValue: boolean = localStorage.getItem("hideLeftSidebarInvoice") === "true";

const InvoiceIndex: React.FC = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(isHideLeftSideSaveValue);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
      localStorage.setItem("hideLeftSidebarInvoice", JSON.stringify(!isHideLeftSide));
    } else {
      setIsHideLeftSide(false);
      localStorage.setItem("hideLeftSidebarInvoice", JSON.stringify(false));
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <InvoiceBuilderMainWrapper>
      <InvoiceHeader />

      <InvoicesList>
        <InvoiceLeftSide
          toggleLeftSide={toggleLeftSidePanel}
          isHideLeftSide={isHideLeftSide}
          isShowLeftSideTab={isShowLeftSideTab}
        />
        <InvoiceListBody />
      </InvoicesList>
    </InvoiceBuilderMainWrapper>
  );
};
export default InvoiceIndex;
