import React, { forwardRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import { styled, Typography } from "@mui/material";
import Select from "@mui/material/Select";

const PaymentSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "40px",
  "& .MuiOutlinedInput-input": {
    display: "flex",
    alignItems: "center",
    padding: "2px 14px",
    gap: "8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 350,
      width: "100%",
    },
  },
};

interface Props {
  value: number;
  options: {
    label: string;
    value: string | number;
    icon?: string;
  }[];
}

const PaymentOptions = forwardRef<null, Props>(({ value, options, ...props }, ref) => {
  return (
    <PaymentSelect ref={ref} {...props} size='small' color='secondary' defaultValue={value} MenuProps={MenuProps}>
      {options.map(({ label, value, icon }) => (
        <StyledMenuItem key={"key" + value} value={value}>
          {icon && <img height={"28px"} src={icon} alt='logo' />}
          <Typography variant={"body1"}>{label}</Typography>
        </StyledMenuItem>
      ))}
    </PaymentSelect>
  );
});

PaymentOptions.displayName = "PaymentOptions";

export default PaymentOptions;
