import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../state/store";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl, InputAdornment, SelectChangeEvent, Stack } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import { StyledSelect } from "../../common/Select/StyledSelect";
import CoreTextField from "../../common/TextField/CoreTextField";
import CoreInputLabel from "../../common/TextField/CoreInputLabel";
import { InvoiceListItemInterface } from "../../state/features/invoice/invoice.interface";
import { manuallyRecordPayment } from "../../helpers/validation/manuallyRecordPayment";
import { InvoicePaymentMode, InvoicePaymentModeList } from "../../helpers/constant/invoiceConstant";
import { ManuallyRecordPaymentInterface } from "../../interface/manuallyRecordPayment.interface";
import InvoiceService from "../../services/invoiceService";
import { showErrorToaster, showSuccessToaster } from "../../helpers/utils/toaster";
import { forceInvoiceRender } from "../../state/features/invoice/invoiceSlice";
import { getChildInvoices } from "../../state/features/childInvoice/childInvoiceSlice";

interface Props {
  onClose: () => void;
  deselectChildInvoiceInfo: () => void;
  invoiceInfo: InvoiceListItemInterface;
  childInvoiceId: number | null;
  childInvoiceAmount?: number;
}

const ManuallyRecordPaymentContent: React.FC<Props> = ({
  onClose,
  invoiceInfo,
  childInvoiceId,
  childInvoiceAmount,
  deselectChildInvoiceInfo,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [paymentMode, setPaymentMode] = useState<string>(InvoicePaymentMode.CASH);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ManuallyRecordPaymentInterface>();

  const onSubmit: SubmitHandler<ManuallyRecordPaymentInterface> = (data) => {
    data.invoiceId = Number(invoiceInfo?.id);
    data.paymentMode = paymentMode;
    if (childInvoiceId) {
      data.childInvoiceId = childInvoiceId as number;
    }

    setLoading(true);
    InvoiceService.manuallyRecordPayment(data)
      .then((res) => {
        if (res.success) {
          if (childInvoiceId) {
            dispatch(getChildInvoices({ invoiceId: Number(invoiceInfo?.id) }));
          } else dispatch(forceInvoiceRender());

          showSuccessToaster(res.message);
          onClose();
        } else {
          showErrorToaster(res.message);
        }
      })
      .catch((err) => showErrorToaster("Something went wrong!"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (childInvoiceId && childInvoiceAmount) {
      setValue("amount", childInvoiceAmount);
    } else {
      setValue("amount", invoiceInfo?.amount);
    }

    return () => {
      if (childInvoiceId) {
        deselectChildInvoiceInfo();
        dispatch(forceInvoiceRender());
      }
    };
  }, []);

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <FormControl size='small'>
          <CoreInputLabel sx={{ WebkitTransform: "unset", mb: 0.5 }} variant='standard'>
            How was this invoice paid?
          </CoreInputLabel>
          <StyledSelect
            displayEmpty
            size='small'
            color='secondary'
            value={paymentMode}
            onChange={(e: SelectChangeEvent<unknown>) => setPaymentMode(e.target.value as string)}
          >
            {InvoicePaymentModeList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        <FormControl fullWidth error={!!(errors.amount && errors.amount.message)} required>
          <CoreInputLabel variant='standard'>Amount</CoreInputLabel>
          <CoreTextField
            type='text'
            size='small'
            color='secondary'
            placeholder='Enter Amount'
            disabled
            error={!!(errors.amount && errors.amount.message)}
            helperText={errors.amount && typeof errors.amount?.message === "string" ? errors.amount.message : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  $
                </InputAdornment>
              ),
            }}
            {...register("amount", manuallyRecordPayment.amount)}
          />
        </FormControl>

        <FormControl fullWidth>
          <CoreInputLabel variant='standard'>Notes</CoreInputLabel>
          <CoreTextField
            multiline
            type='text'
            size='small'
            minRows={4}
            maxRows={6}
            color='secondary'
            {...register("note")}
          />
        </FormControl>
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton size='large' color='success' type='submit' isLoading={loading}>
          Record Payment
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ManuallyRecordPaymentContent;
