import React from "react";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import FolderIcon from "@mui/icons-material/Folder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Stack, Typography, useTheme } from "@mui/material";
import { BuilderHeaderAction, BuilderHeaderWrapper } from "./BuilderStyles";
import CustomBreadcrumb from "../../common/Breadcrumb/CustomBreadcrumb";
import { BUILDER_OPTIONS } from "../../helpers/constant/invoiceConstant";
import {
  selectInvoiceBuilderData,
  selectInvoiceBuilderState,
} from "../../state/features/invoiceBuilder/invoiceBuilderSelector";

interface Props {
  selectedOption: number;
  setSelectedOption: (option: number) => void;
}

const BuilderHeader: React.FC<Props> = ({ selectedOption, setSelectedOption }) => {
  const theme = useTheme();
  const { name } = useSelector(selectInvoiceBuilderData);
  const { updateTime } = useSelector(selectInvoiceBuilderState);

  return (
    <>
      <BuilderHeaderWrapper direction='row' justifyContent='space-between' alignItems='center'>
        <CustomBreadcrumb
          parentLocation='All Invoices'
          icon={<FolderIcon fontSize='small' sx={{ color: theme.palette.common.white }} />}
          color='common.white'
          currentLocation={name}
          parentUri='/ibuilder/invoices'
        />

        <Typography
          variant='chipLight'
          color='common.white'
          sx={{ display: "flex", alignItems: "flex-end", gap: "4px" }}
        >
          {updateTime && (
            <>
              {updateTime === "Saving..." ? <SyncIcon fontSize={"small"} /> : <CloudDoneIcon fontSize={"small"} />}
              {updateTime}
            </>
          )}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1.5}>
          <BuilderHeaderAction
            variant='contained'
            size='small'
            startIcon={<EditIcon color='inherit' />}
            selectedOption={selectedOption === BUILDER_OPTIONS.BUILDER}
            onClick={() => setSelectedOption(BUILDER_OPTIONS.BUILDER)}
          >
            Builder
          </BuilderHeaderAction>

          <BuilderHeaderAction
            variant='contained'
            size='small'
            startIcon={<VisibilityIcon color='inherit' />}
            selectedOption={selectedOption === BUILDER_OPTIONS.PREVIEW_AND_SEND}
            onClick={() => setSelectedOption(BUILDER_OPTIONS.PREVIEW_AND_SEND)}
          >
            Preview & Send
          </BuilderHeaderAction>

          {/*<BuilderHeaderAction*/}
          {/*  variant='contained'*/}
          {/*  size='small'*/}
          {/*  startIcon={<SettingsIcon color='inherit' />}*/}
          {/*  selectedOption={selectedOption === BUILDER_OPTIONS.SETTINGS}*/}
          {/*  onClick={() => setSelectedOption(BUILDER_OPTIONS.SETTINGS)}*/}
          {/*>*/}
          {/*  Settings*/}
          {/*</BuilderHeaderAction>*/}
        </Stack>
      </BuilderHeaderWrapper>
    </>
  );
};

export default BuilderHeader;
