import RecurringSettingInterface, { IUpdateRecurringSetting } from "../interface/recurringSettings.interface";
import httpRequest from "./api/httpRequest";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class RecurringSettingsService {
  getRecurringSettings(id: number) {
    return httpRequest.get<RecurringSettingInterface>(
      `${invoiceAPI}invoice-builder/invoice/payment/recurring-settings?invoiceId=${id}`
    );
  }

  updateRecurringSettingsData(payload: IUpdateRecurringSetting) {
    return httpRequest.put<RecurringSettingInterface>(
      `${invoiceAPI}invoice-builder/invoice/payment/recurring-settings/update`,
      payload
    );
  }
}

export default new RecurringSettingsService();
