import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import FolderIcon from "@mui/icons-material/Folder";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import Folder from "./Folder";
import CreateFolder from "./CreateFolder";
import SpecialFolderList from "./SpecialFolderList";
import HideSideBarIcon from "../../common/Icons/HideSideBarIcon";
import ShowSideBarIcon from "../../common/Icons/ShowSideBarIcon";
import FolderListSkeleton from "../../common/LoadingView/FolderListSkeleton";
import { FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderConstant";
import { AppDispatch } from "../../state/store";
import { selectFolderState } from "../../state/features/folder/folderSelector";
import { getFolders, getMoreFolders } from "../../state/features/folder/folderSlice";
import { selectFolderId } from "../../state/features/invoice/invoiceSlice";
import { selectInvoiceFilters } from "../../state/features/invoice/invoiceSelector";
import { AllFormsButton, CustomList, LeftSideBar, SidebarListWrapper, SideTopBarTab } from "./InvoiceHomePageStyles";

interface IProps {
  toggleLeftSide?: React.MouseEventHandler<HTMLButtonElement>;
  isHideLeftSide: boolean;
  isShowLeftSideTab: boolean;
}

const InvoiceLeftSide: React.FC<IProps> = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, currentPage, limit, hasMore } = useSelector(selectFolderState);
  const { folderId, isFavorite, isArchived, inTrash } = useSelector(selectInvoiceFilters);

  useEffect(() => {
    dispatch(getFolders({ limit: limit, currentPage: 1 }));
  }, []);

  const fetchMore = () => {
    const payload = {
      currentPage: currentPage + 1,
      limit: limit,
    };
    dispatch(getMoreFolders(payload));
  };

  const handleSelectFolder = () => {
    dispatch(selectFolderId(null));
  };

  return (
    <LeftSideBar className={isHideLeftSide ? "collapseLeftSide" : ""}>
      <Stack direction='row' alignItems='center' className='iSidebarTitleBar'>
        <IconButton disableRipple size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
          {isHideLeftSide || isShowLeftSideTab ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </IconButton>
        <Typography variant='body1' color='text.secondary' className='iSidebarTitle'>
          My Invoices
        </Typography>
      </Stack>
      <SidebarListWrapper px={1} hide_sidebar={isHideLeftSide} show_sidebar_tab={isShowLeftSideTab}>
        <SideTopBarTab>
          <Typography variant='body1' color='text.secondary'>
            My Invoices
          </Typography>
          <IconButton disableRipple size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
            <HideSideBarIcon />
          </IconButton>
        </SideTopBarTab>

        <AllFormsButton
          fullWidth
          variant='text'
          color='inherit'
          startIcon={<FolderIcon sx={{ color: FOLDER_DEFAULT_COLOR }} />}
          onClick={handleSelectFolder}
          isActive={!folderId && !(isFavorite || isArchived || inTrash)}
        >
          All Invoices
        </AllFormsButton>

        <CustomList className='ib_scroll_bar_light'>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<FolderListSkeleton count={8} />}
            scrollableTarget='ib_scroll_bar'
          >
            {data.map((folder, index) => {
              return <Folder key={index} folder={folder} selected={folder.id === folderId} />;
            })}
          </InfiniteScroll>
        </CustomList>

        <CreateFolder />
        <Divider light />
        <SpecialFolderList />
      </SidebarListWrapper>
    </LeftSideBar>
  );
};

export default InvoiceLeftSide;
