import React from "react";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import CoreTextField from "../../common/TextField/CoreTextField";
import { debounceHandler } from "../../helpers/utils/debounceHandler";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { addFilterItemSearchText } from "../../state/features/item/itemSlice";

const ItemsListFilterSearch: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <CoreTextField
      fullWidth
      size={"small"}
      type={"search"}
      color={"secondary"}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ mr: 0 }} position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
      placeholder={"Search Items"}
      onChange={debounceHandler((event) => {
        dispatch(addFilterItemSearchText(event.target.value));
      }, 1000)}
    />
  );
};

export default ItemsListFilterSearch;
