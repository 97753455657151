import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { StyledSelect } from "../../../common/Select/StyledSelect";
import { InvoiceOrderByList } from "../../../helpers/constant/invoiceConstant";
import { AppDispatch } from "../../../state/store";
import { addInvoiceOrderByFilter } from "../../../state/features/invoice/invoiceSlice";
import { selectInvoiceFilters } from "../../../state/features/invoice/invoiceSelector";

const InvoiceListFilterStatus: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { orderBy = "" } = useSelector(selectInvoiceFilters);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(addInvoiceOrderByFilter(event.target.value as string));
  };

  return (
    <StyledSelect size='small' color='secondary' value={orderBy} onChange={handleChange} sx={{ width: "200px" }}>
      {InvoiceOrderByList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default InvoiceListFilterStatus;
