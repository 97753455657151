import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import TitleAndValue from "./TitleAndValue";
import { StyledStack, SummeryHeading } from "../DefaultTemplateStyles";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import getInvoiceDateAndDueDate from "../../../../helpers/utils/getInvoiceDateAndDueDate";
import generateAddress from "../../../../helpers/utils/generateAddress";
import numberToDollar from "../../../../helpers/utils/numberToDollar";
import addressView from "../../../../helpers/utils/addressView";

const InvoiceSummery: React.FC = () => {
  const theme = useTheme();
  const { data } = useSelector(selectInvoiceBuilderState);
  const { client, invoiceDate, dueDate, invoiceNumber, orderId, invoiceType, startDate, paymentTerms } = data || {};

  const billingAddress = client && addressView(client.billingAddress);
  const shippingAddress = client && addressView(client.shippingAddress);

  const { formattedInvoiceDate, formattedDueDate } = getInvoiceDateAndDueDate(
    invoiceType,
    invoiceDate,
    dueDate,
    startDate,
    paymentTerms
  );

  return (
    <Box>
      <SummeryHeading>
        <Typography variant={"h4"} color={"secondary.main"}>
          Invoice
        </Typography>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant={"body2"} color={"secondary.main"}>
            Balance due:
          </Typography>
          <Typography variant={"h6"} color={"secondary.main"}>
            {numberToDollar(data.totalAmountOfInvoice)}
          </Typography>
        </Box>
      </SummeryHeading>

      <Stack direction={"row"} sx={{ width: "100%", gap: "2px", mt: 2 }}>
        {(client?.billingAddress?.address || billingAddress) && (
          <StyledStack background={theme.palette.other.outlinedBorder}>
            <Typography variant={"subtitle2"} color={"text.primary"}>
              BILL TO:
            </Typography>

            {client?.billingAddress?.address && (
              <Typography variant={"body2"} color={"text.primary"}>
                {client?.billingAddress?.address}
              </Typography>
            )}
            {(client?.billingAddress?.city || client?.billingAddress?.state || client?.billingAddress?.zip) && (
              <Typography variant={"body2"} color={"text.primary"}>
                {generateAddress([client.billingAddress.city, client.billingAddress.state])} {client.billingAddress.zip}
              </Typography>
            )}
            {client?.billingAddress?.country && (
              <Typography variant={"body2"} color={"text.primary"} textTransform={"capitalize"}>
                {client.billingAddress.country}
              </Typography>
            )}
          </StyledStack>
        )}
        {(client?.shippingAddress?.address || shippingAddress) && (
          <StyledStack background={theme.palette.other.secondaryHover}>
            <Typography variant={"subtitle2"} color={"text.primary"}>
              SHIP TO:
            </Typography>
            {client?.shippingAddress?.address && (
              <Typography variant={"body2"} color={"text.primary"}>
                {client?.shippingAddress?.address}
              </Typography>
            )}
            {(client?.shippingAddress?.city || client?.shippingAddress?.state || client?.shippingAddress?.zip) && (
              <Typography variant={"body2"} color={"text.primary"}>
                {generateAddress([client.shippingAddress.city, client.shippingAddress.state])}{" "}
                {client.shippingAddress.zip}
              </Typography>
            )}
            {client?.shippingAddress?.country && (
              <Typography variant={"body2"} color={"text.primary"} textTransform={"capitalize"}>
                {client.shippingAddress.country}
              </Typography>
            )}
          </StyledStack>
        )}
        <StyledStack background={theme.palette.other.secondaryHover} spacing={2}>
          <TitleAndValue title={"Invoice Number:"} value={invoiceNumber} color={"text.primary"} />
          <TitleAndValue title={"Order Number:"} value={orderId ? orderId : "-"} color={"text.primary"} />
        </StyledStack>
        <StyledStack background={theme.palette.secondary.main} spacing={2}>
          <TitleAndValue title={"Invoice Date:"} value={formattedInvoiceDate} color={"common.white"} />
          <TitleAndValue title={"Due Date:"} value={formattedDueDate} color={"common.white"} />
        </StyledStack>
      </Stack>
    </Box>
  );
};

export default InvoiceSummery;
