import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, ListItemIcon, ListItemText } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOrEditFolderName from "./AddOrEditFolderName";
import { ChangeColorText, ColorItemBox, SideBarListItem, StyledListItemButton } from "./InvoiceHomePageStyles";
import AddOrEditInvoiceName from "./AddOrEditInvoiceName";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import FolderIcon from "../../common/Icons/FolderIcon";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../hooks/useBoolean";
import { FOLDER_COLORS, FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderConstant";
import { AppDispatch } from "../../state/store";
import {
  deleteFolder,
  resetDeleteFolderSuccess,
  resetUpdateFolderSuccess,
  updateFolder,
} from "../../state/features/folder/folderSlice";
import { IFolder } from "../../state/features/folder/folder.interface";
import { selectFolderId } from "../../state/features/invoice/invoiceSlice";
import { selectFolderState } from "../../state/features/folder/folderSelector";

interface IProps {
  folder: IFolder;
  selected?: boolean;
}

const Folder: React.FC<IProps> = ({ folder, selected }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isActive, setIsActive] = useState(false);

  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const {
    value: openAddInvoiceModal,
    setTrue: onOpenAddInvoiceModal,
    setFalse: onCloseAddInvoiceModal,
  } = useBoolean(false);

  const { isUpdateSuccess, isDeleteSuccess, isDeleting } = useSelector(selectFolderState);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(resetUpdateFolderSuccess());
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(resetDeleteFolderSuccess());
      onCloseDeleteModal();
    }
  }, [isDeleteSuccess]);

  const changeFolderColorHandler = (color: string) => {
    dispatch(updateFolder({ ...folder, color }));
  };

  const deleteFolderHandler = () => {
    dispatch(deleteFolder(folder.id as number));
  };

  const selectFolderHandler = () => {
    dispatch(selectFolderId(folder.id as number));
  };

  return (
    <>
      <SideBarListItem disablePadding sx={{ pr: 2 }} className={selected || isActive ? "active" : ""}>
        <StyledListItemButton onClick={selectFolderHandler}>
          <ListItemIcon>
            <FolderIcon fontSize='small' sx={{ color: folder.color || FOLDER_DEFAULT_COLOR }} />
          </ListItemIcon>
          <ListItemText
            primary={folder.name}
            title={folder.name}
            sx={{ "& > span": { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}
          />
        </StyledListItemButton>

        <CustomPopoverMenu callbackOnClose={() => setIsActive(false)}>
          <CustomPopoverMenuButton>
            <IconButton size='small' className='ib-sidebar-more-icon' onClick={() => setIsActive(true)}>
              <MoreVertIcon color='action' />
            </IconButton>
          </CustomPopoverMenuButton>
          <CustomPopoverMenuItem
            title='Add New Invoice'
            icon={<AddIcon color='action' />}
            onClick={onOpenAddInvoiceModal}
          />
          <CustomPopoverMenuItem
            title='Change Name'
            icon={<EditIcon color='action' />}
            onClick={onOpenModal}
            onClickHide={true}
          />
          <CustomPopoverMenuItem
            title='Delete Folder'
            icon={<DeleteIcon color='action' />}
            onClick={onOpenDeleteModal}
            onClickHide={true}
          />

          <ChangeColorText variant='body1' color='text.secondary'>
            <ColorLensIcon color='action' /> Change Color
          </ChangeColorText>

          <ColorItemBox>
            {FOLDER_COLORS.map((color, i) => (
              <div
                key={i}
                className='ibColorItem'
                style={{ backgroundColor: color }}
                onClick={() => changeFolderColorHandler(color)}
              />
            ))}
          </ColorItemBox>
        </CustomPopoverMenu>
      </SideBarListItem>

      <DeleteWarningModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deleteFolderHandler}
        isLoading={isDeleting}
      />

      <BasicModalWithHeader title='Change Folder Name' icon={<EditIcon />} open={openModal} onClose={onCloseModal}>
        <AddOrEditFolderName onClose={onCloseModal} isEditMode={true} folder={folder} />
      </BasicModalWithHeader>

      <BasicModalWithHeader title='Create New Invoice' open={openAddInvoiceModal} onClose={onCloseAddInvoiceModal}>
        <AddOrEditInvoiceName onClose={onCloseAddInvoiceModal} isEditMode={false} folderId={folder.id} />
      </BasicModalWithHeader>
    </>
  );
};

export default React.memo(Folder);
