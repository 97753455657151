import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, delay, put } from "typed-redux-saga";
import { sendMailFailed } from "../mail/mailSlice";
import { SendSMSPayload } from "./sms.interface";
import SmsService from "../../../services/smsService";
import { getSMSTemplateDataSuccess, sendSmsFailed, sendSmsSuccess } from "./smsSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* invoiceWatcher() {
  yield takeEvery("sms/getSMSTemplateData", getSMSTemplateDataSaga);
  yield takeEvery("sms/sendSms", sendSmsSaga);
}

function* getSMSTemplateDataSaga() {
  const response = yield* call(SmsService.getSMSTemplateData);
  if (response.success) {
    yield put(getSMSTemplateDataSuccess(response.data));
  }
}

function* sendSmsSaga(action: PayloadAction<SendSMSPayload>) {
  try {
    yield delay(1000);

    const response = yield* call(SmsService.smsSend, action.payload);
    if (response.success) {
      yield put(sendSmsSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(sendSmsFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(sendMailFailed("Something wrong!"));
  }
}

export default function* smsSaga() {
  yield all([invoiceWatcher()]);
}
