export const mailPattern = {
  subject: {
    required: "subject is required!",
    maxLength: {
      value: 100,
      message: "subject must have less than 100 characters!",
    },
  },
  body: {
    required: "message body is required!",
  },
  from: {
    required: "from mail address is required!",
  },
  to: {
    required: "to mail address is required!",
  },
};
