import { all, call, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import PaymentListService from "../../../services/PaymentListService";
import { getPaymentListFailed, getPaymentListSuccess } from "./paymentListSlice";

function* paymentListWatcher() {
  yield takeEvery("paymentList/getPaymentList", getPaymentListSaga);
}

function* getPaymentListSaga() {
  try {
    const response = yield* call(PaymentListService.getPaymentList);

    if (response.success) {
      yield put(getPaymentListSuccess(response.data));
    } else {
      yield put(getPaymentListFailed(response.message));
    }
  } catch (err) {
    yield put(getPaymentListFailed("Network or server error"));
  }
}

export default function* paymentListSaga() {
  yield all([paymentListWatcher()]);
}
