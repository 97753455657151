import React from "react";
import { useSelector } from "react-redux";
import { Skeleton, Stack, styled } from "@mui/material";
import CustomPopOver from "../Popover/CustomPopOver";
import TriggerPersonalizedAccordion from "../Accordion/TriggerPersonalizedAccordion";
import { selectPersonalizeTagState } from "../../state/features/personalizeTag/personalizeTagSelector";

const StyledListWrapper = styled(Stack)(() => ({
  gap: "8px",
  maxWidth: "200px",
  width: "100%",
  padding: "8px",
}));

const StyledList = styled("ul")(({ theme }) => ({
  "& li": {
    margin: theme.spacing(0.5),
    cursor: "pointer",
    padding: "4px 15px",
    fontFamily: "Poppins,Arial,sans-serif",
    fontSize: "13px",
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    transition: "all 0.3s ease",
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      borderColor: "transparent",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

interface IProps {
  onClick: (value: string) => void;
  isSMS?: boolean;
}

const PersonalizedTag: React.FC<IProps> = ({ onClick, isSMS = false }) => {
  const { data: personalizeTags, isLoading } = useSelector(selectPersonalizeTagState);

  let content: any = <></>;
  if (isLoading)
    content = Array.from({ length: 6 }).map((rowNum, index) => (
      <Skeleton key={index} variant='rounded' sx={{ m: 0.5 }} height={38} />
    ));
  else if (personalizeTags?.length) {
    content = personalizeTags.map((eachGroup, eachGroupIndex) => (
      <TriggerPersonalizedAccordion key={eachGroupIndex} title={eachGroup.group_name} expanded={eachGroupIndex === 0}>
        <StyledList>
          {eachGroup.list.map((eachData, eachDataIndex) => {
            if (eachData.value === "[[previous_unpaid_invoice_link]]" && isSMS) return null;
            return (
              <li key={eachGroupIndex + "_" + eachDataIndex} onClick={() => onClick(eachData.value)}>
                {eachData.field_label}
              </li>
            );
          })}
        </StyledList>
      </TriggerPersonalizedAccordion>
    ));
  }

  return (
    <CustomPopOver buttonText={"Personalized"}>
      <StyledListWrapper>{content}</StyledListWrapper>
    </CustomPopOver>
  );
};

export default PersonalizedTag;
