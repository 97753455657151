import React from "react";
import { Skeleton } from "@mui/material";

interface Props {
  count?: number;
}

const FolderListSkeleton: React.FC<Props> = ({ count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((rowNum, index) => (
        <Skeleton key={index} variant='rectangular' sx={{ mb: 0.7, borderRadius: 1 }} height={38} />
      ))}
    </>
  );
};

export default FolderListSkeleton;
