import { ItemInterface } from "../../state/features/item/item.interface";

const generateDefaultLineItem = (): ItemInterface => {
  return {
    id: new Date().getTime(),
    name: "",
    description: "",
    unitOfItem: "",
    itemType: "",
    quantity: 1,
    price: 0,
    tax: 0,
  };
};

export default generateDefaultLineItem;
