import React from "react";
import ExportFileHeader from "./ExportFileHeader";
import ExportFilesTable from "./ExportFilesTable";

const ExportFile: React.FC = () => {
  return (
    <>
      <ExportFileHeader />
      <ExportFilesTable />
    </>
  );
};

export default ExportFile;
