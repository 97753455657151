import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { InvoiceFooterInPreview } from "../DefaultTemplateStyles";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceFooter: React.FC = () => {
  const { data } = useSelector(selectInvoiceBuilderState);

  return (
    <InvoiceFooterInPreview>
      {data.termsAndCondition && (
        <>
          <Stack spacing={1}>
            <Typography variant={"body2"} color={"secondary.main"}>
              Terms and Conditions
            </Typography>
            <Typography variant={"caption"} color={"text.secondary"}>
              <div className="preview" dangerouslySetInnerHTML={{ __html: data.termsAndCondition }}></div>
            </Typography>
          </Stack>
        </>
      )}

      {data.note && (
        <>
          <Stack spacing={1}>
            <Typography variant={"body2"} color={"secondary.main"}>
              Notes
            </Typography>
            <Typography variant={"caption"} color={"text.secondary"}>
              <div className="preview" dangerouslySetInnerHTML={{ __html: data.note }}></div>
            </Typography>
          </Stack>
        </>
      )}
    </InvoiceFooterInPreview>
  );
};

export default InvoiceFooter;
