import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetTaxesPayload, TaxInterface } from "./tax.interface";

const initialState = {
  data: [] as TaxInterface[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    getTaxes: (state, _action: PayloadAction<IGetTaxesPayload>) => {
      state.isLoading = true;
    },
    getTaxesSuccess: (state, action: PayloadAction<TaxInterface[]>) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = "";
    },
    getTaxesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { getTaxes, getTaxesFailed, getTaxesSuccess } = taxSlice.actions;

export default taxSlice.reducer;
