import React from "react";
import { Box } from "@mui/material";
import InvoiceHeaderPreview from "./DefaultInvoiceComponents/InvoiceHeaderPreview";
import InvoiceSummery from "./DefaultInvoiceComponents/InvoiceSummery";
import InvoiceItemsList from "./DefaultInvoiceComponents/InvoiceItemsList";
import InvoiceFooter from "./DefaultInvoiceComponents/InvoiceFooter";

const DefaultInvoiceBody = () => {
  return (
    <Box py={0.5}>
      <InvoiceHeaderPreview />
      <InvoiceSummery />
      <InvoiceItemsList />
      <InvoiceFooter />
    </Box>
  );
};

export default DefaultInvoiceBody;
