import { all } from "redux-saga/effects";
import itemsSaga from "./features/item/itemSaga";
import smsSaga from "./features/sms/smsSaga";
import mailSaga from "./features/mail/mailSaga";
import folderSaga from "./features/folder/folderSaga";
import invoiceSaga from "./features/invoice/invoiceSaga";
import childInvoiceSaga from "./features/childInvoice/childInvoiceSaga";
import invoiceBuilderSaga from "./features/invoiceBuilder/invoiceBuilderSaga";
import contactSaga from "./features/contact/contactSaga";
import invoiceStateSaga from "./features/invoiceState/invoiceStateSaga";
import paymentListSaga from "./features/paymentList/paymentListSaga";
import recurringSettingSaga from "./features/recurringSettings/recurringSettingSaga";
import followupSettingSaga from "./features/followupSetting/followupSettingSaga";
import defaultInfoSaga from "./features/defaultInfo/defaultInfoSaga";
import paymentTermSaga from "./features/paymentTerm/paymentTermSaga";
import taxSaga from "./features/tax/taxSaga";
import personalizeTagSaga from "./features/personalizeTag/personalizeTagSaga";

export default function* rootSaga() {
  yield all([
    itemsSaga(),
    mailSaga(),
    smsSaga(),
    folderSaga(),
    invoiceSaga(),
    childInvoiceSaga(),
    invoiceBuilderSaga(),
    invoiceStateSaga(),
    contactSaga(),
    paymentListSaga(),
    recurringSettingSaga(),
    followupSettingSaga(),
    defaultInfoSaga(),
    paymentTermSaga(),
    taxSaga(),
    personalizeTagSaga(),
  ]);
}
