import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import InvoiceService from "../../../services/invoiceService";
import { IGetChildInvoicePayload } from "./childInvoice.interface";
import { getChildInvoicesFailed, getChildInvoicesSuccess } from "./childInvoiceSlice";

function* childInvoiceWatcher() {
  yield takeEvery("childInvoice/getChildInvoices", getChildInvoicesSaga);
}

function* getChildInvoicesSaga(action: PayloadAction<IGetChildInvoicePayload>) {
  try {
    const response = yield* call(InvoiceService.getChildInvoices, action.payload);

    if (response.success) {
      yield put(getChildInvoicesSuccess(response.data));
    } else {
      yield put(getChildInvoicesFailed(response.message));
    }
  } catch (err) {
    yield put(getChildInvoicesFailed("Network or server error"));
  }
}

export default function* childInvoiceSaga() {
  yield all([childInvoiceWatcher()]);
}
