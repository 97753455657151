import React from "react";
import { DescriptionOutlined, RepeatOutlined } from "@mui/icons-material";

export const BUILDER_OPTIONS = {
  BUILDER: 1,
  PREVIEW_AND_SEND: 2,
  SETTINGS: 3,
};

export const INVOICE_SETTING_ID = {
  // SETTINGS: 1,
  AUTO_RECURRING: 2,
  TRIGGER: 3,
  FOLLOWUP: 4,
  SEND_EMAIL: 5,
  SEND_SMS: 6,
};

export const INVOICE_SETTING_OPTIONS = {
  [INVOICE_SETTING_ID.AUTO_RECURRING]: {
    id: INVOICE_SETTING_ID.AUTO_RECURRING,
    title: "Recurring Settings",
    content: "Set your recurring invoice here",
  },
  [INVOICE_SETTING_ID.FOLLOWUP]: {
    id: INVOICE_SETTING_ID.FOLLOWUP,
    title: "Followup Settings",
    content: "Set your followup invoice here",
  },
};

export const INVOICE_TYPE = {
  ONE_TIME: "ONE_TIME",
  RECURRING: "RECURRING",
};

export const INVOICE_TYPE_LIST = {
  [INVOICE_TYPE.ONE_TIME]: {
    value: INVOICE_TYPE.ONE_TIME,
    label: "One-time Invoice",
    content: "Send a one-time invoice to the customer right away.",
    icon: <DescriptionOutlined />,
  },
  [INVOICE_TYPE.RECURRING]: {
    value: INVOICE_TYPE.RECURRING,
    label: "Recurring Invoice Template",
    content: "Send a recurring invoice to the customer at scheduled intervals.",
    icon: <RepeatOutlined />,
  },
};

export const TEMPLATE_TYPE = {
  STANDARD: 1,
  DEFAULT: 2,
  BASIC: 3,
};

export const INVOICE_TEMPLATE_IMAGE = {
  BASIC: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311272986_basic.png",
  STANDARD: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311344729_standard.png",
  DEFAULT: "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1698311504487_default.png",
};

export const CHOOSE_TEMPLATE_LIST = {
  [TEMPLATE_TYPE.STANDARD]: {
    label: "Standard",
    value: TEMPLATE_TYPE.STANDARD,
    image: INVOICE_TEMPLATE_IMAGE.STANDARD,
  },
  [TEMPLATE_TYPE.DEFAULT]: { label: "Default", value: TEMPLATE_TYPE.DEFAULT, image: INVOICE_TEMPLATE_IMAGE.DEFAULT },
  [TEMPLATE_TYPE.BASIC]: { label: "Basic", value: TEMPLATE_TYPE.BASIC, image: INVOICE_TEMPLATE_IMAGE.BASIC },
};

export const InvoiceOrderByList = [
  { title: "Title [a-z]", value: "a-z" },
  { title: "Title [z-a]", value: "z-a" },
  { title: "Creation Date", value: "cre-date" },
  { title: "Last Edit", value: "last-edit" },
];

export const PAYMENT_STATUS = {
  OUTSTANDING: "OUTSTANDING",
  PAST_DUE: "PAST_DUE",
  PAID: "PAID",
};

export const StatusFilterList = [
  { title: "Outstanding", value: PAYMENT_STATUS.OUTSTANDING },
  { title: "Past Due", value: PAYMENT_STATUS.PAST_DUE },
  { title: "Paid", value: PAYMENT_STATUS.PAID },
];

export const InvoiceEndDateStatus = {
  NEVER: "NEVER",
  BY: "BY",
  AFTER: "AFTER",
};

export const InvoiceEndDateStatusList = {
  [InvoiceEndDateStatus.NEVER]: { label: "Never", value: InvoiceEndDateStatus.NEVER },
  [InvoiceEndDateStatus.BY]: { label: "by", value: InvoiceEndDateStatus.BY },
  [InvoiceEndDateStatus.AFTER]: { label: "After", value: InvoiceEndDateStatus.AFTER },
};

export const InvoicePaymentMode = {
  CASH: "CASH",
  CARD: "CARD",
  CHEQUE: "CHEQUE",
  BANK_TRANSFER: "BANK_TRANSFER",
  OTHER: "OTHER",
};

export const InvoicePaymentModeList = [
  {
    label: "Cash",
    value: InvoicePaymentMode.CASH,
  },
  {
    label: "Card",
    value: InvoicePaymentMode.CARD,
  },
  {
    label: "Check",
    value: InvoicePaymentMode.CHEQUE,
  },
  {
    label: "Bank Transfer",
    value: InvoicePaymentMode.BANK_TRANSFER,
  },
  {
    label: "Other",
    value: InvoicePaymentMode.OTHER,
  },
];
