import React from "react";
import { Link } from "react-router-dom";
import { styled, Typography } from "@mui/material";

const BreadCrumbText = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  textDecoration: "none",
  color: "inherit",
}));

interface Props {
  parentLocation: string;
  icon?: JSX.Element;
  currentLocation: string;
  parentUri?: string;
  color?: string;
}

const CustomBreadcrumb: React.FC<Props> = ({ parentLocation, icon, currentLocation, parentUri = "#", color }) => {
  return (
    <BreadCrumbText variant={"chipLight"} color={color}>
      <StyledLink to={parentUri}>
        {icon} {parentLocation}
      </StyledLink>
      / {currentLocation}
    </BreadCrumbText>
  );
};

export default CustomBreadcrumb;
