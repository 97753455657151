export const CLIENT_CAN_SEE_KEY = {
  QUANTITY: 1,
  PRICE: 2,
  TAX_PERCENT: 3,
  LINE_TOTAL: 4,
  SUB_TOTAL: 5,
  SHIPPING_CHARGE: 6,
  DISCOUNT: 7,
  TAXES: 8,
};

export const CLIENT_CAN_SEE = {
  [CLIENT_CAN_SEE_KEY.QUANTITY]: { label: "Quantity", value: CLIENT_CAN_SEE_KEY.QUANTITY },
  [CLIENT_CAN_SEE_KEY.PRICE]: { label: "Rate", value: CLIENT_CAN_SEE_KEY.PRICE },
  // [CLIENT_CAN_SEE_KEY.TAX_PERCENT]: { label: "Item Tax Percent", value: CLIENT_CAN_SEE_KEY.TAX_PERCENT },
  [CLIENT_CAN_SEE_KEY.LINE_TOTAL]: { label: "Amount", value: CLIENT_CAN_SEE_KEY.LINE_TOTAL },
  [CLIENT_CAN_SEE_KEY.SUB_TOTAL]: { label: "Sub Total", value: CLIENT_CAN_SEE_KEY.SUB_TOTAL },
  [CLIENT_CAN_SEE_KEY.SHIPPING_CHARGE]: { label: "Shipping Charge", value: CLIENT_CAN_SEE_KEY.SHIPPING_CHARGE },
  [CLIENT_CAN_SEE_KEY.DISCOUNT]: { label: "Discount", value: CLIENT_CAN_SEE_KEY.DISCOUNT },
  [CLIENT_CAN_SEE_KEY.TAXES]: { label: "Taxes", value: CLIENT_CAN_SEE_KEY.TAXES },
};

export const SETTING_PAYMENT_MODE = ["bank-transfer", "check", "cash", "credit-card", "bank-remittance"];
export const SETTING_CURRENCY = ["usd", "cad"];

export const CHOOSE_TEMPLATE = [
  {
    label: "simple",
    value: "SIMPLE",
  },
  { label: "Clean", value: "CLEAN" },
  {
    label: "Modern",
    value: "MODERN",
  },
];

export const INVOICE_SEND_TYPE = {
  EMAIL: "EMAIL",
  SMS: "SMS",
};

export const IF_CLIENT_CAN_SEE_FALSE = "-";
