import httpRequest from "./api/httpRequest";
import FollowupSettingInterface, { IUpdateFollowupSetting } from "../interface/followupSetting.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class followupSettingService {
  getFollowupSetting(id: number) {
    return httpRequest.get<FollowupSettingInterface>(
      `${invoiceAPI}invoice-builder/invoice/followup-settings?invoiceId=${id}`
    );
  }

  updateFollowupSettingData(payload: IUpdateFollowupSetting) {
    return httpRequest.put<FollowupSettingInterface>(
      `${invoiceAPI}invoice-builder/invoice/followup-settings/update`,
      payload
    );
  }
}

export default new followupSettingService();
