import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import { IGetItemsPayload, ItemInterface } from "./item.interface";

const initialState = {
  data: [] as ItemInterface[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 10,

  isFetching: false,
  isFetchingSuccess: false,

  isAdding: false,
  isAddSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  filter: {
    searchText: "",
  },
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    getItems: (state, action: PayloadAction<IGetItemsPayload | undefined>) => {
      state.isLoading = true;
    },
    getItemsSuccess: (state, action: PayloadAction<WithPagination<ItemInterface>>) => {
      const { content, count, limit, currentPage } = action.payload;
      state.data = content;
      state.currentPage = currentPage;
      state.count = count || 0;
      state.limit = +limit;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getItemsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addItem: (state, action: PayloadAction<ItemInterface>) => {
      state.isAdding = true;
    },
    addItemSuccess: (state, action: PayloadAction<ItemInterface>) => {
      state.data.push(action.payload);
      state.isAdding = false;
      state.isAddSuccess = true;
      state.count++
    },
    addItemFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    resetAddItemSuccess: (state) => {
      state.isAddSuccess = false;
    },

    updateItem: (state, action: PayloadAction<ItemInterface>) => {
      state.isUpdating = true;
    },
    updateItemSuccess: (state, action: PayloadAction<ItemInterface>) => {
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });

      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updateItemFailed: (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },
    resetUpdateItemSuccess: (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    deleteItem: (state, action: PayloadAction<number>) => {
      state.isDeleting = true;
    },
    deleteItemSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.count--
    },
    deleteItemFailed: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },
    resetDeleteItemSuccess: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    //filters
    addFilterItemSearchText: (state, action: PayloadAction<string>) => {
      state.filter.searchText = action.payload;
    },

    resetItemState: (state) => {
      state.data = [] as ItemInterface[];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.count = 0;
      state.currentPage = 1;
      state.limit = 10;
      state.filter = {
        searchText: "",
      };
    },
  },
});

export const {
  getItems,
  getItemsFailed,
  getItemsSuccess,

  addItem,
  addItemFailed,
  addItemSuccess,
  resetAddItemSuccess,

  updateItem,
  updateItemFailed,
  updateItemSuccess,
  resetUpdateItemSuccess,

  deleteItem,
  deleteItemFailed,
  deleteItemSuccess,
  resetDeleteItemSuccess,

  addFilterItemSearchText,
  resetItemState,
} = itemSlice.actions;

export default itemSlice.reducer;
