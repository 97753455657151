import { Box, Stack, styled } from "@mui/material";
import { TableTotal } from "../../Builder/InvoiceItems/ItemsStyes";

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "210px",
  height: "80px",
  "& img": {
    width: "auto",
    maxWidth: "100%",
    height: "100%",
    objectFit: "contain",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90px",
  },
}));

export const PreviewHeaderWrapper = styled(Box)(({ theme }) => ({
  borderTop: `8px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(4),
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

// header ends

// invoice summery
interface BoxBackgroundProps {
  background: string;
}
export const StyledStack = styled(Stack)<BoxBackgroundProps>(({ theme, background }) => ({
  backgroundColor: background,
  padding: theme.spacing(3, 2),
  flexWrap: "wrap",
  flex: 1,
}));

export const SummeryHeading = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 4),
}));

//inv footer
export const InvoiceFooterInPreview = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.other.outlinedBorder}`,
  gap: 20,
}));

// item table
export const TotalAmountTable = styled(TableTotal)(({ theme }) => ({
  marginRight: 0,
  "& .MuiTableRow-root": {
    border: "none",
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
      "&.ib-topBottomBordered": {
        borderTop: `1.5px solid ${theme.palette.secondary.main}`,
        borderBottom: `1.5px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
      },
    },
    "&:last-child": {
      borderTop: `1px solid ${theme.palette.other.outlinedBorder}`,
      borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
    },
  },
}));
