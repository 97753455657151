import { alpha, Box, Stack, styled } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

export const SingleSettingWrapper = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

//client can see

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (propName: string) => {
    return propName !== "selectedValues";
  },
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  flex: "1 1 49%",
  "& .MuiFormControlLabel-label": {
    fontWeight: 300,
  },
}));

//choose template
export const ChooseTemplateWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "auto",
  "&::-webkit-scrollbar": { display: "none" },
}));

export const TemplateBoxWrp = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: theme.spacing(1),
}));

export const TemplateBox = styled(Box)(({ theme }) => ({
  minWidth: "270px",
  width: "100%",
  height: "430px",
  padding: theme.spacing(0.5),
  border: `2px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: "4px",
  position: "relative",
  transition: "all 0.3s ease",
  "& .ib_checkedIcon": {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    transform: "scale(0)",
  },
  "&.inv_selected": {
    borderColor: alpha(theme.palette.secondary.main, 0.5),
    backgroundColor: theme.palette.other.secondaryHover,
    "& .ib_checkedIcon": {
      transform: "scale(1)",
    },
  },
  "&:hover": {
    borderColor: alpha(theme.palette.secondary.main, 0.5),
  },
  "& img": {
    width: "100%",
    height: "100%",
  },
}));
