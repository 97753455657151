import React from "react";
import { Box } from "@mui/material";
import InvoiceHeaderStandard from "./StandardInvoiceComponents/InvoiceHeaderStandard";
import InvoiceSummeryStandard from "./StandardInvoiceComponents/InvoiceSummeryStandard";
import InvoiceFooterStandard from "./StandardInvoiceComponents/InvoiceFooterStandard";
import InvoiceTableStandard from "./StandardInvoiceComponents/InvoiceTableStandard";

const StandardInvoiceBody: React.FC = () => {
  return (
    <Box p={5}>
      <InvoiceHeaderStandard />
      <InvoiceSummeryStandard />
      <InvoiceTableStandard />
      <InvoiceFooterStandard />
    </Box>
  );
};

export default StandardInvoiceBody;
