export const RECURRING_CYCLE = {
  NEVER: "NEVER",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export const RECURRING_CYCLE_LIST = [
  // { label: "Never", value: RECURRING_CYCLE.NEVER },
  { label: "Daily", value: RECURRING_CYCLE.DAILY },
  { label: "Weekly", value: RECURRING_CYCLE.WEEKLY },
  { label: "Monthly", value: RECURRING_CYCLE.MONTHLY },
  { label: "Yearly", value: RECURRING_CYCLE.YEARLY },
];

export const DAILY_SEND_EVERY = [
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "4 days", value: 4 },
  { label: "5 days", value: 5 },
  { label: "6 days", value: 6 },
  { label: "7 days", value: 7 },
  { label: "8 days", value: 8 },
  { label: "9 days", value: 9 },
  { label: "10 days", value: 10 },
  { label: "11 days", value: 11 },
  { label: "12 days", value: 12 },
  { label: "13 days", value: 13 },
  { label: "14 days", value: 14 },
  { label: "15 days", value: 15 },
  { label: "16 days", value: 16 },
  { label: "17 days", value: 17 },
  { label: "18 days", value: 18 },
  { label: "19 days", value: 19 },
  { label: "20 days", value: 20 },
  { label: "21 days", value: 21 },
  { label: "22 days", value: 22 },
  { label: "23 days", value: 23 },
  { label: "24 days", value: 24 },
  { label: "25 days", value: 25 },
  { label: "26 days", value: 26 },
  { label: "27 days", value: 27 },
  { label: "28 days", value: 28 },
];

export const SEND_AT = [
  { label: "12 PM", value: 0 },
  { label: "1 AM", value: 1 },
  { label: "2 AM", value: 2 },
  { label: "3 AM", value: 3 },
  { label: "4 AM", value: 4 },
  { label: "5 AM", value: 5 },
  { label: "6 AM", value: 6 },
  { label: "7 AM", value: 7 },
  { label: "8 AM", value: 8 },
  { label: "9 AM", value: 9 },
  { label: "10 AM", value: 10 },
  { label: "11 AM", value: 11 },
  { label: "12 AM", value: 12 },
  { label: "1 PM", value: 13 },
  { label: "2 PM", value: 14 },
  { label: "3 PM", value: 15 },
  { label: "4 PM", value: 16 },
  { label: "5 PM", value: 17 },
  { label: "6 PM", value: 18 },
  { label: "7 PM", value: 19 },
  { label: "8 PM", value: 20 },
  { label: "9 PM", value: 21 },
  { label: "10 PM", value: 22 },
  { label: "11 PM", value: 23 },
];

export const WEEKLY_SEND_EVERY = {
  SUNDAY: "SUN",
  MONDAY: "MON",
  TUESDAY: "TUE",
  WEDNESDAY: "WED",
  THURSDAY: "THU",
  FRIDAY: "FRI",
  SATURDAY: "SAT",
};

export const WEEKLY_SEND_EVERY_LIST = [
  { label: "Sunday", value: WEEKLY_SEND_EVERY.SUNDAY },
  { label: "Monday", value: WEEKLY_SEND_EVERY.MONDAY },
  { label: "Tuesday", value: WEEKLY_SEND_EVERY.TUESDAY },
  { label: "Wednesday", value: WEEKLY_SEND_EVERY.WEDNESDAY },
  { label: "Thursday", value: WEEKLY_SEND_EVERY.THURSDAY },
  { label: "Friday", value: WEEKLY_SEND_EVERY.FRIDAY },
  { label: "Saturday", value: WEEKLY_SEND_EVERY.SATURDAY },
];

export const MONTHLY_SEND_EVERY = [
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
  { label: "5th", value: 5 },
  { label: "6th", value: 6 },
  { label: "7th", value: 7 },
  { label: "8th", value: 8 },
  { label: "9th", value: 9 },
  { label: "10th", value: 10 },
  { label: "11th", value: 11 },
  { label: "12th", value: 12 },
  { label: "13th", value: 13 },
  { label: "14th", value: 14 },
  { label: "15th", value: 15 },
  { label: "16th", value: 16 },
  { label: "17th", value: 17 },
  { label: "18th", value: 18 },
  { label: "19th", value: 19 },
  { label: "20th", value: 20 },
  { label: "21st", value: 21 },
  { label: "22nd", value: 22 },
  { label: "23rd", value: 23 },
  { label: "24th", value: 24 },
  { label: "25th", value: 25 },
  { label: "26th", value: 26 },
  { label: "27th", value: 27 },
  { label: "28th", value: 28 },
];

export const YEARLY_SEND_EVERY_MONTH = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];
