export const addDays = (date: Date | string, days: number = 0) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonths = (date: Date | string, months: number = 0) => {
  let result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};
