import React, { ReactElement } from "react";
import { Typography } from "@mui/material";
import Styles from "./GlobalEmptyPage.module.scss";

interface Props {
  image?: string;
  icon?: ReactElement;
  title: string;
  description?: string;
}

const GlobalEmptyPage: React.FC<Props> = ({ image, icon, title, description }) => {
  return (
    <div className={Styles.wrapper}>
      {image && (
        <div className={Styles.imageWrapper}>
          <img src={image} alt='Not Found' />
        </div>
      )}
      {icon && <div className={Styles.iconWrapper}>{icon}</div>}
      <Typography variant='h5' mt={4} color={"text.primary"}>
        {title}
      </Typography>
      <Typography variant='body1medium' color='text.secondary' sx={{ maxWidth: "520px", textAlign: "center" }}>
        {description}
      </Typography>
    </div>
  );
};

export default GlobalEmptyPage;
