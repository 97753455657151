import React, { useState } from "react";
import { Box, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import { CustomListItemText, StyledMenu, positions } from "./CustomPopoverMenuStyles";

interface Props {
  twoColumns?: boolean;
  position?: "left" | "right" | "bottom";
  children: JSX.Element[];
  onClickHide?: boolean;
  callbackOnClose?: () => void;
}

const CustomPopoverMenu: React.FC<Props> = function ({ twoColumns, position, onClickHide, callbackOnClose, children }) {
  const [anchorActionMenu, setAnchorActionMenu] = useState<HTMLDivElement | null>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorActionMenu(e.currentTarget);
  const handleCloseMenu = () => {
    setAnchorActionMenu(null);
    if (callbackOnClose) callbackOnClose();
  };
  let buttonChildren: JSX.Element[] = [];

  const renderActionMenu = () => {
    let items: JSX.Element[] = [];

    if (Array.isArray(children)) {
      children?.forEach((elem, index) => {
        const clickHandler = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, element: JSX.Element) => {
          const { onClickHide: onClickHideFromMenuItem, onClick } = element.props;
          if (onClickHideFromMenuItem || onClickHide) {
            handleCloseMenu();
          }
          onClick(e);
        };

        if (elem.type !== undefined && elem.type.name === CustomPopoverMenuButton.name) {
          buttonChildren.push(elem);
        } else if (elem.type !== undefined && elem.type.name === CustomPopoverMenuItem.name) {
          items.push(
            <MenuItem
              sx={{ minWidth: "232px", color: "text.secondary" }}
              disabled={elem.props.disabled}
              onClick={(e) => clickHandler(e, elem)}
              key={"action-menu-" + index}
              className={elem.props.className}
            >
              <ListItemIcon sx={{ height: "24px" }}>{elem.props.icon}</ListItemIcon>
              <CustomListItemText inset>{elem.props.title}</CustomListItemText>
            </MenuItem>
          );
        } else if (Array.isArray(elem)) {
          elem?.forEach((item, index) => {
            items.push(
              <MenuItem
                onClick={(e) => clickHandler(e, item)}
                key={"action-menu-" + index}
                className={item.props.className}
              >
                <ListItemIcon>{item.props.icon}</ListItemIcon>
                <ListItemText inset>
                  <Typography variant='body2' color='text.primary'>
                    {item.props.title}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          });
        } else {
          items.push(elem);
        }
      });

      return items;
    } else {
      buttonChildren = children;
    }
    return null;
  };

  return (
    <Box>
      <Box onClick={handleOpenMenu}>{buttonChildren}</Box>

      <StyledMenu
        twoColumns={twoColumns}
        id='action-menu'
        anchorEl={anchorActionMenu}
        keepMounted
        open={Boolean(anchorActionMenu)}
        onClose={handleCloseMenu}
        anchorOrigin={
          position ? (positions[position].transform ? positions[position].anchor : positions.right.anchor) : undefined
        }
        transformOrigin={
          position
            ? positions[position].transform
              ? positions[position].transform
              : positions.right.transform
            : undefined
        }
      >
        {renderActionMenu()}
      </StyledMenu>
    </Box>
  );
};

interface CustomPopoverMenuButtonProps {
  children: React.ReactNode;
}

export const CustomPopoverMenuButton: React.FC<CustomPopoverMenuButtonProps> = ({ children }) => {
  return <>{children}</>;
};

interface CustomPopoverMenuItemProps {
  children?: React.ReactNode;
  title?: string;
  icon?: JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickHide?: boolean;
}
export const CustomPopoverMenuItem: React.FC<CustomPopoverMenuItemProps> = ({ children }) => {
  return <>{children}</>;
};

export default CustomPopoverMenu;
