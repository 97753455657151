import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import AddIcon from "@mui/icons-material/Add";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { ContentCopyTwoTone, RemoveCircleTwoTone } from "@mui/icons-material";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TableContainer,
  Typography,
  useTheme,
} from "@mui/material";
import TaxSelect from "./TaxSelect";
import SelectItem from "./SelectItem";
import CoreButton from "../../../common/Button/CoreButton";
import BasicDrawer from "../../../common/Drawer/BasicDrawer";
import CoreTextField from "../../../common/TextField/CoreTextField";
import AddItemDrawerContent from "../../Items/AddItemDrawerContent";
import ClientCanSeeOptions from "../../Settings/InvoiceSettings/ClientCanSeeOptions";
import formatCurrency from "../../../helpers/utils/formatCurrency";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import { registerPatternItem } from "../../../helpers/constant/registerPattern";
import generateDefaultLineItem from "../../../helpers/utils/generateDefaultLineItem";
import useBoolean from "../../../hooks/useBoolean";
import { InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";
import { ItemInterface } from "../../../state/features/item/item.interface";
import { TaxInterface } from "../../../state/features/tax/tax.interface";
import { DataCellTd, StyleTextField } from "./ItemsStyes";
import { InvoiceContainer } from "../BuilderStyles";
import BasicModal from "../../../common/Modal/BasicModal";
import InvoicePaymentContent from "../InvoicePayment/InvoicePaymentContent";

const InvoiceItemsBody: React.FC = () => {
  const theme = useTheme();
  const { value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer } = useBoolean(false);
  const { value: openColumnModal, setTrue: onOpenColumnModal, setFalse: onCloseColumnModal } = useBoolean(false);

  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
    clearErrors,
  } = useFormContext<InvoiceInterface>();

  const {
    fields,
    append: handleAppendItem,
    remove: handleRemoveItem,
    insert: handleInsertItem,
    update: handleItemUpdate,
  } = useFieldArray({
    name: "items",
    rules: { minLength: 1 },
    control,
  });

  const handleAddNewLineItem = () => {
    const data = generateDefaultLineItem();
    handleAppendItem(data);
  };

  const handleRemove = (index: number) => {
    const items = getValues("items");
    if (items?.length > 1) {
      handleRemoveItem(index);
      showSuccessToaster("Item successfully deleted!");
    } else {
      showErrorToaster("Minimum 1 line item is required!");
    }
  };

  const handleCallbackSelectItem = (itemInfo: ItemInterface, index: number) => {
    handleItemUpdate(index, itemInfo);
    clearErrors(`items.${index}`);
  };

  const handleTaxSelect = (taxInfo: TaxInterface) => {
    setValue("taxPercentage", taxInfo.taxPercentage || 0);
    setValue("taxName", taxInfo.taxPercentage === 0 ? "" : taxInfo.name);
  };

  // get items value when change input value
  const listItems = getValues("items");
  const shippingChargeValue = +getValues("shippingCharge");
  const discountValue = +getValues("discount");
  const taxPercentage = +getValues("taxPercentage");

  const subTotalAmount = Array.isArray(listItems)
    ? listItems.reduce((previousValue, currentValue) => {
        const amount = Number(currentValue.quantity || 0) * Number(currentValue.price || 0);
        return previousValue + amount;
      }, 0)
    : 0;
  const subTotalAfterSubtractionDiscount = subTotalAmount - discountValue;
  const totalTax = (subTotalAfterSubtractionDiscount * taxPercentage) / 100;
  const totalAmount = subTotalAfterSubtractionDiscount + totalTax + shippingChargeValue;

  watch();

  return (
    <InvoiceContainer>
      <Box>
        {/* ## Items Table View ## */}
        <TableContainer className='ib_xscroll_bar'>
          <Table sx={{ minWidth: 650 }} size={"small"}>
            <TableHead sx={{ bgcolor: "other.outlinedBorderDarker" }}>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ borderRight: `1px solid ${theme.palette.common.white}`, width: 300, maxWidth: 350 }}
                >
                  Items
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ borderRight: `1px solid ${theme.palette.common.white}`, width: "max-content" }}
                >
                  Description
                </TableCell>
                <TableCell align='right' sx={{ borderRight: `1px solid ${theme.palette.common.white}`, width: 120 }}>
                  Quantity
                </TableCell>
                <TableCell align='right' sx={{ borderRight: `1px solid ${theme.palette.common.white}`, width: 120 }}>
                  Rate
                </TableCell>
                <TableCell align='right' sx={{ borderRight: `1px solid ${theme.palette.common.white}`, width: 120 }}>
                  Amount
                </TableCell>
                <TableCell align='right' width={70} sx={{ paddingInline: "6px" }}>
                  {/*<ExtraSmallButton*/}
                  {/*  variant={"outlined"}*/}
                  {/*  color={"secondary"}*/}
                  {/*  sx={{ minWidth: "max-content" }}*/}
                  {/*  onClick={onOpenColumnModal}*/}
                  {/*>*/}
                  {/*  Edit Columns*/}
                  {/*</ExtraSmallButton>*/}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ "& > tr > td": { verticalAlign: "top" } }}>
              {fields.map((item, index) => {
                const itemInfo = getValues(`items.${index}`) || {};
                const quantity = +itemInfo.quantity;
                const price = +itemInfo.price;
                const amount = quantity * price;

                return (
                  <TableRow key={item.id}>
                    <DataCellTd align='center' sx={{ width: 168 }}>
                      <SelectItem
                        callback={handleCallbackSelectItem}
                        index={index}
                        onOpenDrawer={onOpenDrawer}
                        name={item.name}
                      />
                      <Controller
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <input type={"text"} style={{ width: 0, height: 0, border: 0 }} {...field} />
                              <FormHelperText error={invalid}>{error?.message}</FormHelperText>
                            </>
                          );
                        }}
                        control={control}
                        name={`items.${index}.name`}
                        rules={registerPatternItem.name}
                      />
                      <input type='hidden' {...register(`items.${index}.unitOfItem`)} />
                      <input type='hidden' {...register(`items.${index}.itemType`)} />
                    </DataCellTd>
                    <DataCellTd>
                      <Controller
                        render={({ field, fieldState: { invalid, error } }) => (
                          <CoreTextField
                            fullWidth
                            size='small'
                            type='text'
                            color='secondary'
                            multiline
                            maxRows={3}
                            placeholder='Type description'
                            disabled={!item.name}
                            error={invalid}
                            helperText={invalid && error?.message}
                            {...field}
                            className={"ib_editor_light_textField"}
                          />
                        )}
                        control={control}
                        name={`items.${index}.description`}
                        rules={registerPatternItem.description(item.name)}
                      />
                    </DataCellTd>
                    <DataCellTd align='right'>
                      <Controller
                        render={({ field, fieldState: { invalid, error } }) => (
                          <StyleTextField
                            size='small'
                            type='number'
                            color='secondary'
                            inputProps={{
                              min: 1,
                              step: 1,
                            }}
                            disabled={!item.name}
                            error={invalid}
                            helperText={invalid && error?.message}
                            {...field}
                            className={"ib_editor_light_textField"}
                          />
                        )}
                        control={control}
                        name={`items.${index}.quantity`}
                        rules={registerPatternItem.quantity}
                      />
                    </DataCellTd>

                    <DataCellTd align='right'>
                      <Controller
                        control={control}
                        name={`items.${index}.price`}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <StyleTextField
                              size='small'
                              type='number'
                              color='secondary'
                              disabled={!item.name}
                              error={invalid}
                              helperText={invalid && error?.message}
                              className={"ib_editor_light_textField"}
                              {...field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment sx={{ mr: 0 }} position='start'>
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          );
                        }}
                        rules={registerPatternItem.price}
                      />
                    </DataCellTd>

                    <DataCellTd align='right' sx={{ pr: 2 }}>
                      <Typography variant='body1' color={"text.secondary"} fontWeight={300} pt={1.3}>
                        {formatCurrency(amount, "$")}
                      </Typography>
                    </DataCellTd>

                    <DataCellTd align='right'>
                      <Stack direction='row' alignItems='center' pt={1}>
                        <IconButton
                          color='secondary'
                          size='small'
                          onClick={() => {
                            handleInsertItem(index + 1, { ...itemInfo, id: Date.now() });
                            showSuccessToaster("Item successfully cloned!");
                          }}
                        >
                          <ContentCopyTwoTone sx={{ fontSize: 16 }} />
                        </IconButton>
                        <IconButton
                          color='error'
                          size='small'
                          onClick={() => {
                            handleRemove(index);
                          }}
                        >
                          <RemoveCircleTwoTone sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Stack>
                    </DataCellTd>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction='row' justifyContent={"space-between"} gap={1} sx={{ mt: 2 }}>
          <Stack alignItems={"flex-start"} justifyContent={"space-between"} sx={{ maxWidth: "35%" }}>
            <CoreButton
              variant='outlined'
              size={"small"}
              color='secondary'
              endIcon={<AddIcon />}
              onClick={handleAddNewLineItem}
              sx={{ mt: -1 }}
            >
              Add new line
            </CoreButton>
            <InvoicePaymentContent />
          </Stack>

          <Paper elevation={0} sx={{ bgcolor: "other.outlinedBorder", p: 2, marginRight: "60px" }}>
            <Table
              size={"small"}
              sx={{
                "& tr": { borderBottom: "none" },
                "& td": { borderBottom: "none", padding: "3px 12px", color: "text.secondary", fontWeight: 300 },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>Sub Total</TableCell>
                  <TableCell align={"right"}>{formatCurrency(subTotalAmount, "$")}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Shipping Charges</TableCell>
                  <TableCell>
                    <StyleTextField
                      sx={{ width: 140 }}
                      size='small'
                      type='number'
                      color='secondary'
                      inputProps={{
                        min: 0,
                        step: 0.05,
                      }}
                      {...register("shippingCharge", { valueAsNumber: true })}
                      className={"ib_editor_light_textField"}
                    />
                  </TableCell>
                  <TableCell align={"right"}>{formatCurrency(+getValues("shippingCharge"), "$")}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Discount</TableCell>
                  <TableCell>
                    <StyleTextField
                      sx={{ width: 140 }}
                      size='small'
                      type='number'
                      color='secondary'
                      inputProps={{
                        min: 0,
                        step: 0.05,
                      }}
                      {...register("discount", { valueAsNumber: true })}
                      className={"ib_editor_light_textField"}
                    />
                  </TableCell>
                  <TableCell align={"right"}>{formatCurrency(+getValues("discount"), "$")}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <TaxSelect callback={handleTaxSelect} name={getValues("taxName")} />
                  </TableCell>
                  <TableCell>
                    <StyleTextField
                      sx={{ width: 140 }}
                      size='small'
                      type='number'
                      color='secondary'
                      inputProps={{
                        min: 0,
                        step: 0.01,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                      }}
                      {...register("taxPercentage", { required: true, valueAsNumber: true })}
                      className={"ib_editor_light_textField"}
                    />
                    <input type='hidden' {...register("taxName")} />
                  </TableCell>
                  <TableCell align={"right"}>{formatCurrency(totalTax, "$")}</TableCell>
                </TableRow>

                <TableRow sx={{ "& td": { borderTop: "1px solid", borderColor: "other.outlinedBorderDarker" } }}>
                  <TableCell colSpan={2}>
                    <Typography variant='body1' fontWeight={300}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align={"right"}>
                    <input
                      type='text'
                      style={{ display: "none" }}
                      {...register("totalAmountOfInvoice")}
                      value={totalAmount}
                    />
                    <Typography variant='body1' color={"text.primary"} fontWeight={300}>
                      {formatCurrency(totalAmount, "$")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Stack>
      </Box>

      <BasicDrawer open={openDrawer} title='Add New Item' toggleDrawer={onCloseDrawer}>
        <AddItemDrawerContent onClose={onCloseDrawer} />
      </BasicDrawer>

      <BasicModal open={openColumnModal} onClose={onCloseColumnModal}>
        <Box sx={{ maxWidth: 600, px: 5 }}>
          <Controller
            control={control}
            name={"settings.clientCanSee"}
            render={({ field: { value, onChange } }) => (
              <ClientCanSeeOptions
                selectedValues={value || ([] as number[])}
                onChange={onChange}
                onClose={onCloseColumnModal}
              />
            )}
          />
        </Box>
      </BasicModal>
    </InvoiceContainer>
  );
};

export default InvoiceItemsBody;
