import React from "react";

const EmailText = ({ email }: { email: string }) => {
  if (!email) return <></>;

  const href = "mailto: " + email;
  return (
    <a href={href} style={{ textDecoration: "none", color: "inherit" }}>
      {email}
    </a>
  );
};

export default EmailText;
