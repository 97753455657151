import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import { Alert, Box, Checkbox, Stack, Typography } from "@mui/material";
import LoadingButton from "../../../common/Button/LoadingButton";
import { CLIENT_CAN_SEE } from "../../../helpers/constant/invoiceSettingConstant";
import {
  resetUpdateInvoiceClientCanSee,
  updateInvoiceClientCanSee,
} from "../../../state/features/invoiceBuilder/invoiceBuilderSlice";
import { selectInvoiceBuilderState } from "../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { TEMPLATE_TYPE } from "../../../helpers/constant/invoiceConstant";
import { StyledFormControlLabel } from "./invoiceSettingStyles";

interface Props {
  selectedValues: number[];
  onChange: (values: number[]) => void;
  onClose: () => void;
}

type InvoiceUseParams = {
  invoiceId: string;
};

const ClientCanSeeOptions: React.FC<Props> = ({ selectedValues, onChange, onClose }) => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams<InvoiceUseParams>();

  const clientCanSeeOptionList = Object.values(CLIENT_CAN_SEE);
  const { data, isUpdatingSettings, updatedSettingsSuccess } = useSelector(selectInvoiceBuilderState);
  const { settings: oldSettings } = data || {};
  const [localSelectedValues, setLocalSelectedValues] = useState(selectedValues || []);

  const handleChangeOption = (value: string) => {
    const currentKey = Number(value);
    const values = Array.isArray(localSelectedValues) ? localSelectedValues.slice(0) : [];

    const currentKeyIndex = values.findIndex((key) => key === currentKey);
    if (currentKeyIndex !== -1) {
      values.splice(currentKeyIndex, 1);
    } else {
      values.push(currentKey);
    }

    setLocalSelectedValues(values);
  };

  useEffect(() => {
    if (updatedSettingsSuccess) {
      onChange(localSelectedValues);
      dispatch(resetUpdateInvoiceClientCanSee());
      onClose();
    }
  }, [updatedSettingsSuccess]);

  const handleSave = () => {
    const { template } = oldSettings || {};
    const settings = {
      clientCanSee: localSelectedValues,
      template: template || TEMPLATE_TYPE.DEFAULT,
    };
    dispatch(updateInvoiceClientCanSee({ invoiceId: +invoiceId, settings }));
  };

  const isEqual = selectedValues.toString() === localSelectedValues.toString();

  return (
    <Box>
      <Typography variant={"body2"} color={"text.primary"} mb={1}>
        Select items to display on invoice below:
      </Typography>

      <FormGroup row={true}>
        {clientCanSeeOptionList.map((option) => {
          return (
            <StyledFormControlLabel
              key={option.value}
              label={option.label}
              control={
                <Checkbox
                  value={option.value}
                  size={"small"}
                  color={"secondary"}
                  checked={localSelectedValues?.includes(option.value)}
                  onChange={(event) => {
                    handleChangeOption(event.target.value);
                  }}
                />
              }
            />
          );
        })}
      </FormGroup>
      <Stack direction={"row"} justifyContent={"center"} mb={1}>
        <LoadingButton
          type={"button"}
          size={"medium"}
          color={"secondary"}
          variant={"contained"}
          disabled={isEqual}
          isLoading={isUpdatingSettings}
          loadingText={"Saving..."}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </Stack>
      <Alert severity='error'>
        <b>Note:</b> Changes to your selection above will not be reflected immediately. The changes will appear when you
        review your invoice in the next step.
      </Alert>
    </Box>
  );
};

export default React.memo(ClientCanSeeOptions);
