import React, { useState } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import { DescriptionOutlined, Payment, RepeatOutlined } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChildInvoices from "./ChildInvoices";
import AddToFolderInvoice from "./AddToFolderInvoice";
import FolderNameChipList from "./FolderNameChipList";
import AddOrEditInvoiceName from "./AddOrEditInvoiceName";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import RouteLink from "../../common/Link/RouteLink";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../hooks/useBoolean";
import { AppDispatch } from "../../state/store";
import {
  cloneInvoice,
  deleteInvoices,
  toggleArchiveStatus,
  toggleFavStatus,
  toggleSelectedSingleInvoice,
} from "../../state/features/invoice/invoiceSlice";
import getFullName from "../../helpers/utils/getFullName";
import formatCurrency from "../../helpers/utils/formatCurrency";
import { BUILDER_OPTIONS, INVOICE_TYPE, InvoiceEndDateStatus } from "../../helpers/constant/invoiceConstant";
import { generatePDF } from "../../state/features/invoiceBuilder/invoiceBuilderSlice";
import { InvoiceListItemInterface } from "../../state/features/invoice/invoice.interface";
import { selectInvoicePdfGenerating } from "../../state/features/invoiceBuilder/invoiceBuilderSelector";
import { selectInvoiceSelectedIds } from "../../state/features/invoice/invoiceSelector";
import ManuallyRecordPaymentContent from "./ManuallyRecordPaymentContent";
import { inputDateFormat } from "../../helpers/utils/previewDateFormat";

interface Props {
  invoice: InvoiceListItemInterface;
}

const getFormattedDate = (dueDate: string | Date) => {
  return format(new Date(dueDate), "MMM dd, yyyy");
};

const InvoiceListSingleItemTable: React.FC<Props> = ({ invoice }) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    id,
    name,
    invoiceNumber,
    client,
    totalAmount,
    isArchived,
    isFavorite,
    invoiceDate,
    createdAt,
    dueDate,
    status,
    invoiceType,
    totalRecurringInvoice,
    startDate,
    endDateStatus,
    endDate,
    formattedCreatedAt,
  } = invoice;
  const { value: openRenameModal, setTrue: onOpenRenameModal, setFalse: onCloseRenameModal } = useBoolean(false);
  const { value: openChilInvModal, setTrue: onOpenChilInvModal, setFalse: onCloseChilInvModal } = useBoolean(false);
  const [selectedChildInvoiceId, setSelectedChildInvoiceId] = useState<null | number>(null);
  const [selectedChildInvoiceAmount, setSelectedChildInvoiceAmount] = useState<number>(0);
  const {
    value: openManualPaymentModal,
    setTrue: onOpenManualPaymentModal,
    setFalse: onCloseManualPaymentModal,
  } = useBoolean(false);

  const [isActive, setIsActive] = useState(false);
  const selectedInvoiceIds = useSelector(selectInvoiceSelectedIds);
  const pdfGenerating = useSelector(selectInvoicePdfGenerating);

  const toggleFavoriteHandler = () => {
    dispatch(toggleFavStatus({ id: id as number, isFavorite: !isFavorite }));
  };

  const archiveHandler = () => {
    dispatch(toggleArchiveStatus({ ids: [id as number], isArchived: !isArchived }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteInvoiceHandler = () => {
    dispatch(deleteInvoices({ ids: [id as number], isTrashed: true }));
  };

  const handleCloneInvoice = () => {
    dispatch(cloneInvoice({ invoiceId: id as number }));
  };

  const onDownload = () => {
    if (id) dispatch(generatePDF(id));
  };

  const onGoBuilderHeader = () => {
    history.push("/ibuilder/invoice/" + id, {
      tab: BUILDER_OPTIONS.BUILDER,
    });
  };

  const onGoBuilderSettings = () => {
    history.push("/ibuilder/invoice/" + id, {
      tab: BUILDER_OPTIONS.SETTINGS,
    });
  };

  const onGoBuilderSendInvoice = () => {
    history.push("/ibuilder/invoice/" + id, {
      tab: BUILDER_OPTIONS.PREVIEW_AND_SEND,
    });
  };

  const onGoBuilderPreview = () => {
    history.push("/ibuilder/invoice/" + id, {
      tab: BUILDER_OPTIONS.PREVIEW_AND_SEND,
    });
  };

  const onClickManualRecordPayment = (childInvoiceId: number, totalAmount: number) => {
    setSelectedChildInvoiceId(childInvoiceId);
    setSelectedChildInvoiceAmount(totalAmount);
    onOpenManualPaymentModal();
  };

  const deselectChildInvoiceInfo = () => {
    setSelectedChildInvoiceId(null);
    setSelectedChildInvoiceAmount(0);
  };

  const open = Boolean(anchorEl);
  const formattedDueDate = dueDate ? getFormattedDate(dueDate) : "-";
  // const formattedCreatedAt = createdAt ? getFormattedDate(createdAt) : "-";
  const formattedInvoiceDate = invoiceDate ? getFormattedDate(invoiceDate) : "-";
  const formattedStartDate = startDate ? getFormattedDate(startDate) : "-";
  const formattedEndDate = endDate ? getFormattedDate(endDate) : "-";

  const isChecked = selectedInvoiceIds.includes(id as number);

  let customer: string | undefined;
  if (client?.id) {
    customer = getFullName(client.firstName, client.lastName) || client.email || client.number;
  }

  let paymentStatus;

  if (status === "COMPLETED") {
    paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"success"} label={"Paid"} />;
  } else {
    const today = inputDateFormat(new Date());
    if (today <= inputDateFormat(dueDate)) {
      paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"secondary"} label={"Outstanding"} />;
    } else {
      paymentStatus = <Chip size={"xSmall"} variant={"outlined"} color={"error"} label={" Past Due"} />;
    }
  }

  return (
    <TableBody sx={{ "& td": { fontWeight: 300, width: "max-content" } }}>
      <TableRow selected={selectedInvoiceIds.includes(id as number) || isActive}>
        <TableCell sx={{ pr: 0 }}>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            <Checkbox
              size={"small"}
              edge='start'
              color='secondary'
              checked={isChecked}
              onChange={() => dispatch(toggleSelectedSingleInvoice(id as number))}
              sx={{
                padding: "4px",
                marginLeft: 0,
                "& .PrivateSwitchBase-input": {
                  pointerEvents: "auto !important",
                },
              }}
            />
            <Checkbox
              size={"small"}
              checked={Boolean(isFavorite)}
              icon={<StarIcon color='action' />}
              checkedIcon={<StarIcon color='warning' />}
              onClick={toggleFavoriteHandler}
              sx={{
                padding: "4px",
                "& .PrivateSwitchBase-input": {
                  pointerEvents: "auto !important",
                },
              }}
            />
            <CustomTooltip
              arrow
              placement={"top"}
              title={invoiceType === INVOICE_TYPE.ONE_TIME ? "OneTime" : "Recurring"}
            >
              <span>
                <IconButton size={"small"} sx={invoiceType === INVOICE_TYPE.ONE_TIME ? { cursor: "default" } : {}}>
                  {invoiceType === INVOICE_TYPE.ONE_TIME ? (
                    <DescriptionOutlined fontSize={"small"} />
                  ) : (
                    <Badge color='secondary' badgeContent={totalRecurringInvoice} onClick={onOpenChilInvModal}>
                      <RepeatOutlined fontSize={"small"} />
                    </Badge>
                  )}
                </IconButton>
              </span>
            </CustomTooltip>
          </Stack>
        </TableCell>

        <TableCell sx={{ lineHeight: "15px" }}>
          <Stack direction='row' spacing={2} alignItems='center' mr={2}>
            <Typography variant='body2' color='text.primary' sx={{ minWidth: "max-content" }}>
              <RouteLink to={`/ibuilder/invoice/${invoice.id}`}>{name}</RouteLink>
            </Typography>
            <FolderNameChipList invoiceId={invoice.id as number} invoiceFolderList={invoice.folder || []} />
          </Stack>
          <Typography variant='caption' color='text.secondary' fontWeight={300}>
            Created on: {formattedCreatedAt}
          </Typography>
        </TableCell>

        <TableCell>{invoiceNumber}</TableCell>

        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar sx={{ width: 25, height: 25 }}>{customer?.charAt(0).toUpperCase()}</Avatar>
            <Typography variant='body2' color='text.primary' fontWeight={300}>
              {customer || "-"}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{invoiceType === INVOICE_TYPE.RECURRING ? formattedStartDate : formattedInvoiceDate}</TableCell>
        <TableCell>
          {invoiceType === INVOICE_TYPE.RECURRING
            ? endDateStatus === InvoiceEndDateStatus.NEVER
              ? "Never End"
              : formattedEndDate
            : formattedDueDate}
        </TableCell>

        <TableCell>{formatCurrency(totalAmount, "$")}</TableCell>

        <TableCell>
          {invoiceType === INVOICE_TYPE.RECURRING ? (
            <Chip
              icon={<VisibilityIcon />}
              size={"xSmall"}
              variant={"outlined"}
              color={"info"}
              label={"See All"}
              onClick={onOpenChilInvModal}
            />
          ) : (
            paymentStatus
          )}
        </TableCell>

        <TableCell align={"center"}>
          <Box>
            <CustomPopoverMenu twoColumns={true} callbackOnClose={() => setIsActive(false)}>
              <CustomPopoverMenuButton>
                <CustomTooltip title='More Options' arrow placement='top'>
                  <span>
                    <IconButton size='small' className='ib_invoice_list_more_icon' onClick={() => setIsActive(true)}>
                      <MoreHorizIcon color='action' />
                    </IconButton>
                  </span>
                </CustomTooltip>
              </CustomPopoverMenuButton>
              <CustomPopoverMenuItem
                title='Preview'
                icon={<VisibilityIcon color='action' />}
                onClick={onGoBuilderPreview}
                onClickHide={true}
              />
              <CustomPopoverMenuItem
                title='Download'
                icon={pdfGenerating ? <CircularProgress size={20} /> : <DownloadForOfflineIcon color='action' />}
                onClick={onDownload}
              />
              <CustomPopoverMenuItem
                title='Edit Invoice'
                icon={<EditIcon color='action' />}
                onClick={onGoBuilderHeader}
                onClickHide={true}
              />
              <CustomPopoverMenuItem
                title='Send Invoice'
                icon={<ShareIcon color='action' />}
                onClick={onGoBuilderSendInvoice}
                onClickHide={true}
              />
              <CustomPopoverMenuItem
                title='Invoice Settings'
                icon={<SettingsIcon color='action' />}
                onClick={onGoBuilderSettings}
                onClickHide={true}
              />
              <CustomPopoverMenuItem
                onClick={archiveHandler}
                title={!isArchived ? "Archive" : "Unarchive"}
                icon={!isArchived ? <ArchiveIcon color='action' /> : <UnarchiveIcon color='action' />}
                onClickHide={true}
              />
              <CustomPopoverMenuItem
                title='Rename'
                icon={<DriveFileRenameOutlineIcon color='action' />}
                onClick={onOpenRenameModal}
              />
              <CustomPopoverMenuItem
                title='Delete'
                icon={<DeleteIcon color='action' />}
                onClickHide={true}
                onClick={deleteInvoiceHandler}
              />
              <CustomPopoverMenuItem
                title='Clone'
                icon={<FileCopyIcon color='action' />}
                onClick={handleCloneInvoice}
              />
              <CustomPopoverMenuItem
                title='Add to folder'
                icon={<CreateNewFolderIcon color='action' />}
                onClick={handleClick}
              />
              {invoiceType === INVOICE_TYPE.RECURRING ? (
                <CustomPopoverMenuItem
                  title='Child Invoices'
                  icon={<LibraryBooksIcon color='action' />}
                  onClick={onOpenChilInvModal}
                  onClickHide={true}
                />
              ) : (
                <></>
              )}

              {invoiceType === INVOICE_TYPE.ONE_TIME && status !== "COMPLETED" ? (
                <CustomPopoverMenuItem
                  title='Mark Invoice as Paid'
                  icon={<Payment color='action' />}
                  onClick={onOpenManualPaymentModal}
                  onClickHide={true}
                />
              ) : (
                <></>
              )}
            </CustomPopoverMenu>
          </Box>
        </TableCell>

        <BasicModalWithHeader
          title='Rename Invoice'
          icon={<EditIcon />}
          open={openRenameModal}
          onClose={onCloseRenameModal}
        >
          <AddOrEditInvoiceName onClose={onCloseRenameModal} isEditMode={true} invoiceInfo={invoice} />
        </BasicModalWithHeader>

        <BasicDrawer open={openChilInvModal} title='Child Invoice' toggleDrawer={onCloseChilInvModal}>
          <ChildInvoices invoice={invoice} onClickManualRecordPayment={onClickManualRecordPayment} />
        </BasicDrawer>

        <BasicModalWithHeader
          title='Mark Invoice as Paid'
          icon={<Payment />}
          open={openManualPaymentModal}
          onClose={onCloseManualPaymentModal}
        >
          <ManuallyRecordPaymentContent
            onClose={onCloseManualPaymentModal}
            invoiceInfo={invoice}
            deselectChildInvoiceInfo={deselectChildInvoiceInfo}
            childInvoiceAmount={selectedChildInvoiceAmount}
            childInvoiceId={selectedChildInvoiceId}
          />
        </BasicModalWithHeader>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <AddToFolderInvoice
            invoiceIds={[invoice.id as number]}
            folderIds={(invoice.folder || []).map((s) => s.folderId)}
            onClose={handleClose}
          />
        </Popover>
      </TableRow>
    </TableBody>
  );
};

export default InvoiceListSingleItemTable;
