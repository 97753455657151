import { AddressInterface } from "../../state/features/invoiceBuilder/invoiceBuilderInterface";

const addressView = (addressObj?: AddressInterface) => {
  if (addressObj) {
    const addressArray = Object.keys(addressObj)
      .filter((value) => {
        if (value === "address") return false;
        if (value) return true;
        return false;
      })
      .map((key) => {
        return addressObj[key as keyof typeof addressObj];
      })
      .filter((value) => (value ? true : false));

    return addressArray.join(", ");
  }

  return "";
};

export default addressView;
