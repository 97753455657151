import React from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

interface Props {
  open?: boolean;
  toggleDrawer: () => void;
  title?: string;
  header?: string;
  width?: number;
  children: React.ReactNode;
}

const BasicDrawer: React.FC<Props> = ({ open, toggleDrawer, title = "", header, width = 850, children }) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={toggleDrawer}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: width,
          width: "100%",
        },
      }}
    >
      <Box sx={{ height: "100%" }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} px={3} py={2}>
          <>
            {title && (
              <Typography variant={"subtitle1"} color={"text.primary"} sx={{ fontWeight: "500" }}>
                {title}
              </Typography>
            )}
            {header || null}
          </>

          <IconButton onClick={toggleDrawer} color={"error"}>
            <Clear />
          </IconButton>
        </Stack>

        <Divider />

        <Box sx={{ height: "calc(100% - 75px)", overflowY: "auto" }} className={"ib_scroll_bar"}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default BasicDrawer;
