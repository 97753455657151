import { ItemInterface } from "../item/item.interface";
import { InvoiceTypeEnum } from "../invoice/invoice.interface";

export interface InvoiceInterface {
  id?: number;
  name: string;
  orderId: string;
  invoiceNumber: string;
  client: ContactInterface | null;
  contactId: string | number;
  invoiceDate: string | Date;
  dueDate: string | Date;
  logoOfCompany: string;
  useSystemDefaultLogo?: boolean | number;
  items: ItemInterface[];
  subTotal: number;
  discount: number;
  shippingCharge: number;
  taxes: number;
  taxPercentage: number;
  taxName?: string;
  note: string;
  termsAndCondition: string;
  folder: number[];
  totalAmountOfInvoice: number;
  paymentMethods: number[] | null;
  paymentMethod: number;
  pdfFileUrl: string;
  isPdfGenerate: number;
  invoiceType: InvoiceTypeEnum;
  settings: {
    template: number;
    clientCanSee: number[];
  };
  repeatSettings?: {
    automaticRecurring: true;
    recurringCycle: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
    sendEvery: number;
    dayOfSent: "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT";
    dateOfSent: number;
    monthOfSent: number;
    sentAt: number;
  };
  startDate?: string | Date;
  endDateStatus?: string; // "NEVER", "BY", "AFTER"
  endDate?: string | Date;
  paymentTerms?: {
    id?: number;
    name: string;
    value: number;
    status: string;
  };
}

export interface ContactInterface {
  id: number;
  contactId: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  billingAddress?: AddressInterface;
  shippingAddress?: AddressInterface;
  orderId?: string;
}

export interface AddressInterface {
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
}

export enum PAYMENT_METHOD {
  stripe = "STRIPE",
  paypal = "PAYPAL",
  authorizeDotNet = "AUTHORIZE_DOT_NET",
}

export interface StepInterface {
  title: string;
  value: number;
  isComplete: boolean;
}

export interface IGetInvoiceBuilderData {
  invoiceId: number;
}

export interface IRequestUpdateClientCanSee {
  invoiceId: number;
  settings: {
    clientCanSee: number[];
    template: number;
  };
}

export interface IRequestInvoiceItemData {
  id: number;
  subTotal: number;
  discount: number;
  shippingCharge: number;
  taxes: number;
  totalAmountOfInvoice: number;
  items: ItemInterface[];
}

export interface IResponseInvoiceItemData {
  id: number;
  subTotal: number;
  discount: number;
  shippingCharge: number;
  taxes: number;
  totalAmountOfInvoice: number;
  items: ItemInterface[];
  clientCanSee: number[];
}
