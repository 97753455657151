export const manuallyRecordPayment = {
  subject: {
    required: "subject is required!",
    maxLength: {
      value: 100,
      message: "subject must have less than 100 characters!",
    },
  },
  paymentMode: {
    required: "Payment mode is required!",
  },
  amount: {
    required: "Amount is required!",
  },
};
