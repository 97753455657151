import {
  alpha,
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  styled,
  TableContainer,
  Tabs,
  Typography,
} from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";

//invoice main
export const InvoiceBuilderMainWrapper = styled(Box)(() => ({
  width: "100%",
  // height: "calc(100vh - 116px)",
  background: "linear-gradient(0deg, rgba(148, 157, 178, 0.12), rgba(148, 157, 178, 0.12)), #FFFFFF",
  paddingBottom: 20,
}));

export const InvoiceHeaderWrp = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  flexDirection: "row",
}));

export const InvoicesList = styled(Stack)(({ theme }) => ({
  height: "calc(100% - 62px)",
  gap: "16px",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: { flexDirection: "column", height: "calc(100% - 120px)" },
}));

//left sidebar
export const LeftSideBar = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(0.5),
  transition: "all 0.3s ease-out",
  "& .iSidebarTitleBar": {
    width: "100%",
    height: "56px",
    transition: "all 0.3s ease-out",
    "& .iSidebarTitle": {
      width: "max-content",
    },
  },
  "&.collapseLeftSide": {
    width: 0,
    "& .iSidebarTitleBar": {
      width: "56px",
      justifyContent: "center",
      background: theme.palette.common.white,
      borderRadius: "4px",
      "& .iSidebarTitle": {
        width: 0,
        opacity: 0,
        pointerEvents: "none",
        margin: 0,
        transition: "opacity 0s 0s ease-out",
      },
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "56px",
      background: "transparent",
    },
  },
  [theme.breakpoints.up("lg")]: {
    width: "268px",
    "& .iSidebarTitleBar": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      "& .iSidebarTitle": {
        marginLeft: theme.spacing(1.25),
        transition: "opacity 0.3s 0.3s ease-out",
      },
    },
  },
}));

interface SidebarListWrapperProps {
  hide_sidebar: boolean;
  show_sidebar_tab: boolean;
}

export const SidebarListWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== "hide_sidebar" && prop !== "show_sidebar_tab",
})<SidebarListWrapperProps>(({ theme, hide_sidebar, show_sidebar_tab }) => ({
  width: hide_sidebar === true ? 0 : "268px",
  height: "calc(100vh - 236px)",
  opacity: hide_sidebar === true ? 0 : 1,
  top: "80px",
  left: show_sidebar_tab === true ? "-100%" : 0,
  transition: "all 0.2s ease-out",
  overflowY: "auto",
  zIndex: 99,
  "&::-webkit-scrollbar": { display: "none" },
  "& > *": {
    width: hide_sidebar === true ? 0 : "100%",
    opacity: hide_sidebar === true ? 0 : 1,
    transition: hide_sidebar !== true && "opacity 0.2s 0.2s ease",
  },
  [theme.breakpoints.down("lg")]: {
    height: "calc(100% - 55px) ",
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    position: "fixed",
    boxShadow: theme.shadows[3],
  },
}));

export const SideTopBarTab = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
  display: "none",
  [theme.breakpoints.down("lg")]: { display: "flex" },
}));

interface IAllFormsButtonWr {
  isActive: boolean;
}
export const AllFormsButton = styled(CoreButton, {
  shouldForwardProp: (prop: string) => prop !== "isActive",
})<IAllFormsButtonWr>(({ theme, isActive }) => ({
  justifyContent: "flex-start",
  backgroundColor: isActive ? theme.palette.other.secondaryHover : "",
  "&.MuiButton-textSizeMedium": {
    fontSize: "15px",
    padding: theme.spacing(1.25, 2.5),
  },
  "&:focus": {
    backgroundColor: isActive ? theme.palette.other.secondaryHover + " !important" : "",
  },
}));

export const SideBarListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  "& .ib-sidebar-more-icon": {
    visibility: "hidden",
    pointerEvents: "none",
    marginRight: theme.spacing(-1.5),
  },
  "&:hover": {
    "& .ib-sidebar-more-icon": { visibility: "visible", pointerEvents: "all" },
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  "& .MuiListItemIcon-root ": {
    minWidth: "29px",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const CustomList = styled(List)(({ theme }) => ({
  width: "100%",
  maxHeight: "calc(100% - 265px)",
  height: "auto",
  overflowY: "auto",
  padding: 0,
  margin: "8px 0",
  paddingRight: "6px",
  transition: "0.3s",
  "& .MuiListItem-root": {
    width: "100%",
    borderRadius: "4px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.other.secondaryHover,
    },
    "&.active": {
      background: theme.palette.other.secondaryHover,
    },
    "& .MuiListItemIcon-root": {
      minWidth: "32px",
    },
  },
}));

export const ChangeColorText = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(1, 2),
}));
export const ColorItemBox = styled(Box)(({ theme }) => ({
  gap: "10px",
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  padding: "8px 16px",
  cursor: "pointer",
  "& .ibColorItem": {
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    display: "inline-flex",
    background: theme.palette.other.outlinedBorder,
  },
}));

// right side invoice
export const InvoiceBodyWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  // height: "calc(100% - 120px)",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  [theme.breakpoints.down("lg")]: {
    height: "calc(100% - 190px)",
  },
  [theme.breakpoints.down("md")]: {
    height: "calc(100% - 285px)",
  },
}));

export const InvoiceListTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "300px",
  "& th": {
    background: theme.palette.other.gray,
  },
  "& td,& th": { padding: "4px 4px" },
  "@media (max-height:500px)": {
    maxHeight: "200px",
  },
}));

interface Props {
  isChecked?: boolean;
  isActive?: boolean;
}

export const InvoiceSingleListWrp = styled(Stack, {
  shouldForwardProp: (props) => props !== "isChecked",
})<Props>(({ theme, isChecked }) => ({
  width: "100%",
  padding: theme.spacing(2, 2.7),
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  flexWrap: "wrap",
  transition: "all 0.2s ease-out",
  "&:hover": {
    backgroundColor: theme.palette.other.secondaryHover,
  },
  backgroundColor: isChecked ? theme.palette.other.secondaryHover : "",
}));

// folder list chip tab
export const FolderChipListTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "max-content",
  alignItems: "center",
  "& .MuiTab-root": {
    minHeight: "max-content",
  },
  "& .MuiTabs-flexContainer": { gap: "8px" },
  "&:hover": {
    "& .MuiTabScrollButton-root": {
      display: "inline-flex",
      zIndex: 99,
    },
  },
  "& .MuiTabScrollButton-root": {
    display: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: theme.palette.text.secondary,
    color: theme.palette.common.white,
    position: "absolute",
    left: "-10px",
    "&:last-child": {
      right: "-10px",
      left: "auto",
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

interface StyledChipProps {
  chipColor: string;
}
export const StyledChip = styled(Chip, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== "chipColor";
  },
})<StyledChipProps>(({ theme, chipColor }) => ({
  backgroundColor: alpha(chipColor, 0.1),
  color: chipColor,
  transition: "all 0.3s ease",
  "& .MuiChip-icon": {
    color: chipColor,
  },
  "& .MuiChip-deleteIcon": {
    position: "absolute",
    right: 0,
    transform: "translateX(20px)",
    pointerEvents: "none",
    color: theme.palette.other.darkNavy,
    "&:hover": {
      color: theme.palette.other.darkNavy,
    },
  },
  "&:hover": {
    paddingRight: theme.spacing(2),
    "& .MuiChip-deleteIcon": {
      transform: "translateX(0)",
      transformOrigin: "right",
      pointerEvents: "all",
    },
  },
}));

//choose invoice option
export const CreateInvoiceOption = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
  cursor: "pointer",
  position: "relative",
  "& .ib_checkedIcon": {
    position: "absolute",
    top: 2,
    right: 2,
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    opacity: 0,
  },
  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",
    transition: "all 0.2s ease-in-out",
  },
  "&:hover,&.ib_optionSelected": {
    borderColor: theme.palette.secondary.main,
    background: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
    "&:before": {
      border: `18px solid ${theme.palette.secondary.main}`,
      borderBottomColor: "transparent",
      borderLeftColor: "transparent",
    },
    "& .ib_checkedIcon": { opacity: 1 },
    "& .ib_invoice_type_icon": {
      background: theme.palette.secondary.light,
    },
  },
}));
