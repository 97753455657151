import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Routes from "./AppRoutes";
import ThemeWrapper from "./common/GlobalWrapper/ThemeWrapper";
import { setCookie } from "./helpers/Cookie";
import { getSystemDefaultInfo } from "./state/features/defaultInfo/defaultInfoSlice";

interface Props {
  history?: RouteComponentProps["history"];
}

const App: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();

  if (process.env.REACT_APP_PRODUCTION_MODE !== "true" && process.env.REACT_APP_APP_URL === "http://localhost:3019") {
    // TODO set api access token in cookie for tests
    const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
    if (!accessToken) throw Error("Access token not found in env!");
    // live = DGM2RNXDL8KS85JD066E
    // stage = QW4RFEW1G1HRE1HH1E
    setCookie(accessToken, "DGM2RNXDL8KS85JD066E", process.env.REACT_APP_ACCESS_TOKEN_VALIDITY || "1 hour");
  }

  useEffect(() => {
    if (window.setActiveSidebar) {
      window.setActiveSidebar("invoices");
    }
    dispatch(getSystemDefaultInfo());
  }, []);

  return (
    <ThemeWrapper>
      <Routes history={history} />
    </ThemeWrapper>
  );
};

export default App;
