import React from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Stack, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { StyledTableCell, StyledTableRow, TotalTableRow } from "../BasicPreviewStyles";
import numberToDollar from "../../../../helpers/utils/numberToDollar";
import previewDateFormat from "../../../../helpers/utils/previewDateFormat";
import getInvoiceDateAndDueDate from "../../../../helpers/utils/getInvoiceDateAndDueDate";
import { CLIENT_CAN_SEE_KEY, IF_CLIENT_CAN_SEE_FALSE } from "../../../../helpers/constant/invoiceSettingConstant";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceTableBasic: React.FC = () => {
  const {
    data: {
      items,
      orderId,
      invoiceNumber,
      invoiceDate,
      dueDate,
      startDate,
      discount = 0,
      shippingCharge = 0,
      taxes = 0,
      taxName,
      taxPercentage = 0,
      totalAmountOfInvoice = 0,
      settings,
      invoiceType,
      paymentTerms,
    },
  } = useSelector(selectInvoiceBuilderState);
  const { clientCanSee = [] } = settings || {};

  const { formattedInvoiceDate, formattedDueDate } = getInvoiceDateAndDueDate(
    invoiceType,
    invoiceDate,
    dueDate,
    startDate,
    paymentTerms
  );

  let subTotal = 0;
  if (Array.isArray(items)) {
    subTotal = items.reduce((acc, item) => {
      const { quantity, price } = item;
      const amount = price * quantity;
      return acc + amount;
    }, 0);
  }

  return (
    <TableContainer sx={{ py: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left' sx={{ pl: 10 }}>
              Items & Description
            </StyledTableCell>
            <StyledTableCell align='center'>Quantity</StyledTableCell>
            <StyledTableCell align='right'>Rate</StyledTableCell>
            <StyledTableCell align='right' sx={{ pr: 10 }}>
              Amount
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => {
            const { quantity, price, name, description } = item;
            const amount = price * quantity;

            return (
              <StyledTableRow key={index}>
                <TableCell align='left'>
                  <Stack>
                    <Typography variant={"body2Semibold"}>{name ? name : "-"}</Typography>
                    <Typography variant={"caption"}>{description ? description : "-"}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align='center'>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.QUANTITY) ? quantity : IF_CLIENT_CAN_SEE_FALSE}
                </TableCell>
                <TableCell align='right'>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.PRICE) ? numberToDollar(price) : IF_CLIENT_CAN_SEE_FALSE}
                </TableCell>
                <TableCell align='right'>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.LINE_TOTAL)
                    ? numberToDollar(amount)
                    : IF_CLIENT_CAN_SEE_FALSE}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>

      <Stack direction={"row"} justifyContent={"space-between"} pl={10}>
        <Stack justifyContent={"flex-end"}>
          <Typography variant={"body2Semibold"} color={"other.grayDark"} mb={2}>
            Invoice Details:
          </Typography>
          {invoiceNumber && (
            <Typography variant={"caption"} color={"other.grayDark"}>
              Invoice No: {invoiceNumber}
            </Typography>
          )}
          <Typography variant={"caption"} color={"other.grayDark"}>
            Order No: {orderId}
          </Typography>
          {formattedInvoiceDate && (
            <Typography variant={"caption"} color={"other.grayDark"}>
              Invoice Date: {previewDateFormat(formattedInvoiceDate)}
            </Typography>
          )}
          {formattedDueDate && (
            <Typography variant={"caption"} color={"other.grayDark"}>
              Due Date: {previewDateFormat(formattedDueDate)}
            </Typography>
          )}
        </Stack>
        <Table sx={{ width: "50%" }}>
          <TableBody>
            <TotalTableRow>
              <TableCell align='right'>
                <Typography variant={"caption"}>Sub Total</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant={"body2Semibold"} color={"text.primary"}>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SUB_TOTAL)
                    ? numberToDollar(subTotal)
                    : IF_CLIENT_CAN_SEE_FALSE}
                </Typography>
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell align='right'>
                <Typography variant={"caption"}>After Discount</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant={"body2Semibold"}>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.DISCOUNT)
                    ? numberToDollar(subTotal - discount)
                    : IF_CLIENT_CAN_SEE_FALSE}
                </Typography>
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell align='right'>
                <Typography variant={"caption"}>Shipping Charges</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant={"body2Semibold"} color={"text.primary"}>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.SHIPPING_CHARGE)
                    ? numberToDollar(shippingCharge)
                    : IF_CLIENT_CAN_SEE_FALSE}
                </Typography>
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell align='right'>
                <Typography variant={"caption"}>
                  Taxes{" "}
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) &&
                    (taxName ? `(${taxName} | ${taxPercentage}%)` : `(${taxPercentage}%)`)}
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant={"body2Semibold"} color={"text.primary"}>
                  {clientCanSee.includes(CLIENT_CAN_SEE_KEY.TAXES) ? numberToDollar(taxes) : IF_CLIENT_CAN_SEE_FALSE}
                </Typography>
              </TableCell>
            </TotalTableRow>

            <TotalTableRow>
              <TableCell align='right'>
                <Typography variant={"body1SemiBold"}>TOTAL</Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant={"body1SemiBold"}>{numberToDollar(totalAmountOfInvoice)}</Typography>
              </TableCell>
            </TotalTableRow>
          </TableBody>
        </Table>
      </Stack>
    </TableContainer>
  );
};

export default InvoiceTableBasic;
