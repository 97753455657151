export const PAYMENT_TERM_STATUS = {
  DAY: "DAY",
  DUE_END_OF_THE_MONTH: "DUE_END_OF_THE_MONTH",
  DUE_END_OF_THE_NEXT_MONTH: "DUE_END_OF_THE_NEXT_MONTH",
  DUE_ON_RECEIPT: "DUE_ON_RECEIPT",
};

export const PAYMENT_TERM_STATUS_IDS = {
  DAY: 169763178281000,
  DUE_END_OF_THE_MONTH: 169763178281001,
  DUE_END_OF_THE_NEXT_MONTH: 169763178281002,
  DUE_ON_RECEIPT: 169763178281003,
};

export const PAYMENT_TERM_STATUS_DEFAULT_LIST = [
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 3 days",
    value: 3,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 7 days",
    value: 7,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 10 days",
    value: 10,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 15 days",
    value: 15,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 20 days",
    value: 20,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 25 days",
    value: 25,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 30 days",
    value: 30,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DAY,
    status: PAYMENT_TERM_STATUS.DAY,
    name: "Net 60 days",
    value: 60,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DUE_END_OF_THE_MONTH,
    status: PAYMENT_TERM_STATUS.DUE_END_OF_THE_MONTH,
    name: "Due end of the month",
    value: 0,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DUE_END_OF_THE_NEXT_MONTH,
    status: PAYMENT_TERM_STATUS.DUE_END_OF_THE_NEXT_MONTH,
    name: "Due end of the next month",
    value: 0,
  },
  {
    id: PAYMENT_TERM_STATUS_IDS.DUE_ON_RECEIPT,
    status: PAYMENT_TERM_STATUS.DUE_ON_RECEIPT,
    name: "Due on receipt",
    value: 0,
  },
];
