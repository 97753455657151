import { all, put } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getRecurringSettingFailed,
  getRecurringSettingSuccess,
  updateRecurringSettingFailed,
  updateRecurringSettingSuccess,
} from "./recurringSettingSlice";
import RecurringSettingService from "../../../services/recurringSettingsService";
import { IUpdateRecurringSetting } from "../../../interface/recurringSettings.interface";
import { updateInvoiceBuilderUpdateTime } from "../invoiceBuilder/invoiceBuilderSlice";
import updateBuilderSettingsAt from "../../../helpers/utils/updateBuilderSettingsAt";
import savingBuilderSetting from "../../../helpers/utils/savingBuilderSetting";

function* recurringSettingWatcher() {
  yield takeLatest("recurringSetting/getRecurringSetting", getRecurringSettingSaga);
  yield takeLatest("recurringSetting/updateRecurringSetting", updateRecurringSettingSaga);
}

function* getRecurringSettingSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(RecurringSettingService.getRecurringSettings, action.payload);

    if (response.success) {
      yield put(getRecurringSettingSuccess(response.data));
    } else {
      yield put(getRecurringSettingFailed(response.message as string));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateRecurringSettingSaga(action: PayloadAction<IUpdateRecurringSetting>) {
  try {
    yield put(updateInvoiceBuilderUpdateTime(savingBuilderSetting()));
    const response = yield* call(RecurringSettingService.updateRecurringSettingsData, action.payload);

    if (response.success) {
      yield put(updateRecurringSettingSuccess(response.data));
    } else {
      yield put(updateRecurringSettingFailed(response.message as string));
    }
    yield put(updateInvoiceBuilderUpdateTime(updateBuilderSettingsAt()));
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* recurringSettingSaga() {
  yield all([recurringSettingWatcher()]);
}
