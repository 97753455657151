import httpRequest from "./api/httpRequest";
import { MailTemplateInterface, SendMailPayload } from "../state/features/mail/mail.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class MailService {
  getMailTemplateData() {
    return httpRequest.get<MailTemplateInterface>(invoiceAPI + "invoice-builder/default/email/template");
  }

  mailSend(payload: SendMailPayload) {
    return httpRequest.post<SendMailPayload>(invoiceAPI + "invoice-builder/invoice/send", payload);
  }
}

export default new MailService();
