import React from "react";
import ItemsHeader from "./ItemsHeader";
import ItemsListFilterSearch from "./ItemsListFilterSearch";
import ItemsTable from "./ItemsTable";
import { ItemsMainWrapper, ItemsBodyWrapper } from "./ItemsStyles";

const AllItems: React.FC = () => {
  return (
    <ItemsMainWrapper className={"ib_scroll_bar"}>
      <ItemsHeader />
      <ItemsBodyWrapper>
        <ItemsListFilterSearch />
        <ItemsTable />
      </ItemsBodyWrapper>
    </ItemsMainWrapper>
  );
};

export default AllItems;
