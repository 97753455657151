import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import CoreTextField from "../../common/TextField/CoreTextField";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import { registerPatternFolder } from "../../helpers/constant/registerPattern";
import { IFolder } from "../../state/features/folder/folder.interface";
import { AppDispatch } from "../../state/store";
import {
  addFolder,
  resetAddFolderSuccess,
  resetUpdateFolderSuccess,
  updateFolderName,
} from "../../state/features/folder/folderSlice";
import { selectFolderState } from "../../state/features/folder/folderSelector";
import { FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderConstant";

interface Props {
  onClose: () => void;
  isEditMode: boolean;
  folder?: IFolder;
}

const AddOrEditFolderName: React.FC<Props> = ({ onClose, isEditMode, folder }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isAdding, isAddSuccess, isUpdateSuccess, isUpdating } = useSelector(selectFolderState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFolder>();

  const onSubmit: SubmitHandler<IFolder> = (data) => {
    if (isEditMode && folder) {
      dispatch(updateFolderName({ ...folder, name: data.name }));
    } else {
      dispatch(addFolder({ name: data.name, color: FOLDER_DEFAULT_COLOR }));
    }
  };

  useEffect(() => {
    if (folder) {
      setValue("name", folder.name);
    }
  }, [folder]);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      onClose();
      dispatch(resetAddFolderSuccess());
      dispatch(resetUpdateFolderSuccess());
    }
  }, [isAddSuccess, isUpdateSuccess]);

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction='column' spacing={1}>
        <Typography variant='body2medium' color='text.primary'>
          Folder Name
        </Typography>
        <CoreTextField
          fullWidth
          type='text'
          size='small'
          placeholder='Enter folder name here'
          color='secondary'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternFolder.name)}
        />
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          size='large'
          color='success'
          type='submit'
          loadingText={isEditMode ? "Changing..." : "Creating..."}
          isLoading={isAdding || isUpdating}
        >
          {isEditMode ? "Change" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditFolderName;
