import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { AppDispatch } from "../../../state/store";
import { selectInvoiceFilters } from "../../../state/features/invoice/invoiceSelector";
import { addInvoiceSearchTextFilter } from "../../../state/features/invoice/invoiceSlice";

const InvoiceListFilterSearch: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { searchText = "" } = useSelector(selectInvoiceFilters);
  const [search, setSearch] = useState(searchText);

  useEffect(() => {
    if (searchText === "") {
      setSearch("");
    }
  }, [searchText]);

  const doSearch = (value: string) => {
    dispatch(addInvoiceSearchTextFilter(value));
  };

  // debounce implement for search input change
  const handleSearch = React.useMemo(() => {
    return debounceHandler(doSearch, 500);
  }, []);

  return (
    <CoreTextField
      fullWidth
      size={"small"}
      type={"search"}
      color={"secondary"}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ mr: 0 }} position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
      placeholder={"Search invoice by name"}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        return handleSearch(e.target.value);
      }}
    />
  );
};

export default InvoiceListFilterSearch;
