import React from "react";
import { IconButton, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOrEditPaymentTerm from "./AddOrEditPaymentTerm";
import useBoolean from "../../../hooks/useBoolean";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { PaymentTermInterface } from "../../../state/features/paymentTerm/paymentTerm.interface";

interface IProps {
  paymentTermInfo: PaymentTermInterface;
  handleSelectPaymentTerms: (paymentTerm: PaymentTermInterface) => void;
  isDefault?: boolean;
  alreadyHasDays?: number[];
}

const SinglePaymentTerm: React.FC<IProps> = ({
  paymentTermInfo,
  handleSelectPaymentTerms,
  isDefault = false,
  alreadyHasDays,
}) => {
  const { id, name, value } = paymentTermInfo || {};

  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onOpenModal();
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    onCloseDeleteModal();
  };

  const alreadyHasDayExceptOwn = alreadyHasDays?.filter((day) => day !== value);

  return (
    <>
      <ListItem key={id} disablePadding onClick={() => handleSelectPaymentTerms(paymentTermInfo)}>
        <ListItemButton>
          <ListItemText primary={name} />

          <Stack direction={"row"} alignItems={"center"} sx={isDefault ? { display: "none" } : {}}>
            <IconButton size={"small"} onClick={handleEdit}>
              <EditIcon fontSize={"small"} />
            </IconButton>
            <IconButton size={"small"} onClick={handleDelete}>
              <DeleteIcon fontSize={"small"} />
            </IconButton>
          </Stack>
        </ListItemButton>
      </ListItem>

      <BasicModalWithHeader title={"Edit Payment Term"} open={openModal} onClose={onCloseModal}>
        <AddOrEditPaymentTerm
          paymentTermInfo={paymentTermInfo}
          onClose={onCloseModal}
          isEditMode={true}
          alreadyHasDays={alreadyHasDayExceptOwn}
        />
      </BasicModalWithHeader>

      <DeleteWarningModal
        title={"Delete!!"}
        warningSubtitle={"Are you sure, you want to delete this payment term?"}
        warningContent={"After delete, this operation cannot be undone."}
        buttonText={"Yes, Delete"}
        cancelButtonText={"Cancel"}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDelete}
        isLoading={false}
      />
    </>
  );
};

export default SinglePaymentTerm;
