import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import {
  addItemFailed,
  addItemSuccess,
  deleteItemFailed,
  deleteItemSuccess,
  getItemsFailed,
  getItemsSuccess,
  updateItemFailed,
  updateItemSuccess,
} from "./itemSlice";
import ItemService from "../../../services/itemService";
import { IGetItemsPayload, ItemInterface } from "./item.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* formFolderWatcher() {
  yield takeEvery("item/getItems", getItemsSaga);
  yield takeEvery("item/addItem", addItemSaga);
  yield takeEvery("item/updateItem", updateItemSaga);
  yield takeEvery("item/deleteItem", deleteItemSaga);
}

function* getItemsSaga(action: PayloadAction<IGetItemsPayload>) {
  try {
    const response = yield* call(ItemService.getItems, action.payload);

    if (response.success) {
      yield put(getItemsSuccess(response.data));
    } else {
      yield put(getItemsFailed(response.message));
    }
  } catch (err) {
    yield put(getItemsFailed("Network or server error"));
  }
}

function* addItemSaga(action: PayloadAction<ItemInterface>) {
  try {
    const response = yield* call(ItemService.addItem, action.payload);

    if (response.success) {
      yield put(addItemSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(addItemFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(addItemFailed());
    showErrorToaster("Network or server error");
  }
}

function* updateItemSaga(action: PayloadAction<ItemInterface>) {
  try {
    const response = yield* call(ItemService.updateItem, action.payload);

    if (response.success) {
      yield put(updateItemSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(updateItemFailed());
      showSuccessToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(updateItemFailed());
  }
}

function* deleteItemSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(ItemService.deleteItem, action.payload);

    if (response.success) {
      yield put(deleteItemSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(deleteItemFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
  }
}

export default function* formFolderSaga() {
  yield all([formFolderWatcher()]);
}
