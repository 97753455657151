import httpRequest from "./api/httpRequest";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class FileUploadService {
  uploadFile(params: FormData) {
    return httpRequest.post<{ urls: string }>(
      process.env.REACT_APP_BACKEND_URL_INVOICE_API + "invoice-builder/invoice/upload/file",
      params
    );
  }
}

export default new FileUploadService();
