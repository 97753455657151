import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const getProgressNumber = (prevNum: number) => {
  if (prevNum < 50) return 10;
  if (prevNum < 80) return 5;
  if (prevNum < 90) return 2;
  if (prevNum < 99) return 1;
  return 0;
};

interface commonSendButtonPropsI {
  isLoading: boolean;
}

const SendInvoiceLoadingContent: React.FC<commonSendButtonPropsI> = ({ isLoading }) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + getProgressNumber(prevProgress));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress color={"secondary"} variant='determinate' value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default SendInvoiceLoadingContent;
