import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import { IGetPaymentTermsPayload, PaymentTermInterface } from "./paymentTerm.interface";

const initialState = {
  data: [] as PaymentTermInterface[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 10,

  isFetching: false,
  isFetchingSuccess: false,

  isAdding: false,
  isAddSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  filter: {
    searchText: "",
  },
};

const paymentTermSlice = createSlice({
  name: "paymentTerm",
  initialState,
  reducers: {
    getPaymentTerms: (state, action: PayloadAction<IGetPaymentTermsPayload | undefined>) => {
      state.isLoading = true;
    },
    getPaymentTermsSuccess: (state, action: PayloadAction<WithPagination<PaymentTermInterface>>) => {
      const { content, count, limit, currentPage } = action.payload;
      state.data = content;
      state.currentPage = currentPage;
      state.count = count || 0;
      state.limit = +limit;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getPaymentTermsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addPaymentTerm: (state, action: PayloadAction<PaymentTermInterface>) => {
      state.isAdding = true;
    },
    addPaymentTermSuccess: (state, action: PayloadAction<PaymentTermInterface>) => {
      state.data.push(action.payload);
      state.isAdding = false;
      state.isAddSuccess = true;
      state.count++;
    },
    addPaymentTermFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    resetAddPaymentTermSuccess: (state) => {
      state.isAddSuccess = false;
    },

    updatePaymentTerm: (state, action: PayloadAction<PaymentTermInterface>) => {
      state.isUpdating = true;
    },
    updatePaymentTermSuccess: (state, action: PayloadAction<PaymentTermInterface>) => {
      state.data = state.data.map((paymentTerm) => {
        if (paymentTerm.id === action.payload.id) {
          return { ...paymentTerm, ...action.payload };
        }
        return paymentTerm;
      });

      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updatePaymentTermFailed: (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },
    resetUpdatePaymentTermSuccess: (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    deletePaymentTerm: (state, action: PayloadAction<number>) => {
      state.isDeleting = true;
    },
    deletePaymentTermSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((paymentTerm) => paymentTerm.id !== action.payload);
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.count--;
    },
    deletePaymentTermFailed: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },
    resetDeletePaymentTermSuccess: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    //filters
    addFilterPaymentTermSearchText: (state, action: PayloadAction<string>) => {
      state.filter.searchText = action.payload;
    },

    resetPaymentTermState: (state) => {
      state.data = [] as PaymentTermInterface[];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.count = 0;
      state.currentPage = 1;
      state.limit = 10;
      state.filter = {
        searchText: "",
      };
    },
  },
});

export const {
  getPaymentTerms,
  getPaymentTermsFailed,
  getPaymentTermsSuccess,

  addPaymentTerm,
  addPaymentTermFailed,
  addPaymentTermSuccess,
  resetAddPaymentTermSuccess,

  updatePaymentTerm,
  updatePaymentTermFailed,
  updatePaymentTermSuccess,
  resetUpdatePaymentTermSuccess,

  deletePaymentTerm,
  deletePaymentTermFailed,
  deletePaymentTermSuccess,
  resetDeletePaymentTermSuccess,

  addFilterPaymentTermSearchText,
  resetPaymentTermState,
} = paymentTermSlice.actions;

export default paymentTermSlice.reducer;
