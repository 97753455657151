import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddItemDrawerContent from "./AddItemDrawerContent";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import useBoolean from "../../hooks/useBoolean";
import { AppDispatch } from "../../state/store";
import { ItemInterface } from "../../state/features/item/item.interface";
import { selectItemState } from "../../state/features/item/itemSelector";
import { deleteItem, resetDeleteItemSuccess } from "../../state/features/item/itemSlice";

interface IProps {
  item: ItemInterface;
}

const ItemsActionButtons: React.FC<IProps> = ({ item }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isDeleteSuccess, isDeleting } = useSelector(selectItemState);
  const { value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer } = useBoolean(false);
  const { value: openModal, toggle: toggleOpenModal, setFalse: onCloseModal } = useBoolean(false);

  const deleteItemHandler = () => {
    dispatch(deleteItem(item.id));
  };

  useEffect(() => {
    if (openModal && isDeleteSuccess) {
      onCloseModal();
      dispatch(resetDeleteItemSuccess());
    }
  }, [isDeleteSuccess]);

  return (
    <Stack alignItems='center' direction='row' spacing={1} justifyContent='flex-end'>
      <IconButton size='small' color={"error"} onClick={toggleOpenModal}>
        <DeleteForeverIcon />
      </IconButton>
      <IconButton size='small' color={"secondary"} onClick={onOpenDrawer}>
        <EditIcon />
      </IconButton>

      <DeleteWarningModal
        open={openModal}
        onClose={toggleOpenModal}
        onConfirm={deleteItemHandler}
        isLoading={isDeleting}
        warningSubtitle='Are you sure, you want to delete this item?'
      />

      <BasicDrawer open={openDrawer} title='Edit Item' toggleDrawer={onCloseDrawer}>
        <AddItemDrawerContent onClose={onCloseDrawer} isEditMode={true} item={item} />
      </BasicDrawer>
    </Stack>
  );
};

export default ItemsActionButtons;
