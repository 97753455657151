import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Grid, InputAdornment, Stack, styled } from "@mui/material";
import CustomSelect from "./ItemsDropdowns/CustomSelect";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import CoreInputLabel from "../../common/TextField/CoreInputLabel";
import { createItemPattern } from "../../helpers/validation/itemPatten";
import { AppDispatch } from "../../state/store";
import { ItemInterface } from "../../state/features/item/item.interface";
import { selectItemState } from "../../state/features/item/itemSelector";
import { ITEM_TYPE_OPTIONS, ITEM_UNIT_OPTIONS } from "../../helpers/constant/itemConstant";
import { addItem, resetAddItemSuccess, resetUpdateItemSuccess, updateItem } from "../../state/features/item/itemSlice";

const SingleLabelInputBox = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingRight: theme.spacing(3),
  gap: theme.spacing(0.5),
}));

interface IProps {
  onClose: () => void;
  isEditMode?: boolean;
  item?: ItemInterface;
}

const AddItemDrawerContent: React.FC<IProps> = ({ onClose, isEditMode = false, item }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAddSuccess, isUpdateSuccess, isAdding, isUpdating } = useSelector(selectItemState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ItemInterface>({
    defaultValues: item,
  });

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetUpdateItemSuccess());
      dispatch(resetAddItemSuccess());
      onClose();
    }
  }, [isAddSuccess, isUpdateSuccess]);

  const onSubmit = (payload: ItemInterface) => {
    let data = { ...payload, tax: 0, quantity: 1 };

    if (isEditMode) {
      dispatch(updateItem(data));
    } else {
      dispatch(addItem(data));
    }
  };

  const itemTypeOptions = Object.values(ITEM_TYPE_OPTIONS);
  const unitOptions = Object.values(ITEM_UNIT_OPTIONS);

  return (
    <Box component='form' noValidate autoComplete='off' sx={{ px: 3, py: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
            <CoreInputLabel variant='standard'>Item Name</CoreInputLabel>
            <CoreTextField
              size='small'
              type='text'
              color='secondary'
              placeholder='Enter item name here'
              error={!!(errors.name && errors.name.message)}
              helperText={errors.name && errors.name.message}
              {...register("name", createItemPattern.name)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!(errors.description && errors.description.message)}>
            <CoreInputLabel variant='standard'>Item Description</CoreInputLabel>
            <CoreTextField
              size='small'
              type='text'
              color='secondary'
              placeholder='Enter item description here'
              multiline
              minRows={3}
              maxRows={4}
              error={!!(errors.description && errors.description.message)}
              helperText={errors.description && errors.description.message}
              {...register("description", createItemPattern.description)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <CustomSelect
            name='itemType'
            control={control}
            label='Item Type'
            required
            placeholder='Select item type'
            options={itemTypeOptions}
            rules={createItemPattern.itemType}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelect
            name='unitOfItem'
            control={control}
            label='Units of item'
            placeholder='Select unit type'
            options={unitOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!(errors.price && errors.price.message)} required>
            <CoreInputLabel variant='standard'>Selling Price</CoreInputLabel>
            <CoreTextField
              size='small'
              type='number'
              color='secondary'
              placeholder='$12345'
              error={!!(errors.price && errors.price.message)}
              helperText={errors.price && errors.price.message}
              {...register("price", createItemPattern.price)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <SingleLabelInputBox direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
            <CoreButton variant='text' onClick={onClose} sx={{ color: "text.primary" }}>
              Cancel
            </CoreButton>
            <LoadingButton
              loadingText={isEditMode ? "Changing..." : "Creating..."}
              type='button'
              size='large'
              color='secondary'
              isLoading={isAdding || isUpdating}
              onClick={handleSubmit(onSubmit)}
            >
              {isEditMode ? "Change" : "Create"}
            </LoadingButton>
          </SingleLabelInputBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddItemDrawerContent;
