import { all, call, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import TaxService from "../../../services/taxService";
import { getTaxesFailed, getTaxesSuccess } from "./taxSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IGetTaxesPayload } from "./tax.interface";

function* taxWatcher() {
  yield takeEvery("tax/getTaxes", getTaxesSaga);
}

function* getTaxesSaga(action: PayloadAction<IGetTaxesPayload>) {
  try {
    const response = yield* call(TaxService.getTaxes, action.payload);

    if (response.success) {
      if (response.data.count > 10) {
        const response2 = yield* call(TaxService.getTaxes, { ...action.payload, limit: response.data.count });
        if (response2.success) {
          yield put(getTaxesSuccess(response2.data.content));
        }
      } else {
        yield put(getTaxesSuccess(response.data.content));
      }
    } else {
      yield put(getTaxesFailed(response.message));
    }
  } catch (err) {
    yield put(getTaxesFailed("Network or server error"));
  }
}

export default function* taxSaga() {
  yield all([taxWatcher()]);
}
