import { Box, Stack, styled, Table, TableCell, tableCellClasses, TableRow } from "@mui/material";

export const HeaderWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.other.outlinedBorderDarker}`,
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  width: "210px",
  height: "80px",
  "& img": {
    width: "auto",
    maxWidth: "100%",
    height: "100%",
    objectFit: "contain",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90px",
  },
}));

export const SummeryTable = styled(Table)(({ theme }) => ({
  width: "30%",
  marginTop: 30,
  "& tr": {
    border: "none",
    "& .MuiTableCell-root": { border: "none", padding: 4 },
    "& th": { border: "none" },
  },
}));

//inv table for standerd
export const TotalTableRow = styled(TableRow)(({ theme }) => ({
  border: "none",
  "& td": {
    border: "none",
    padding: theme.spacing(1, 2),
    "&.iv_tableCell_color": {
      backgroundColor: theme.palette.other.dividerFillColor,
      borderBottom: `2px solid ${theme.palette.other.outlinedBorderDarker}`,
    },
    "&.iv_with_borderTop": {
      borderTop: `2px solid ${theme.palette.other.outlinedBorderDarker}`,
    },
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.other.dividerFillColor,
  "& td": {
    fontWeight: 600,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    minWidth: "100px",
  },
}));
