import { all, put } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getFollowupSettingFailed,
  getFollowupSettingSuccess,
  updateFollowupSettingFailed,
  updateFollowupSettingSuccess,
} from "./followupSettingSlice";
import { updateInvoiceBuilderUpdateTime } from "../invoiceBuilder/invoiceBuilderSlice";
import updateBuilderSettingsAt from "../../../helpers/utils/updateBuilderSettingsAt";
import { IUpdateFollowupSetting } from "../../../interface/followupSetting.interface";
import savingBuilderSetting from "../../../helpers/utils/savingBuilderSetting";
import FollowupSettingService from "../../../services/followupSettingService";

function* followupSettingWatcher() {
  yield takeLatest("followupSetting/getFollowupSetting", getFollowupSettingSaga);
  yield takeLatest("followupSetting/updateFollowupSetting", updateFollowupSettingSaga);
}

function* getFollowupSettingSaga(action: PayloadAction<number>) {
  try {
    const response = yield* call(FollowupSettingService.getFollowupSetting, action.payload);

    if (response.success) {
      yield put(getFollowupSettingSuccess(response.data));
    } else {
      yield put(getFollowupSettingFailed(response.message as string));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateFollowupSettingSaga(action: PayloadAction<IUpdateFollowupSetting>) {
  try {
    yield put(updateInvoiceBuilderUpdateTime(savingBuilderSetting()));
    const response = yield* call(FollowupSettingService.updateFollowupSettingData, action.payload);

    if (response.success) {
      yield put(updateFollowupSettingSuccess(response.data));
    } else {
      yield put(updateFollowupSettingFailed(response.message as string));
    }
    yield put(updateInvoiceBuilderUpdateTime(updateBuilderSettingsAt()));
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* followupSettingSaga() {
  yield all([followupSettingWatcher()]);
}
