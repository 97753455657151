import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Typography } from "@mui/material";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddOrEditInvoiceName from "./AddOrEditInvoiceName";
import { InvoiceHeaderWrp } from "./InvoiceHomePageStyles";
import CoreButton from "../../common/Button/CoreButton";
import RouteLinkWithPrevPath from "../../common/Link/RouteLinkWithPrevPath";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../hooks/useBoolean";

const InvoiceHeader: React.FC = () => {
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  // const defaultAgency = getAccountData("agencyId") === 1;

  return (
    <>
      <InvoiceHeaderWrp>
        <Box sx={{ flex: 1 }}>
          <Typography variant='h5' color='text.primary'>
            My Invoices
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            You can see your all Invoice data here.
          </Typography>
        </Box>

        <Stack direction='row' spacing={1} alignSelf='flex-end'>
          {/*{defaultAgency && (*/}
          {/*  <Alert variant='filled' severity='error'>*/}
          {/*    Due to significant updates, all prior test data in the Invoice Builder has been reset.*/}
          {/*  </Alert>*/}
          {/*)}*/}
          <RouteLinkWithPrevPath to={`/user/invoice-settings`}>
            <CoreButton color='secondary' variant='outlined' size='large' startIcon={<SettingsSuggestIcon />}>
              Settings
            </CoreButton>
          </RouteLinkWithPrevPath>
          {/*<RouteLink to={`/ibuilder/reports`}>*/}
          {/*  <CoreButton color='secondary' variant='outlined' size='large' startIcon={<AssessmentIcon />}>*/}
          {/*    Reports*/}
          {/*  </CoreButton>*/}
          {/*</RouteLink>*/}
          <CoreButton color='secondary' variant='contained' size='large' startIcon={<AddIcon />} onClick={onOpenModal}>
            Create Invoice
          </CoreButton>
        </Stack>
      </InvoiceHeaderWrp>

      <BasicModalWithHeader title='Create New Invoice' open={openModal} onClose={onCloseModal}>
        <AddOrEditInvoiceName onClose={onCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
    </>
  );
};

export default InvoiceHeader;
