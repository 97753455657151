import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPaymentListItem } from "./paymentList.interface";

const initialState = {
  data: [] as IPaymentListItem[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",
};

const paymentListSlice = createSlice({
  name: "paymentList",
  initialState,
  reducers: {
    getPaymentList: (state) => {
      state.isLoading = true;
    },
    getPaymentListSuccess: (state, action: PayloadAction<IPaymentListItem[]>) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = "";
    },
    getPaymentListFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { getPaymentList, getPaymentListFailed, getPaymentListSuccess } = paymentListSlice.actions;

export default paymentListSlice.reducer;
