import React, { useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Divider,
  Paper,
  Skeleton,
  Slider,
  Stack,
  styled,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import CircleIcon from "@mui/icons-material/Circle";
import formatCurrency from "../../helpers/utils/formatCurrency";
import { selectStateOfInvoice } from "../../state/features/invoiceState/invoiceStateSelector";
import { getInvoiceStateReportWithDateRange } from "../../state/features/invoiceState/invoiceStateSlice";

const SliderStyle = styled(Slider)(({ theme }) => ({
  color: theme.palette.error.main,
  height: "8px",
  "& .MuiSlider-thumb": { display: "none" },
  "& .MuiSlider-valueLabel": {
    backgroundColor: theme.palette.error.main,
  },
}));

const InvoiceCollections = () => {
  const dispatch = useDispatch();

  const { report, isReportLoading } = useSelector(selectStateOfInvoice);
  const {
    last30DaysPastDue,
    last30To60DaysPastDue,
    moreThan60DaysPastDue,
    notYetPastDue,
    todayTotalOutstandingAmount,
    currentDate,
  } = report || {};

  useEffect(() => {
    dispatch(getInvoiceStateReportWithDateRange());
  }, []);

  return (
    <Box p={2}>
      <Typography variant={"h5"} color={"text.primary"} mb={2}>
        Today&apos;s Collections
      </Typography>
      <Divider light />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} py={2}>
        <Typography variant={"body2"} color={"text.primary"} fontWeight={300}>
          Outstanding Invoices
        </Typography>
        <Typography variant={"caption"} color={"text.secondary"}>
          Data as of {format(new Date(currentDate), "MMMM dd, yyyy")}
        </Typography>
      </Stack>
      <Typography variant={"h5"} color={"text.primary"} fontWeight={400} mb={2}>
        {isReportLoading ? <Skeleton width={80} /> : formatCurrency(todayTotalOutstandingAmount ?? 0, "$")}
      </Typography>

      <SliderStyle value={70} valueLabelDisplay='auto' />

      <Paper variant={"outlined"} sx={{ maxWidth: "800px", marginInline: "auto" }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Age</TableCell>
              <TableCell align={"right"}>Invoices</TableCell>
              <TableCell align={"right"}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: "error.main" }} />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    More than 60 days past due
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={40} sx={{ ml: "auto" }} />
                ) : (
                  moreThan60DaysPastDue.totalInvoiceDue ?? 0
                )}
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={80} sx={{ ml: "auto" }} />
                ) : (
                  formatCurrency(moreThan60DaysPastDue.totalDueAmount ?? 0, "$")
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: "warning.main" }} />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    31-60 days past due
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={40} sx={{ ml: "auto" }} />
                ) : (
                  last30To60DaysPastDue.totalInvoiceDue ?? 0
                )}
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={80} sx={{ ml: "auto" }} />
                ) : (
                  formatCurrency(last30To60DaysPastDue.totalDueAmount ?? 0, "$")
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: "warning.light" }} />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    0-30 days past due
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? <Skeleton width={40} sx={{ ml: "auto" }} /> : last30DaysPastDue.totalInvoiceDue ?? 0}
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={80} sx={{ ml: "auto" }} />
                ) : (
                  formatCurrency(last30DaysPastDue.totalDueAmount ?? 0, "$")
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <CircleIcon color={"primary"} sx={{ fontSize: 10 }} />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    Not yet past due
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={40} sx={{ ml: "auto" }} />
                ) : (
                  notYetPastDue.totalNotYetPastDueInvoice ?? 0
                )}
              </TableCell>
              <TableCell align={"right"}>
                {isReportLoading ? (
                  <Skeleton width={80} sx={{ ml: "auto" }} />
                ) : (
                  formatCurrency(notYetPastDue.totalNotYetPastDueAmount ?? 0, "$")
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default InvoiceCollections;
