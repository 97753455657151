import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: "01/04/2023",
    Due: 4000,
    OverDue: 2400,
    Pending: 2400,
    Collected: 2480,
  },
  {
    name: "08/04/2023",
    Due: 3000,
    OverDue: 1398,
    Pending: 2210,
    Collected: 3500,
  },
  {
    name: "15/04/2023",
    Due: 2000,
    OverDue: 9800,
    Pending: 2290,
    Collected: 3500,
  },
  {
    name: "22/04/2023",
    Due: 2780,
    OverDue: 3908,
    Pending: 2000,
    Collected: 3500,
  },
  {
    name: "29/04/2023",
    Due: 1890,
    OverDue: 4800,
    Pending: 2181,
    Collected: 3500,
  },
];

export default function ReportChart() {
  return (
    <ResponsiveContainer width='100%' height={500}>
      <LineChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 40,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <YAxis type='number' hide={false} />
        <XAxis dataKey='name' />
        <Tooltip />
        <Legend />
        {/*{hasCartData && (*/}
        <>
          <Line dataKey='Due' stroke='#b574ce' fill='#b574ce' strokeWidth={3} />
          <Line dataKey='OverDue' stroke='#338af7' fill='#338af7' strokeWidth={3} />
          <Line dataKey='Pending' stroke='#8179ff' fill='#8179ff' strokeWidth={3} />
          <Line dataKey='Collected' stroke='#81c784' fill='#81c784' strokeWidth={3} />
        </>
        {/*)}*/}
      </LineChart>
    </ResponsiveContainer>
  );
}
