import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { CallMade } from "@mui/icons-material";
import { Alert, Stack, Typography } from "@mui/material";
import PaymentOptions from "./PaymentOptions";
import { InvoiceContainer } from "../BuilderStyles";
import { InvoiceInterface } from "../../../state/features/invoiceBuilder/invoiceBuilderInterface";
import { selectFormattedPaymentList } from "../../../state/features/paymentList/paymentListSelector";

const InvoicePaymentContent: React.FC = () => {
  const { register, getValues } = useFormContext<InvoiceInterface>();
  const paymentList = useSelector(selectFormattedPaymentList);

  return (
    <Stack spacing={1}>
      <div>
        <Typography variant={"body2"} color={"text.primary"} mb={1}>
          Payment Processor
        </Typography>
        <PaymentOptions {...register("paymentMethod")} value={getValues("paymentMethod")} options={paymentList} />
      </div>
      <Alert severity='info' sx={{ "& .MuiAlert-message": { pt: 0.5 } }}>
        You have the choice to add your payment method —{" "}
        <strong>
          <a href={"/user/invoice"} target={"_blank"} rel='noreferrer'>
            here <CallMade fontSize={"small"} />
          </a>
        </strong>
      </Alert>
    </Stack>
  );
};

export default InvoicePaymentContent;
