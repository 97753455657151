import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import CoreTextField from "../../../common/TextField/CoreTextField";

export const TableTotal = styled(Table)(() => ({
  width: "45%",
  marginLeft: "auto",
  marginRight: "84px",
  "& td": {
    border: "none",
  },
  "& th": {
    border: "none",
  },
}));

export const StyleTextField = styled(CoreTextField)(() => ({
  "& input": {
    textAlign: "right",
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
  },
}));

export const DataCellTd = styled(TableCell)(({ theme }) => ({
  minWidth: "70px",
  padding: theme.spacing(0.5, 1),
  "&.totalCell": {
    border: "none",
  },
}));

export const TotalDataCellTd = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  minWidth: "80px",
  maxWidth: "100px",
  border: "none",
}));
