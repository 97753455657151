export const STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  FAILED: 3,
};

export const GLOBAL_DELAY = {
  DEBOUNCE: 1000,
  RESPONSE: 1000,
};
