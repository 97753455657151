import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import { IGetItemsPayload, ItemInterface } from "../state/features/item/item.interface";

const invoiceAPI = process.env.REACT_APP_BACKEND_URL_INVOICE_API;
if (!invoiceAPI) throw Error("Invoice API URL not found in env!");

class ItemService {
  getItems(params: IGetItemsPayload) {
    return httpRequest.get<WithPagination<ItemInterface[]>>(invoiceAPI + "invoice-builder/item/lists", params);
  }

  addItem(body: ItemInterface) {
    return httpRequest.post<ItemInterface>(invoiceAPI + "invoice-builder/item/create", body);
  }

  updateItem(body: ItemInterface) {
    return httpRequest.put<ItemInterface>(invoiceAPI + "invoice-builder/item/update", body);
  }

  deleteItem(id: number) {
    return httpRequest.delete<number>(invoiceAPI + "invoice-builder/item/delete", { id: id });
  }
}

export default new ItemService();
