import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import EmailText from "../../../../common/FormatText/EmailText";
import TelephoneText from "../../../../common/FormatText/TelephoneText";
import { HeaderWrapper, LogoBox } from "../StandardInvoiceStyles";
import getFullName from "../../../../helpers/utils/getFullName";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";

const InvoiceHeaderStandard: React.FC = () => {
  const {
    data: { client, logoOfCompany },
  } = useSelector(selectInvoiceBuilderState);

  return (
    <HeaderWrapper>
      <LogoBox>{logoOfCompany && <img src={logoOfCompany} alt={"branding logo"} />}</LogoBox>

      {client?.id && (
        <Stack sx={{ textAlign: "right" }}>
          <Typography variant={"h5"} color={"text.primary"}>
            Invoice
          </Typography>
          <Typography variant={"body1SemiBold"} color={"text.primary"}>
            {getFullName(client.firstName, client.lastName)}
          </Typography>
          <Typography variant={"body2"} color={"text.primary"}>
            <EmailText email={client.email} />
          </Typography>
          <Typography variant={"body2"} color={"text.primary"}>
            <TelephoneText phoneNumber={client.number} />
          </Typography>
        </Stack>
      )}
    </HeaderWrapper>
  );
};

export default InvoiceHeaderStandard;
