import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonalizeTagInterface } from "./personalizeTag.interface";

const initialState = {
  data: [] as PersonalizeTagInterface[],
  isLoading: false,
  isSuccess: false,
};

const personalizeTagSlice = createSlice({
  name: "personalizeTag",
  initialState,
  reducers: {
    getPersonalizeTags: (state) => {
      state.isLoading = true;
    },
    getPersonalizeTagsSuccess: (state, action: PayloadAction<PersonalizeTagInterface[]>) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    getPersonalizeTagsFailed: (state) => {
      state.data = [];
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { getPersonalizeTags, getPersonalizeTagsFailed, getPersonalizeTagsSuccess } = personalizeTagSlice.actions;

export default personalizeTagSlice.reducer;
