import React from "react";
import { formatUSNumber } from "../../helpers/utils/formatUSNumber";

const TelephoneText = ({ phoneNumber }: { phoneNumber: string }) => {
  if (!phoneNumber) return <></>;

  const href = "tel: " + phoneNumber;
  const usNumberFormat = formatUSNumber(phoneNumber);
  return (
    <a href={href} style={{ textDecoration: "none", color: "inherit" }}>
      {usNumberFormat}
    </a>
  );
};

export default TelephoneText;
