import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Refresh } from "@mui/icons-material";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import ButtonForIcon from "../../../common/Button/ButtonForIcon";
import { InvoiceOrderByList } from "../../../helpers/constant/invoiceConstant";
import { AppDispatch } from "../../../state/store";
import { resetOnlyInvoiceFilter } from "../../../state/features/invoice/invoiceSlice";
import { selectInvoiceFilters } from "../../../state/features/invoice/invoiceSelector";

const InvoiceResetFilter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { searchText, orderBy, paymentStatus } = useSelector(selectInvoiceFilters);

  const handleReset = () => {
    dispatch(resetOnlyInvoiceFilter());
  };

  const isShowResetButton = searchText || orderBy !== InvoiceOrderByList[2].value || paymentStatus;
  if (!isShowResetButton) return null;

  return (
    <CustomTooltip arrow placement='bottom' title='Reset Filter'>
      <ButtonForIcon variant={"outlined"} color={"secondary"} onClick={handleReset}>
        <Refresh />
      </ButtonForIcon>
    </CustomTooltip>
  );
};

export default InvoiceResetFilter;
