import React from "react";
import { Box, Paper, Stack, styled, Typography } from "@mui/material";

const StyledStack = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
    color: theme.palette.secondary.contrastText,
  },
}));

interface CommonTitleBarInterface {
  name?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const CommonTitleBar: React.FC<CommonTitleBarInterface> = ({ name, subtitle, icon, children }) => {
  return (
    <StyledStack elevation={0}>
      {icon}
      <Stack>
        <Typography variant={"h5"} color={"common.white"}>
          {name}
        </Typography>
        {subtitle && (
          <Typography variant={"caption"} color={"common.white"}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Box sx={{ ml: "auto" }}>{children}</Box>
    </StyledStack>
  );
};

export default CommonTitleBar;
