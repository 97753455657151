import React from "react";
import { Box, Paper, styled } from "@mui/material";
import ReportChart from "./ReportChart";
import ReportHeader from "./ReportHeader";
import ReportFooter from "./ReportFooter";

const ReportPageWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  height: "calc(100vh - 96px)",
  background: "linear-gradient(0deg, rgba(148, 157, 178, 0.12), rgba(148, 157, 178, 0.12)), #FFFFFF",
}));

const Reports = () => {
  return (
    <ReportPageWrapper className={"ib_scroll_bar"}>
      <ReportHeader />
      <ReportFooter />
      <Paper elevation={0} sx={{ width: "100%", p: 2, my: 2 }}>
        <ReportChart />
      </Paper>
    </ReportPageWrapper>
  );
};

export default Reports;
