import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { selectInvoiceBuilderState } from "../../../../state/features/invoiceBuilder/invoiceBuilderSelector";
import generateAddress from "../../../../helpers/utils/generateAddress";
import addressView from "../../../../helpers/utils/addressView";

const InvoiceSummeryStandard: React.FC = () => {
  const {
    data: { client },
  } = useSelector(selectInvoiceBuilderState);

  const billingAddress = client && addressView(client.billingAddress);
  const shippingAddress = client && addressView(client.shippingAddress);

  return (
    <Stack direction={"row"} justifyContent={"space-between"} p={2}>
      {client?.billingAddress?.address || billingAddress ? (
        <Stack>
          <Typography variant={"h6"} color={"text.primary"}>
            BILL TO
          </Typography>

          {client?.billingAddress?.address && (
            <Typography variant={"body1SemiBold"} color={"text.primary"}>
              {client?.billingAddress?.address}
            </Typography>
          )}
          {(client?.billingAddress?.city || client?.billingAddress?.state || client?.billingAddress?.zip) && (
            <Typography variant={"body2"} color={"text.primary"}>
              {generateAddress([client.billingAddress.city, client.billingAddress.state])} {client.billingAddress.zip}
            </Typography>
          )}
          {client?.billingAddress?.country && (
            <Typography variant={"body2"} color={"text.primary"} textTransform={"capitalize"}>
              {client.billingAddress.country}
            </Typography>
          )}
        </Stack>
      ) : (
        <div></div>
      )}

      {client?.shippingAddress?.address || shippingAddress ? (
        <Stack alignItems='flex-end'>
          <Typography variant={"h6"} color={"text.primary"}>
            SHIP TO
          </Typography>

          {client?.shippingAddress?.address && (
            <Typography variant={"body1SemiBold"} color={"text.primary"}>
              {client?.shippingAddress?.address}
            </Typography>
          )}
          {(client?.shippingAddress?.city || client?.shippingAddress?.state || client?.shippingAddress?.zip) && (
            <Typography variant={"body2"} color={"text.primary"}>
              {generateAddress([client.shippingAddress.city, client.shippingAddress.state])}{" "}
              {client.shippingAddress.zip}
            </Typography>
          )}
          {client?.shippingAddress?.country && (
            <Typography variant={"body2"} color={"text.primary"} textTransform={"capitalize"}>
              {client.shippingAddress.country}
            </Typography>
          )}
        </Stack>
      ) : (
        <div></div>
      )}
    </Stack>
  );
};

export default InvoiceSummeryStandard;
