import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../../state/store";
import { Box, Stack, Typography } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { registerPatternPaymentTerm } from "../../../helpers/constant/registerPattern";
import {
  addPaymentTerm,
  updatePaymentTerm,
  resetAddPaymentTermSuccess,
  resetUpdatePaymentTermSuccess,
} from "../../../state/features/paymentTerm/paymentTermSlice";
import { selectPaymentTermState } from "../../../state/features/paymentTerm/paymentTermSelector";
import { PaymentTermInterface } from "../../../state/features/paymentTerm/paymentTerm.interface";

interface Props {
  onClose: () => void;
  isEditMode: boolean;
  paymentTermInfo?: PaymentTermInterface;
  alreadyHasDays?: number[];
}

const AddOrEditPaymentTerm: React.FC<Props> = ({ onClose, isEditMode, paymentTermInfo, alreadyHasDays }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isAdding, isAddSuccess, isUpdateSuccess, isUpdating } = useSelector(selectPaymentTermState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PaymentTermInterface>();

  const onSubmit: SubmitHandler<PaymentTermInterface> = (data) => {
    if (isEditMode && paymentTermInfo?.id) {
      data.id = paymentTermInfo.id;
      dispatch(updatePaymentTerm(data));
    } else {
      dispatch(addPaymentTerm(data));
    }
  };

  useEffect(() => {
    if (paymentTermInfo) {
      setValue("name", paymentTermInfo.name);
      setValue("value", paymentTermInfo.value);
    }
  }, []);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      onClose();
      dispatch(resetAddPaymentTermSuccess());
      dispatch(resetUpdatePaymentTermSuccess());
    }
  }, [isAddSuccess, isUpdateSuccess]);

  return (
    <Box component='form' noValidate autoComplete='off' sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}>
      <Stack direction='column' spacing={1} mb={2}>
        <Typography variant='body2medium' color='text.primary'>
          Name *
        </Typography>
        <CoreTextField
          fullWidth
          type='text'
          size='small'
          placeholder='Enter payment term name here'
          color='secondary'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternPaymentTerm.name)}
        />
      </Stack>

      <Stack direction='column' spacing={1}>
        <Typography variant='body2medium' color='text.primary'>
          Days *
        </Typography>
        <CoreTextField
          fullWidth
          type='number'
          size='small'
          placeholder='Enter payment term days here'
          color='secondary'
          error={!!(errors.value && errors.value.message)}
          helperText={errors.value && errors.value.message}
          {...register("value", registerPatternPaymentTerm.value(alreadyHasDays))}
        />
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          size='large'
          color='success'
          type='button'
          loadingText={isEditMode ? "Updating..." : "Creating..."}
          isLoading={isAdding || isUpdating}
          onClick={handleSubmit(onSubmit)}
        >
          {isEditMode ? "Update" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditPaymentTerm;
